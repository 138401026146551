import React, { Fragment, useEffect, useState } from 'react'
import { Grid, Typography, CardContent, Button, Container } from '@mui/material';
import StatusMessage from '../StatusMessage';
import CardStatusTransaction from '../CardStatusTransaction';
import AssistComponent from '../AssistComponent';
import { AddCircle, Info, Receipt } from '@mui/icons-material';
import PurchaseDetail from '../PurchaseDetail';
import SecureSite from '../SecureSite';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getBookingAction, getRetrieveAction } from '../../../../store/actions';
import { connect } from 'react-redux';
import { useClientData } from '../../../../context/ClientContext';
import { PENDING } from './utils';
import { useTranslation } from 'react-i18next';

const Pending = (props) => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { clientData, currentProduct } = useClientData();
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(0);
  const [intervalTimer, setIntervalTimer] = useState();
  const [content, setContent] = useState({});
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    let { content,  messages } = PENDING[params.module] || { content: {}, messages: {} };

    if (location.state?.dataFinal?.change_price) {
      const messagesAux = content.messages || [];
      messagesAux.push({
        title: '¡Atención!',
        subtitle: t('checkout.common.changePriceReservation'),
        className: 'status-pending'
      });
      content.messages = messagesAux;
    }

    if (props.paymentData?.name === 'credit') {
      content.title = 'insufficientCredit';
      content.status_payment_icon = 'error';
      content.status_payment_title = 'pendingPayment';
      content.status_payment = 'pending';
      messages = [
        {
          position: 'descriptionMessage',
          message: [
            'insufficientCreditMessage'
          ]
        }
      ]
    } else if (props.paymentData?.name === 'braspag') {
      content.title = 'reservationInAnalysis';
      content.statusMessage = 'inAnalysis';
      content.status_payment_icon = 'error';
      content.status_payment_title = 'inAnalysis';
      content.status_payment = 'pending';
    }

    setContent(content);
    setMessages(messages);

    if (params.module === 'hotels') {
      getRetrieve();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (content.messages?.find(elem => elem.show_timer)) {
      if (minutes === 0 && seconds === 0) {
        clearInterval(intervalTimer);
        setContent(current => ({
          ...current,
          statusMessage: 'pendingConfirmation',
          messages: [{
            subtitle: 'checkRealStatus',
            className: 'status-pending'
          }]
        }));
      } else {
        const interval = setInterval(() => {
          if (seconds > 0) {
            setSeconds(current => current - 1);
          } else if (seconds === 0) {
            setMinutes(current => current - 1);
            setSeconds(59);
          }
        }, 1000)

        setIntervalTimer(interval);

        return () => {
          clearInterval(interval);
        };
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content, minutes, seconds]);

  useEffect(() => {
    if (props.responseRetrieve) {
      if (props.responseRetrieve.completed !== undefined && props.responseRetrieve.completed) {
        if (props.responseRetrieve.status === 'Pending') {
          setContent(current => ({
            ...current,
            statusMessage: 'pendingConfirmation',
            messages: [{
              subtitle: 'checkRealStatus',
              className: 'status-pending'
            }]
          }));
        }
        props.handleChangeRetrieve && props.handleChangeRetrieve(props.responseRetrieve);
      } else {
        setTimeout(() => {
          getRetrieve();
        }, 15000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.responseRetrieve]);

  const CardContentStatusTransaction = () => (
    <CardStatusTransaction
      reservationNumber={params.reservationNumber}
      content={content}
      minutes={minutes}
      seconds={seconds}
    />
  )

  const DescriptionMessage = () => {
    return (
      messages.length !== 0 && messages.map((item, i) => {
        return (
          <Fragment key={i}>
            {item.position === "descriptionMessage" &&
              item.message.map((item, i) => {
                return (<Fragment key={i} >
                  <CardContent className="text-center card-divider">
                    <div className="content card-content-booking">
                      <p>{t(`checkout.thankYouPage.${item}`)}</p>
                    </div>
                  </CardContent>
                </Fragment>)
              })}
          </Fragment>
        )
      })
    )
  }

  const CardContentMessage = () => {
    return (
      <Grid>
        <DescriptionMessage />
        {props.email && <CardContent className="text-center card-divider">
          <div className="content card-content-booking">
            <Typography>{t('checkout.thankYouPage.receiveReservationTo')} <strong>{props.email}</strong></Typography>
            <Typography>{t('checkout.thankYouPage.spam')}</Typography>
          </div>
        </CardContent>}
      </Grid>
    )
  }

  const handleClickReservations = () => {
    if (params.idTransaction) {
      let adminUrl = `https://admin-b2.e2etravelsolutions.com/#/reservation/${params.idTransaction}/show`;  // PROD
      // let adminUrl = `https://dev-b2-admin.dev001.api-services-group.com/#/reservation/${params.idTransaction}/show`;  // DEV
      const token = localStorage.getItem('jwt');
      if (token) {
        adminUrl += `?token=${token}`;
      }
      window.open(adminUrl);
    }
  }

  const getRedirectUrl = () => {
    const module = clientData?.modules && Array.isArray(clientData?.modules) && clientData?.modules?.filter(elem => elem.module === params.module)?.slice(-1)?.[0];
    return module?.path || '/';
  }

  const handleSelectReservation = (reservationNumber = null) => {
    const reservation = props.reservations?.find(elem => elem.number === reservationNumber);
    props.handleSelectReservation && props.handleSelectReservation(reservation || null);
    if (reservationNumber && reservation) {
      localStorage.setItem('selectedReservation', JSON.stringify(reservation));
    } else {
      localStorage.removeItem('selectedReservation');
    }
    navigate(getRedirectUrl());
  }

  const getRetrieve = () => {
    const tokenCheckout = currentProduct?.config?.api_checkout_token || currentProduct?.config_work_unit?.api_checkout_token;

    if (location.state?.dataFinal?.hash && tokenCheckout) {
      const requestDefault = {
        "hash": location.state.dataFinal.hash,
        'module': 'hotels',
        'steps': {
          'payment': {
            'status': false,
            'data': []
          },
          'booking': {
            'status': false,
            'data': []
          },
          'crossel': {
            'status': false,
            'data': []
          }
        }
      }

      props.getRetrieve(tokenCheckout, requestDefault);
    }
  }

  const ReservationContainer = () => {
    return <Grid className='reservation-container row mt-2'>
      <Grid className='left-side row'><Info /></Grid>
      <Grid className='right-side' container columns={24}>
        <Grid item xs={24} sm={8} xl={7} className='column' style={{ borderRight: '1px solid lightgray' }}>
          <Typography>
            {t('checkout.thankYouPage.bookingDetail')}
          </Typography>
          <Button startIcon={<Receipt />} color="default" variant='contained' onClick={handleClickReservations}>
            {t('checkout.thankYouPage.myBookings')}
          </Button>
        </Grid>
        <Grid item xs={24} sm={9} className='column' style={{ borderRight: '1px solid lightgray' }}>
          <Typography>
            {t('checkout.thankYouPage.addProducts')}
          </Typography>
          <Button startIcon={<AddCircle />} variant='contained' onClick={() => handleSelectReservation(params.reservationNumber)}>
            {t('checkout.thankYouPage.continueBooking')}
          </Button>
        </Grid>
        <Grid item xs={24} sm={7} xl={8} className='column'>
          <Typography>
            {t('checkout.thankYouPage.newBooking')}
          </Typography>
          <Button startIcon={<AddCircle />} color='secondary' variant='outlined' onClick={() => handleSelectReservation()}>
            {t('checkout.thankYouPage.reserve')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  }

  return (
    <Container className='booking-status-pending'>
      <Grid container spacing={2}>
        <Grid item md={8} className='col-content'>
          <StatusMessage
            title={content.title}
            icon={content.icon}
            statusMessage={content.statusMessage}
            cardContentTop={<Fragment />}
            cardContentStatusTransaction={<CardContentStatusTransaction />}
            cardContentMessage={<CardContentMessage />}
          />
          {!params.tokenIframe && !clientData.checkout?.hideSafeTravel && <AssistComponent />}
          <ReservationContainer />
        </Grid>

        {props.purchaseDetail && (
          <Grid item md={4} className="col-sidebar">
            <PurchaseDetail
              purchaseDetail={props.purchaseDetail}
              products={props.products}
              module={params.module}
            />
            <SecureSite client={props.client?.client} />
          </Grid>
        )}
      </Grid>
    </Container>
  )
}

const mapStateToProps = reducers => {
  return reducers.checkoutReducer;
};

const mapDispatchToProps = dispatch => {
  return {
    getRetrieve: (tokenCheckout, data) => dispatch(getRetrieveAction(tokenCheckout, data)),
    getBooking: (access, tokenCheckout, data) => dispatch(getBookingAction(access, tokenCheckout, data))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Pending);
