import { Accordion, AccordionDetails, AccordionSummary, Card, CardHeader, Grid, Tooltip, Typography } from '@mui/material';
import FlightScales from '../../../modules/Flights/FlightScales';
import { AirlineSeatReclineNormal, ChangeCircleOutlined, CurrencyExchange, ExpandMore } from '@mui/icons-material';
import Image from '../../../common/Image';
import { ReactComponent as SmallBaggage } from '../../../../assets/images/baggage/smallBaggage.svg';
import { ReactComponent as MediumBaggage } from '../../../../assets/images/baggage/mediumBaggage.svg';
import { ReactComponent as LargeBaggage } from '../../../../assets/images/baggage/largeBaggage.svg';
import { ReactComponent as MultiplesAirlines } from '../../../../assets/images/providersFlights/multiple.svg';
import { Fragment } from 'react';

const getPrice = (price) => {
  return Number(price).toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

const getTotalPrice = (purchaseDetail) => {
  const charges = purchaseDetail.priceDetail.flatMap(elem => elem.charge);
  const netAgency = charges.reduce((acc, current) => acc + (current?.valorReal || 0), 0);
  return getPrice(netAgency);
}

const getProductsDetails = (purchaseDetail) => {
  const charges = purchaseDetail?.priceDetail
    .flatMap(product => product.charge)
    .filter(charge => (
      charge.title.includes('Adulto')
      || charge.title.includes('Niño')
      || charge.title.includes('Infante')
    ));

  return charges.map((item, i) => (
    <Grid
      key={i}
      container
      className='item-resume'
    >
      <Grid item md={6}>
        <div className='container-item-resume row'>
          <span>{item.title}</span>
        </div>
      </Grid>
      <Grid item md={6}>
        <div className={`text-right`}>
          <span>
            {purchaseDetail?.currency} {getPrice(item.valorReal)}
          </span>
        </div>
      </Grid>
    </Grid>
  ));
}

const getPriceDetails = (purchaseDetail, isModal = false) => {
  const charges = purchaseDetail.priceDetail.flatMap(elem => elem.charge);
  const netAgency = charges.reduce((acc, current) => acc + (current?.valorReal || 0), 0);
  const taxes = charges.filter(elem => (
    !elem.title.includes('Adulto') && !elem.title.includes('Niño') && !elem.title.includes('Infante')
  ));
  const totalTickets = netAgency - taxes.reduce((acc, current) => acc + (current.valorReal || 0), 0);

  if (isModal) {
    return [
      { title: 'netAgency', value: getPrice(netAgency), classname: 'strong amount', showTax: true }
    ]
  } else {
    return [
      { title: 'totalTickets', value: getPrice(totalTickets), classname: 'strong sub-amount' },
      ...taxes.map(elem => ({ title: elem.title, value: getPrice(elem.valorReal), noTranslate: true })),
      { title: 'netAgency', value: getPrice(netAgency), classname: 'strong amount', showTax: true }
    ];
  }
}

const getCardDetail = (cardDetail, t) => {

  const getCabinClass = () => {
    const classes = {
      Y: t('results.flights.economy'),
      B: t('results.flights.business'),
      F: t('results.flights.firstClass'),
      P: t('results.flights.premium'),
      W: t('results.flights.economy')
    };

    const cabinClass = cardDetail?.fareFeatures?.find(elem => elem.Code === 'CABIN_CLASS')?.Value
      || cardDetail?.legs?.[0]?.Options?.[0]?.Segments?.[0]?.CabinClass;
    if (cabinClass && classes[cabinClass]) {
      return `${t('results.flights.class')} ${classes[cabinClass]}`;
    }

    return '';
  }

  const getFareFeatures = () => {
    if (cardDetail?.fareFeatures) {
      const allFeatures = {
        SEAT_SELECTION: { value: t('results.flights.notIncluded') },
        CHANGEABLE: { value: t('results.flights.notIncluded') },
        REFUNDABLE: { value: t('results.flights.notIncluded') },
      };

      const seatSelection = cardDetail?.fareFeatures?.filter(elem => elem.Code === 'SEAT_SELECTION');
      if (seatSelection.length > 0) {
        allFeatures['SEAT_SELECTION'].value = t('results.flights.included');
        allFeatures['SEAT_SELECTION'].className = 'included';
      }

      const changeable = cardDetail?.fareFeatures?.filter(elem => elem.Code === 'CHANGEABLE');
      if (changeable.length > 0) {
        allFeatures['CHANGEABLE'].value = changeable.find(elem => elem?.Description?.includes('WITH COST'))
          ? t('results.flights.withExtraCost')
          : t('results.flights.withoutExtraCost');
        allFeatures['CHANGEABLE'].className = 'included';
      }

      const refundable = cardDetail?.fareFeatures?.filter(elem => elem.Code === 'REFUNDABLE');
      if (refundable.length > 0) {
        allFeatures['REFUNDABLE'].value = refundable.find(elem => elem?.Description?.includes('WITH COST'))
          ? t('results.flights.allowedWithPenalty')
          : t('results.flights.allowedWithoutPenalty');
        allFeatures['REFUNDABLE'].className = 'included';
      }

      return <Grid container>
        <Grid xs={12} md={4} className='row fare-feature'>
          <AirlineSeatReclineNormal className={allFeatures['SEAT_SELECTION'].className || ''} />
          <Typography><b>{t('results.flights.seatSelection')}:</b> {allFeatures['SEAT_SELECTION'].value}</Typography>
        </Grid>

        <Grid xs={12} md={3} className='row fare-feature'>
          <ChangeCircleOutlined className={allFeatures['CHANGEABLE'].className || ''} />
          <Typography><b>{t('results.flights.changes')}:</b> {allFeatures['CHANGEABLE'].value}</Typography>
        </Grid>

        <Grid xs={12} md={5} className='row fare-feature'>
          <CurrencyExchange className={allFeatures['REFUNDABLE'].className || ''} />
          <Typography><b>{t('results.flights.refund')}:</b> {allFeatures['REFUNDABLE'].value}</Typography>
        </Grid>
      </Grid>
    }
  }

  const getScalesTooltip = (segments) => {
    if (segments.length === 1) {
      return '';
    }

    return <>
      {segments.slice(1).map((segment, i) => (
        <Grid key={i}>
          <Typography><b>{segment.Departure.CountryName} ({segment.Departure.CityCode})</b></Typography>
          <Typography>
            {t('results.flights.scaleWait', {
              city: segment.Departure.CityName,
              hours: segment.WaitHours,
              minutes: segment.WaitMinutes
            })}
          </Typography>
        </Grid>
      ))}
    </>
  }

  const getSegmentsDuration = (segments) => {
    return <>
      {segments.map((segment, i) => {
        const hours = Math.floor(segment.Duration / 60);
        const minutes = segment.Duration % 60;

        return <Fragment key={i}>
          {i > 0 && (
            <Grid className='row' justifyContent='space-between'>
              <Typography style={{ marginRight: 20 }}>
                {t('results.flights.scaleIn', { airport: segment.Departure.AirportCode })}
              </Typography>
              <Typography>{segment.WaitHours}h {segment.WaitMinutes}m</Typography>
            </Grid>
          )}

          <Grid className='row' justifyContent='space-between'>
            <Typography style={{ marginRight: 20 }}>
              <b>{segment.Departure.AirportCode} - {segment.Arrival.AirportCode}</b>
            </Typography>
            <Typography><b>{hours}h {minutes}m</b></Typography>
          </Grid>
        </Fragment>
      })}
    </>
  }

  const getDaysAfterTooltip = (days) => {
    return days === 1
      ? t('results.flights.dayAfter')
      : t('results.flights.daysAfter', { days });
  }

  const getBaggages = (option, vertical = true) => {
    const baggages = [
      {
        icon: <SmallBaggage />,
        title: t('results.flights.smallBaggageTitle'),
        description: t('results.flights.smallBaggageDescription')
      }
    ];

    let mediumBaggage = {
      icon: <MediumBaggage className='not-included' />,
      title: t('results.flights.mediumBaggageNotIncluded')
    };

    let largeBaggage = {
      icon: <LargeBaggage className='not-included' />,
      title: t('results.flights.largeBaggageNotIncluded')
    };

    if (cardDetail?.fareFeatures) {
      const mediumBaggageIncluded = cardDetail?.fareFeatures?.find(elem => elem.Code === 'CABIN_BAG');
      if (mediumBaggageIncluded) {
        mediumBaggage = {
          icon: <MediumBaggage />,
          title: t('results.flights.mediumBaggageTitle'),
          description: t('results.flights.mediumBaggageDescription')
        };
      }

      const largeBaggageIncluded = cardDetail?.fareFeatures?.filter(elem => elem.Code === 'CHECKED_BAG');
      if (largeBaggageIncluded.length > 0) {
        const description = [];

        cardDetail?.axFares?.forEach(elem => {
          const passenger = elem.PaxType === 'ADT'
            ? t('results.flights.adult')
            : elem.PaxType === 'CHD'
              ? t('results.flights.kid')
              : t('results.flights.baby');
          const pieces = largeBaggageIncluded.length;
          description.push(t(
            `results.flights.${pieces > 1 ? 'piecesPerPassenger' : 'piecePerPassenger'}`,
            { pieces, passenger: passenger.toLowerCase() }
          ));
        });

        description.push(t('results.flights.largeBaggageDescription'));

        largeBaggage = {
          icon: <LargeBaggage />,
          title: t('results.flights.largeBaggageIncluded'),
          description: description.join(' ')
        };
      }
    } else if (!['0PC', '0K'].includes(option?.Segments?.[0]?.Baggage)) {
      const description = [];

      if (option?.Segments?.[0]?.Baggage?.includes('PC')) {
        const splitPC = option?.Segments?.[0]?.Baggage?.split('PC');
        const pieces = splitPC.length > 0 ? splitPC[0] : 0;
        cardDetail?.paxFares?.forEach(elem => {
          const passenger = elem.PaxType === 'ADT'
            ? t('results.flights.adult')
            : elem.PaxType === 'CHD'
              ? t('results.flights.kid')
              : t('results.flights.baby');
          description.push(t(
            `results.flights.${pieces > 1 ? 'piecesPerPassenger' : 'piecePerPassenger'}`,
            { pieces, passenger: passenger.toLowerCase() }
          ));
        })
      } else if (option?.Segments?.[0]?.Baggage?.includes('K')) {
        const splitPC = option?.Segments?.[0]?.Baggage?.split('K');
        const kg = splitPC.length > 0 ? splitPC[0] : 0;
        cardDetail?.paxFares?.forEach(elem => {
          const passenger = elem.PaxType === 'ADT'
            ? t('results.flights.adult')
            : elem.PaxType === 'CHD'
              ? t('results.flights.kid')
              : t('results.flights.baby');
          description.push(t('results.flights.pieceKgPerPassenger', { kg, passenger: passenger.toLowerCase() }));
        })
      }

      description.push(t('results.flights.largeBaggageDescription'));

      mediumBaggage.title = t('results.flights.mediumBaggageTitle');
      mediumBaggage.description = t('results.flights.mediumBaggageDescription');
      mediumBaggage.icon = <MediumBaggage />

      largeBaggage.title = t('results.flights.largeBaggageIncluded');
      largeBaggage.description = description.join(' ');
      largeBaggage.icon = <LargeBaggage />
    }

    baggages.push(mediumBaggage);
    baggages.push(largeBaggage);

    return <Grid container className='baggage-container' spacing={1}>
      {baggages.map((elem, i) => (
        <Grid item xs={vertical ? 12 : 4} key={i}>
          <Grid container className='row' key={i} spacing={1} columns={24}>
            <Grid item xs={3} className='icon-container'>{elem.icon}</Grid>
            <Grid item xs={21} className='column baddage-description'>
              <b>{elem.title}</b>
              {elem.description && <span>{elem.description}</span>}
            </Grid>
          </Grid>
        </ Grid>
      ))}
    </Grid>
  }

  const getBaggageIcons = (option) => {
    const baggage = {};

    if (cardDetail?.fareFeatures) {
      const mediumBaggageNotIncluded = !cardDetail?.fareFeatures?.find(elem => elem.Code === 'CABIN_BAG');
      if (mediumBaggageNotIncluded) {
        baggage['medium'] = 'not-included';
      }

      const largeBaggageNotIncluded = !cardDetail?.fareFeatures?.find(elem => elem.Code === 'CHECKED_BAG');
      if (largeBaggageNotIncluded) {
        baggage['large'] = 'not-included';
      }
    } else if (['0PC', '0K'].includes(option?.Segments?.[0]?.Baggage)) {
      baggage['medium'] = 'not-included';
      baggage['large'] = 'not-included';
    }

    return <Tooltip title={getBaggages(option)}>
      <SmallBaggage />
      <MediumBaggage className={baggage['medium']} />
      <LargeBaggage className={baggage['large']} />
    </Tooltip>
  }

  const getOptionContainer = (option) => {
    const segmentsLength = option?.Segments?.length;
    const departure = option?.Segments?.[0]?.Departure;
    const arrival = option?.Segments?.[segmentsLength - 1]?.Arrival;
    const providers = [...new Set(option?.Segments?.map(elem => elem.Airline))];

    return <Grid container columns={24} className='option-container'>
      <Grid item className='row' xs={3}>
        {providers.length === 1 ? (
          <Image img={`providersFlights/${providers[0]}.png`} alt='logo-provider' className='airline-logo' hideBrokenImage />
        ) : (
          <div className='row'>
            <MultiplesAirlines className='multiple-logo' />
            &nbsp;<span>{t('results.flights.multipleAirlines')}</span>
          </div>
        )}
      </Grid>

      <Grid item className='column' xs={6}>
        <Tooltip title={departure?.AirportName || ''}>
          <Typography className='title'>{departure?.AirportCode}</Typography>
        </Tooltip>
        <Typography className='time'>{departure?.Time}</Typography>
      </Grid>

      <Grid item className='column' xs={6}>
        <Tooltip title={getScalesTooltip(option?.Segments)} placement='top'>
          <Typography className={`scales-text ${segmentsLength === 1 && 'direct'}`}>
            {segmentsLength > 1 && <>{segmentsLength - 1}&nbsp;</>}
            {t(`results.flights.${segmentsLength === 1 ? 'direct' : segmentsLength === 2 ? 'scale' : 'scales'}`)}
          </Typography>
        </Tooltip>
        <div className='scale-line'>
          <div className='circles-container row'>
            {option?.Segments.slice(1).map((elem, i) => <div key={i} className='circle' />)}
          </div>
        </div>
        <span>
          <Tooltip title={getSegmentsDuration(option?.Segments)} placement='bottom'>
            <span className='duration'>{option.OptionDurationHours}h {option.OptionDurationMinutes}m</span>
          </Tooltip>
          {option.DayAfter > 0 && (
            <Tooltip title={getDaysAfterTooltip(option.DayAfter)} placement='bottom'>
              <span className='plus-days'>+{option.DayAfter}D</span>
            </Tooltip>
          )}
        </span>
      </Grid>

      <Grid item className='column' xs={6}>
        <Tooltip title={arrival?.AirportName || ''}>
          <Typography className='title'>{arrival?.AirportCode}</Typography>
        </Tooltip>
        <Typography className='time'>{arrival?.Time}</Typography>
      </Grid>

      <Grid item className='row baggage-container-icons' xs={3}>
        {getBaggageIcons(option)}
      </Grid>
    </Grid>
  }

  return <>
    <Card className='fare-features-card'>
      <CardHeader className='title-container text-center' title={getCabinClass()} />
      {getFareFeatures()}
    </Card>

    {cardDetail?.legs?.map((leg, i) => (
      <Accordion key={i} className='option-accordion'>
        <AccordionSummary expandIcon={<ExpandMore />}>
          {getOptionContainer(leg.Options[0])}
        </AccordionSummary>
        <AccordionDetails>
          <FlightScales option={leg.Options[0]} />
          {getBaggages(leg.Options[0], false)}
        </AccordionDetails>
      </Accordion>
    ))}
  </>
}

export {
  getTotalPrice,
  getProductsDetails,
  getPriceDetails,
  getCardDetail
};
