import allCountries from './allCountries.json';
import allStates from './allStates.json';

export const getCountry = () => {
  return allCountries.sort((a, b) => a.description.toLowerCase().localeCompare(b.description.toLowerCase()));
}

export const getStatesByCountry = (countryId) => {
  if (countryId !== undefined && countryId !== '') {
    let states = allStates.filter(elem => elem.country_id === countryId);
    if (states.length === 0) {
      const country = allCountries.find(elem => elem.id === countryId);
      states = [{
        id: country.id,
        name: country.description
      }]
    }

    return states.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
  }

  return [];
}

export const getSituation = () =>{
  const response = [
    { code: '04', description: 'Consumidor Final' },
    { code: '01', description: 'Responsable Incripto' },
    { code: '07', description: 'Responsable no Incripto' },
    { code: '03', description: 'Exento' },
    { code: '05', description: 'Responsable Monotributo' },
    { code: '06', description: 'No responsables' }
  ];

  return response;
}

export const getDocType = (country = null) =>{
  let response = [];

  if (country === 'BR') {
    response = [
      { code: 'CPF', description: 'CPF' },
      { code: 'CNPJ', description: 'CNPJ' }
    ];
  } else {
    response = [
      { code: 'Dni', description: 'DNI' },
      { code: 'Lc', description: 'LC' },
      { code: 'PP', description: 'PAS' }
    ];
  }

  return response;
}