import React, { useEffect } from 'react';

const useScript = (url, widgetCode, showZohoBot) => {
  useEffect(() => {
    if (showZohoBot) {
      const script = document.createElement('script');
      script.setAttribute("type", "text/javascript");

      const code = `var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "${widgetCode}", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="${url}";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);d.innerHTML = "<div id='zsiqwidget'></div>";`

      script.appendChild(document.createTextNode(code));
      document.body.appendChild(script);

      document.querySelector('[data-id=zsalesiq]')?.classList?.remove('hidden');

      return () => {
        document.body.removeChild(script);
      }
    }
    
    document.querySelector('[data-id=zsalesiq]')?.classList?.add('hidden');
  }, [url, widgetCode, showZohoBot]);
};

export default function ZohoBot({ showZohoBot }) {
  return (
    <React.Fragment>
      {useScript(
        'https://salesiq.zoho.com/widget',
        '55d893b31b577635128f7a49bd1800f33ad41c180f2e687953a08fe8772078341a2010ab7b6727677d37b27582c0e9c4',
        showZohoBot
      )}
    </React.Fragment>
  );
}
