import React, { useEffect, useState } from 'react';
import { Alert, Button, Container, Grid, Hidden, IconButton, Rating, Snackbar, Tooltip, Typography } from '@mui/material';
import { connect } from 'react-redux';
import { addFavoriteActionGimmonix, cacheActionGimmonix, deleteFavoriteActionGimmonix, filterActionGimmonix, getFavoritesActionGimmonix, getFiltersActionGimmonix, resetResultsActionGimmonix, searchActionGimmonix } from '../../../store/actions';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import FiltersComponent from './FiltersComponent';
import ResultsComponent from './ResultsComponent';
import ComparationComponent from './ComparationComponent';
import { ArrowBack, Close, LocationOn } from '@mui/icons-material';
import HotelModal from './HotelModal';
import Preloader from '../../common/Preloader';
import { useTranslation } from 'react-i18next';
import { checkToken } from '../../../store/services/Login';
import Multibuscador from '../../common/Multibuscador/Multibuscador';
import { useClientData } from '../../../context/ClientContext';

const PROVIDERS = [
  { name: 'abreu', image: require('../../../assets/images/providersGimmonix/abreu.png') },
  { name: 'actiontravel', image: require('../../../assets/images/providersGimmonix/actiontravel.png') },
  { name: 'dotw', image: require('../../../assets/images/providersGimmonix/destinationoftheworld.png') },
  { name: 'expedia', image: require('../../../assets/images/providersGimmonix/expedia.png') },
  { name: 'grnconnect', image: require('../../../assets/images/providersGimmonix/GRNconnect.png') },
  { name: 'goglobal', image: require('../../../assets/images/providersGimmonix/goglobal.png') },
  { name: 'hotelbeds', image: require('../../../assets/images/providersGimmonix/hotelbeds.png') },
  { name: 'jumbotours', image: require('../../../assets/images/providersGimmonix/jumbotours.png') },
  { name: 'methabook', image: require('../../../assets/images/providersGimmonix/methabook.png') },
  { name: 'mybeds', image: require('../../../assets/images/providersGimmonix/mybeds.png') },
  { name: 'ocean', image: require('../../../assets/images/providersGimmonix/ocean.png') },
  { name: 'ratehawk', image: require('../../../assets/images/providersGimmonix/ratehawk.png') },
  { name: 'restel', image: require('../../../assets/images/providersGimmonix/restel.png') },
  { name: 'riu', image: require('../../../assets/images/providersGimmonix/riu.png') },
  { name: 'sirenis', image: require('../../../assets/images/providersGimmonix/sirenis.png') },
  { name: 'smyrooms', image: require('../../../assets/images/providersGimmonix/smyrooms.png') },
  { name: 'tboholidays', image: require('../../../assets/images/providersGimmonix/tboholidays.png') },
  { name: 'welcomebeds', image: require('../../../assets/images/providersGimmonix/welcomebeds.png') },
  { name: 'world2meet', image: require('../../../assets/images/providersGimmonix/w2m.png') },
  { name: 'iberostarw2m', image: require('../../../assets/images/providersGimmonix/iberostarw2m.png') }
]

const FILTERS = [
  'Stars Count',
  'Property Count',
  'BoardBase Count',
  'Rate Type Count',
  'Amenities Count',
  'Provider Count',
  'Price Count'
]

function ResultsView({
  isLoading,
  resultSearch,
  resultCache,
  filtersResult,
  filterSearch,
  resultFavorites,
  error,
  errorFilter,
  errorRule,
  selectedReservation,
  ...props
}) {
  const { t } = useTranslation();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { setClientData } = useClientData();
  const [dataForm, setDataForm] = useState();
  const [results, setResults] = useState();
  const [filters, setFilters] = useState({});
  const [hashFlow, setHashFlow] = useState();
  const [loading, setLoading] = useState(false);
  const [abortController, setAbortController] = useState();
  const [activeSort, setActiveSort] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [selectedHotel, setSelectedHotel] = useState();
  const [defaultView, setDefaultView] = useState('map');
  const [resultsToCompare, setResultsToCompare] = useState([]);
  const [showComparation, setShowComparation] = useState(false);
  const [lastSearch, setLastSearch] = useState();
  const [noResults, setNoResults] = useState(false);
  const [favorites, setFavorites] = useState([]);
  const [repeatLastFilters, setRepeatLastFilters] = useState();
  const [showErrorRule, setShowErrorRule] = useState(false);
  const [openSnackBar, setOpenSnackbar] = useState(false);
  const [initialHotelNames, setInitialHotelNames] = useState([]);
  const [hashFlowCache, setHashFlowCache] = useState()
  const [showSkeleton, setShowSkeleton] = useState(false);

  useEffect(() => {
    let user = localStorage.getItem('user');
    if (user) {
      user = JSON.parse(user);
      if (user.email) {
        props.getFavorites(user.email);
      }
    }

    return () => {
      abortController && abortController.abort();
      props.resetResults();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    ;(async () => {
      try {
        setShowSkeleton(true);
        const accessToken = localStorage.getItem('jwt');
        await checkToken(accessToken);

        setSelectedHotel(null);
        setOpenModal(false);
        const { destination, type, codes, pointInterest, checkin, checkout, rooms } = params;
        const codesSplit = codes.split(',');
        const checkinAux = dayjs(checkin, 'DDMMYY');
        const checkoutAux = dayjs(checkout, 'DDMMYY');
        const guests = {
          rooms: 0,
          roomInfo: []
        };

        rooms.split(';').forEach(room => {
          const roomObj = {};
          const split = room.split('!');
          const pax = split[0].split('-');
          const ages = split[1]?.split('-') || [];

          roomObj.adults = parseInt(pax[0]);
          roomObj.minors = parseInt(pax[1]);
          roomObj.minorAges = ages;
          guests.roomInfo.push(roomObj);
          guests.rooms += 1;
        });

        const newDataForm = {
          destination: {
            label: toCamelCase(destination)
          },
          pointInterest: pointInterest === '1',
          checkin: checkinAux.format('DD/MM/YYYY'),
          checkout: checkoutAux.format('DD/MM/YYYY'),
          nights: checkoutAux.diff(checkinAux, 'day'),
          guests
        };
        if (type === 'geo') {
          newDataForm.destination.location = {
            lat: codesSplit[0],
            lng: codesSplit[1],
            radius: codesSplit[2],
          };
        } else if (type === 'hotel') {
          newDataForm.destination.gimmonix_id = codesSplit[0];
        }
        setDataForm(newDataForm);

        if (
          codes !== undefined &&
          checkin !== undefined &&
          checkout !== undefined &&
          rooms !== undefined
        ) {
          const currentLocation = location.pathname + location.search.slice(1);
          const search = new URLSearchParams(location.search);

          const sort = search.get('sorting');
          if (sort) {
            const split = sort.split('_');
            setActiveSort({ name: split[0], sort: split[1] });
          }

          let lastSearchAux = lastSearch?.indexOf('pag=');
          let currentLocationAux = currentLocation?.indexOf('pag=');
          lastSearchAux = lastSearchAux > -1 ? lastSearch.slice(0, lastSearchAux - 1) : lastSearch;
          currentLocationAux = currentLocationAux > -1 ? currentLocation.slice(0, currentLocationAux) : currentLocation;

          if (lastSearchAux !== currentLocationAux || search.size === 0) {
            setLastSearch(location.pathname + location.search.slice(1));
            setNoResults(false);
            setResultsToCompare([]);

            if (search.size > 0 && search.get('searchId')) {
              let query = location.search + '&nrfBook=true';
              if (hashFlowCache && !hashFlow) {
                const { checkin, checkout } = params;
                const checkinAux = dayjs(checkin, 'DDMMYY');
                const checkoutAux = dayjs(checkout, 'DDMMYY');
                const nights = checkoutAux.diff(checkinAux, 'day');
                query += `&nights=${nights}&cache=true`;
              }
              props.filterProducts(query);
            } else {
              abortController && abortController.abort();
              const controller = new AbortController();
              setAbortController(controller);
              setLoading(true);
              setHashFlow(null);
              setHashFlowCache(null);
              setShowErrorRule(false);

              const hotelProduct = JSON.parse(localStorage.getItem('products') || '[]')
                ?.find(elem => elem.ref_table_detail === 'hotel_detail');
              const token = hotelProduct?.parameters?.['API-Token'];
              if (token) {
                const dataToSendCache = {
                  search_params: location.pathname.slice(location.pathname.indexOf('/geo')),
                  credential: token
                }
                const dataToSend = {
                  type: type,
                  code: [codesSplit],
                  checkin: checkinAux.format('YYYY-MM-DD'),
                  checkout: checkoutAux.format('YYYY-MM-DD'),
                  rooms: rooms,
                  offers: '0',
                  filters: {
                    nombreHotel: null,
                    categoria: null,
                    precioMin: null,
                    precioMax: null,
                    order: 'asc'
                  },
                  token
                }

                if (type === 'hotel') {
                  dataToSend.code = [['0', '0', '0']];
                  dataToSend.ids_gimmonix = [codesSplit[0]];
                }

                const dataRule = {
                  token_product: hotelProduct?.token_id,
                  token_workunit: hotelProduct?.work_unit_token,
                  hash_product: hotelProduct?.hash_product,
                  rules_parameters: {
                    destination_name: destination,
                    country_code: 'NA',
                    checkin: checkinAux.format('YYYY-MM-DD'),
                    destination_type: 3,
                    source: 'NA',
                    rule_type: 'PRICE',
                    supplier: 'NA',
                  },
                  data: ''
                };

                if ("business_unit_token" in hotelProduct) {
                  dataRule.rules_parameters['business_unit_token'] = hotelProduct["business_unit_token"]
                }

                props.searchProducts(accessToken, dataRule, dataToSend, controller.signal);
                props.searchCache(dataToSendCache, controller.signal);

                let newActiveSort = { name: 'recommended' };
                if (newDataForm?.pointInterest) {
                  newActiveSort = {
                    name: 'distance',
                    sort: 'shorter'
                  };
                }
                setActiveSort(newActiveSort);
                setShowComparation(false);
              } else {
                const iframePath = params.tokenIframe ? `/${params.tokenIframe}` : '';
                if (iframePath) {
                  localStorage.clear();
                  setClientData(null);
                  navigate(iframePath);
                } else {
                  navigate('/login', { state: { expiredSession: true } });
                }
              }
            }
          }
        }
      } catch (error) {
        const iframePath = params.tokenIframe ? `/${params.tokenIframe}` : '';
        if (iframePath) {
          localStorage.clear();
          setClientData(null);
          navigate(iframePath);
        } else {
          navigate('/login', { state: { expiredSession: true } });
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (Boolean(resultFavorites?.hotels)) {
      setFavorites(resultFavorites?.hotels);

      if (results) {
        updateFavoriteFilter(resultFavorites.hotels, results, filters);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultFavorites]);

  const sortInitialData = (data) => {
    if (activeSort?.name === 'distance') {
      return data.sort((a, b) => a.toReference - b.toReference);
    } else if (activeSort?.name === 'recommended') {
      return [
        ...data.filter(elem => elem.recommended === true),
        ...data.filter(elem => elem.recommended === false)
      ]
    }
  }

  useEffect(() => {
    if (resultCache?.data && resultCache?.filters?.result && resultCache?.hash_flow) {
      const hotels = resultCache?.data || [];
      const sortedHotels = sortInitialData(hotels);
      setResults(sortedHotels);
      setHashFlowCache(resultCache?.hash_flow);
      setInitialHotelNames(hotels.map(elem => ({ name: elem.name, id: elem.id })));

      const filtersAux = transformFilters(resultCache?.filters?.result, true);
      if (favorites) {
        updateFavoriteFilter(favorites, hotels, filtersAux);
      } else {
        setFilters(filtersAux);
      }

      if (repeatLastFilters && resultCache?.hash_flow) {
        const url = location.pathname + `?searchId=${resultCache?.hash_flow}&${repeatLastFilters}`;
        navigate(url);
      } else {
        setShowSkeleton(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultCache]);

  useEffect(() => {
    if (loading) {
      if (resultSearch?.data?.hotels) {
        const hotels = resultSearch?.data?.hotels || [];
        const sortedHotels = sortInitialData(hotels);
        if (!hashFlowCache || results.length === 0) {
          setResults(sortedHotels);
        } else if (results.length > 0) {
          const updatedResults = results.map(hotel => {
            const newInfo = hotels.find(elem => elem.id === hotel.id);
            return newInfo || null;
          }).filter(elem => elem);
          setResults(updatedResults);
        }

        setNoResults(sortedHotels.length === 0);
        setAbortController(null);
        setHashFlow(resultSearch?.hashFlow);
        setInitialHotelNames(hotels.map(elem => ({ name: elem.name, id: elem.id })));
        props.getFiltersProducts(`?searchId=${resultSearch?.hashFlow}&nfrBook=true`, null);

        if (favorites) {
          updateFavoriteFilter(favorites, sortedHotels, filters);
        }

        if (repeatLastFilters && resultSearch?.hashFlow) {
          const url = location.pathname + `?searchId=${resultSearch?.hashFlow}&${repeatLastFilters}`;
          navigate(url);
        } else {
          setLoading(false);
          setShowSkeleton(false);
        }
      } else {
        setResults(null);
        setHashFlow(null);
        setFilters({});
        if (resultSearch?.errorType) {
          setLoading(false);
          setShowSkeleton(false);
          setNoResults(true);
        }
        if (favorites) {
          updateFavoriteFilter(favorites, [], {});
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultSearch]);

  useEffect(() => {
    if (filtersResult?.filters) {
      setHashFlowCache(null);
      const search = new URLSearchParams(location.search);

      if (!hashFlowCache || results.length === 0 || search.size === 0) {
        setFilters(transformFilters(filtersResult?.filters?.result, true));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersResult]);

  useEffect(() => {
    setShowErrorRule(Boolean(errorRule));

    if (Boolean(errorRule)) {
      setLoading(false);
      setShowSkeleton(false);

      if (errorRule.includes('401')) {
        abortController && abortController.abort();
        const iframePath = params.tokenIframe ? `/${params.tokenIframe}` : '';
        if (iframePath) {
          localStorage.clear();
          setClientData(null);
          navigate(iframePath);
        } else {
          navigate('/login', { state: { expiredSession: true } });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorRule]);

  useEffect(() => {
    if (Object.keys(filterSearch).length > 0) {
      const hotels = filterSearch?.data?.hotels || [];
      setAbortController(null);
      setShowSkeleton(false);
      setResults(hotels);
      setNoResults(hotels.length === 0);
      setRepeatLastFilters(null);

      if (!initialHotelNames || initialHotelNames.length === 0) {
        setInitialHotelNames(hotels.map(elem => ({ name: elem.name, id: elem.id })));
      }

      if (!hashFlowCache && !hashFlow) {
        setHashFlow(filterSearch?.data?.hash_flow);
      }

      const filtersAux = transformFilters(filterSearch?.data?.filters?.result);
      if (favorites) {
        updateFavoriteFilter(favorites, hotels, filtersAux);
      } else {
        setFilters(filtersAux);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSearch]);

  useEffect(() => {
    if (loading && error && error !== 'canceled') {
      setNoResults(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    if ((loading || showSkeleton) && errorFilter && errorFilter !== 'canceled') {
      if (errorFilter.includes('list index out of range')) {
        setShowSkeleton(false);
        setFilters({
          ...filters,
          hotelName: { name: t('results.hotels.hotelNotFound') }
        });
        setRepeatLastFilters(null);
      } else {
        if (!repeatLastFilters) {
          const search = new URLSearchParams(location.search);
          search.delete('searchId');
          setRepeatLastFilters(search.toString());
        } else {
          setRepeatLastFilters(null);
        }

        navigate(location.pathname);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorFilter]);

  const transformFilters = (filtersAux, resetFilters = false) => {
    const transformedFilters = {};
    FILTERS.forEach(elem => {
      let name = elem.replaceAll(' ', '');
      name = name[0].toLowerCase() + name.slice(1);
      transformedFilters[name] = getFilter(filtersAux, elem);
    })
    transformedFilters.favoritesCount = filters.favoritesCount;
    if (resetFilters) {
      transformedFilters.hotelName = {};
    }
    return transformedFilters;
  }

  const getFilter = (filtersAux , filter) => {
    return filtersAux?.find(elem => {
      const keys = Object.keys(elem);
      return keys.includes(filter);
    })?.[filter]
  }

  const updateFavoriteFilter = (favoritesAux, resultsAux, filtersAux) => {
    const favoritesIds = favoritesAux.map(elem => parseInt(elem.id));
    const favoritesHotels = resultsAux.filter(elem => favoritesIds.includes(elem.id));
    const favoriteFilter = {
      active: filtersAux?.favoritesCount?.active || false,
      total: favoritesHotels.length
    }
    setFilters({
      ...filtersAux,
      favoritesCount: favoriteFilter
    })
  }

  const toCamelCase = (str) => {
    let words = str.split('-');
    words = words.filter(elem => elem).map(elem => elem[0].toUpperCase() + elem.slice(1));
    return words.join(' ');
  }

  const handleChangeFilters = (data) => {
    if (hashFlow || hashFlowCache) {
      let url = location.pathname + `?searchId=${hashFlow || hashFlowCache}`;

      if (activeSort?.name) {
        url += `&sorting=${activeSort.name}`;

        if (activeSort.sort) {
          url += `_${activeSort.sort}`;
        }
      }

      if (data?.stars && data?.stars.length > 0) {
        url += `&stars=${data?.stars.join('-')}`;
      }

      if (data?.property && data?.property.length > 0) {
        url += `&property=${data?.property.map(elem => elem.idFilter.replaceAll(' ', '+')).join('-')}`;
      }

      if (data?.boardbase && data?.boardbase.length > 0) {
        url += `&boardbase=${data?.boardbase.map(elem => elem.idFilter.replaceAll(' ', '+')).join('-')}`;
      }

      if (data?.rateType && data?.rateType.length > 0) {
        url += `&rateType=${data?.rateType.map(elem => elem.idFilter.replaceAll(' ', '+')).join('-')}`;
      }

      if (data?.amenities && data?.amenities.length > 0) {
        url += `&amenities=${data?.amenities.map(elem => elem.id_filter).join('-')}`;
      }

      if (data?.provider && data?.provider.length > 0) {
        url += `&providerName=${data?.provider.map(elem => elem.idFilter).join('-')}`;
      }

      if (data?.price && data?.price.length === 2) {
        url += `&price=${data?.price.join('-')}`;
      }

      if (data?.hotelName?.name) {
        url += `&data_hotel_name=${data.hotelName.name}`;
      }

      if (data?.hotelName?.id) {
        url += `&data_hotel=${data.hotelName.id}`;
      }

      navigate(url);

      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  }

  const handleChangeSort = (data) => {
    setActiveSort(data);

    let url = location.pathname + `?searchId=${hashFlow || hashFlowCache}`;

    if (data.name) {
      url += `&sorting=${data.name}`;

      if (data.sort) {
        url += `_${data.sort}`;
      }
    }

    navigate(url);
  }

  const handleClickHotel = (view, hotel) => {
    setDefaultView(view);
    setSelectedHotel(hotel);
    setOpenModal(true);
  }

  const handleClickCompareHotel = (hotel, checked) => {
    let resultsToCompareAux = [...resultsToCompare];
    if (checked) {
      resultsToCompareAux.push(hotel);
    } else {
      resultsToCompareAux = resultsToCompareAux.filter(elem => elem.id !== hotel.id);
    }
    if (checked && resultsToCompareAux.length < 2) {
      setShowComparation(false);
    }
    setResultsToCompare(resultsToCompareAux);
  }

  const handleClickComparation = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    setShowComparation(true)
  }

  const getComparatorContainer = () => {
    return <Grid className='comparator-container'>
      <Container>
        <Grid className='row' justifyContent='space-between' marginBottom='15px'>
          <Typography className='title'>{t('results.hotels.compareHotels')} ({resultsToCompare.length})</Typography>
          <Grid className='row buttons-container'>
            <Button variant='standard' onClick={() => setResultsToCompare([])}>
              <Close />{t('results.hotels.clearSelection')}
            </Button>
            <Button
              variant='outlined'
              onClick={handleClickComparation}
              disabled={resultsToCompare.length < 2}
            >
              {t('results.hotels.compare')}
            </Button>
          </Grid>
        </Grid>

        <Grid container className='row' spacing={2}>
          {resultsToCompare.map((elem, i) => (
            <Grid item key={i} xs={12} md={3}>
              <Grid className='hotel-info row'>
                <img
                  src={elem.image}
                  alt={elem.name}
                  style={{
                    height: '100px',
                    width: '100px',
                    objectFit: 'cover',
                    marginRight: '10px',
                    borderRadius: '12px',
                  }}
                />
                <Grid>
                  <Tooltip title={elem.name}>
                    <Typography className='name'>{elem.name}</Typography>
                  </Tooltip>
                  <Typography className='row location'>
                    <LocationOn fontSize='small' /><span>{`${elem.address}`}</span>
                  </Typography>
                  <Rating size="small" value={parseInt(elem.stars)} readOnly />
                  <Grid className='price-container'>
                    <Typography>{t('results.hotels.totalPriceFrom')}</Typography>
                    <Typography className='price'>{`${elem.currency} ${parseInt(elem.totalPrice)} `}</Typography>
                  </Grid>
                </Grid>

                <IconButton className='close-button' size='small' onClick={() => handleClickCompareHotel(elem, false)}>
                  <Close fontSize='small' />
                </IconButton>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Grid>
  }

  const handleCloseModal = () => {
    setOpenModal(false);
  }

  const handleClickFavorite = (hotel, isFavorite) => {
    const hotelProduct = JSON.parse(localStorage.getItem('products') || '[]')
      ?.find(elem => elem.ref_table_detail === 'hotel_detail');
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const token = hotelProduct?.parameters?.['API-Token'];

    if (Object.keys(user).length > 0 && token && hotel) {
      const hotelInfo = {
        image: hotel.image,
        amenities: hotel.amenities,
        images: hotel.images,
        address: hotel.address,
        boardbases: hotel.boardbases,
        name: hotel.name,
        destination: hotel.destination,
        stars: hotel.stars,
        recommended: hotel.recommended,
        latitude: hotel.latitude.toString(),
        id: hotel.id.toString(),
        longitude: hotel.longitude.toString(),
      };

      if (isFavorite) {
        const dataToSend = {
          hashFlow: hashFlow || hashFlowCache,
          token,
          user: user.email,
          product_type: 'hotels',
          product_info: hotelInfo
        };

        const favoritesAux = [...favorites, hotelInfo];
        setFavorites(favoritesAux);
        props.addFavorite(dataToSend);
        updateFavoriteFilter(favoritesAux, results, filters);
        setOpenSnackbar(true);
      } else {
        const favoritesAux = favorites.filter(elem => elem.id !== hotelInfo.id);
        setFavorites(favoritesAux);
        props.deleteFavorite(user.email, hotelInfo.id);
        updateFavoriteFilter(favoritesAux, results, filters);
      }
    } else {
      const iframePath = params.tokenIframe ? `/${params.tokenIframe}` : '';
      if (iframePath) {
        localStorage.clear();
        setClientData(null);
        navigate(iframePath);
      } else {
        navigate('/login', { state: { expiredSession: true } });
      }
    }
  }

  const handleClickFavoriteFilter = (checked) => {
    const favoriteFilter = {
      ...filters.favoritesCount,
      active: checked
    }
    setFilters(current => ({
      ...current,
      favoritesCount: favoriteFilter
    }))
  }

  const getResults = () => {
    if (filters?.favoritesCount?.active) {
      const favoritesIds = favorites.map(elem => parseInt(elem.id));
      const favoritesHotels = results?.filter(elem => favoritesIds.includes(elem.id));
      return favoritesHotels;
    } else {
      return results;
    }
  }

  const getMinimunPrice = () => {
    return Math.min.apply(Math, results.map(elem => elem.totalPrice));
  }

  console.log(dataForm);
  

  return (
    <Grid className='gimmonix-results results-page'>
      <Grid className='search-container'>
        <Container>
          <Multibuscador
            module='hotels'
            defaultData={dataForm}
            selectedReservation={selectedReservation}
          />
        </Container>
      </Grid>

      <Container>
        {!showSkeleton && noResults ? (
          <Alert severity='error' className='alert-no-results'>
            <Typography className='title'>
              {t('results.hotels.noHotels', { destination: dataForm?.destination?.label, interpolation: { escapeValue: false } })}
            </Typography>
            <Typography className='subtitle'>{t('results.hotels.otherSearch')}</Typography>
          </Alert>
        ) : !showSkeleton && showErrorRule ? (
          <Alert severity='warning' className='alert-error-rule'>
            <Typography className='title'>{t('results.hotels.communicateAdvisor')}</Typography>
          </Alert>
        ) : (
          <>
            {(!showComparation || resultsToCompare.length === 0) ? (
              <Grid className='results-container'>
                {!showSkeleton && (
                  <Typography className='title'>
                    {t('results.hotels.availableHotels', {
                      hotels: results?.length || 0,
                      destination: dataForm?.destination?.label,
                      interpolation: { escapeValue: false }
                    })} {!results || results.length === 0 ? '' : t('results.hotels.fromPrice', { price: parseInt(getMinimunPrice()) })}
                  </Typography>
                )}

                <Grid container columnSpacing={3} rowSpacing={2}>
                  <Hidden lgDown>
                    <Grid item lg={3}>
                      <FiltersComponent
                        filters={filters}
                        loading={showSkeleton}
                        hotels={initialHotelNames}
                        handleChangeFilters={handleChangeFilters}
                        handleClickFavoriteFilter={handleClickFavoriteFilter}
                      />
                    </Grid>
                  </Hidden>

                  <Grid item xs={12} lg={9}>
                    <ResultsComponent
                      results={getResults()}
                      loading={showSkeleton}
                      activeSort={activeSort}
                      resultsToCompare={resultsToCompare}
                      providers={PROVIDERS}
                      favorites={favorites}
                      showDistance={dataForm?.pointInterest}
                      destination={dataForm?.destination?.label}
                      hashFlow={hashFlow}
                      handleChangeSort={handleChangeSort}
                      handleClickHotel={handleClickHotel}
                      handleClickCompareHotel={handleClickCompareHotel}
                      handleClickFavorite={handleClickFavorite}
                    />
                  </Grid>
                </Grid>

                {resultsToCompare.length > 0 && getComparatorContainer()}

                {showSkeleton && (
                  <Preloader
                    addDots
                    image={`sites/${props.client.client.name}/preloadHotel.gif`}
                    text={t('common.searchingBestPrices')}
                  />
                )}
              </Grid>
            ) : (
              <Grid className='comparation-component'>
                <Button className='button row' onClick={() => setShowComparation(false)}>
                  <ArrowBack />{t('results.hotels.backResults')}
                </Button>

                <Typography className='title'>
                  {t('results.hotels.compareHotelsInDestination', { destination: dataForm?.destination?.label, interpolation: { escapeValue: false } })}
                </Typography>

                <ComparationComponent
                  client={props.client}
                  dataForm={dataForm}
                  resultsToCompare={resultsToCompare}
                  providers={PROVIDERS}
                  hashFlow={hashFlow}
                  hotels={results}
                  handleGoBack={() => setShowComparation(false)}
                  handleClickHotel={handleClickHotel}
                  handleClickAddHotel={hotel => handleClickCompareHotel(hotel, true)}
                  handleClickRemoveHotel={hotel => handleClickCompareHotel(hotel, false)}
                />
              </Grid>
            )}

            {results && (
              <HotelModal
                client={props.client}
                checkin={dataForm?.checkin}
                checkout={dataForm?.checkout}
                isOpen={openModal}
                hotels={results}
                defaultView={defaultView}
                selectedHotel={selectedHotel}
                hashFlow={hashFlow}
                providers={PROVIDERS}
                pointInterest={dataForm?.pointInterest ? dataForm?.destination : null}
                handleClose={handleCloseModal}
                onHotelSelect={hotel => setSelectedHotel(hotel)}
              />
            )}
          </>
        )}

        <Snackbar
          open={openSnackBar}
          autoHideDuration={3000}
          onClose={() => setOpenSnackbar(false)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={() => setOpenSnackbar(false)} severity="success" sx={{ width: '100%' }}>
            {t('results.hotels.addedNewFavorite')}
          </Alert>
        </Snackbar>
      </Container>
    </Grid>
  );
}

const mapStateToProps = reducers => {
  return reducers.gimmonixReducer;
};

const mapDispatchToProps = dispatch => {
  return {
    resetResults: () => dispatch(resetResultsActionGimmonix()),
    searchProducts: (token, queryRule, dataForm, signalAbort) => dispatch(searchActionGimmonix(token, queryRule, dataForm, signalAbort)),
    searchCache: (searchParams) => dispatch(cacheActionGimmonix(searchParams)),
    getFiltersProducts: (query, signalAbort) => dispatch(getFiltersActionGimmonix(query, signalAbort)),
    filterProducts: (query, signalAbort = null) => dispatch(filterActionGimmonix(query, signalAbort)),
    getFavorites: (dataForm) => dispatch(getFavoritesActionGimmonix(dataForm)),
    addFavorite: (data) => dispatch(addFavoriteActionGimmonix(data)),
    deleteFavorite: (user, hotelId) => dispatch(deleteFavoriteActionGimmonix(user, hotelId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResultsView);
