import React, { useEffect, useState } from 'react';
import { Button, TextField, Typography, Grid, InputLabel, Alert, Snackbar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { recoverPassword, removeCookie } from '../../../store/services/Login';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import DialogMessage from '../../common/DialogMessage';
import RawMarkup from '../../common/RawMarkup';
import { useClientData } from '../../../context/ClientContext';

const FIELDS = ['user'];

const RecoverPasswordForm = () => {
  const { t } = useTranslation();
  const { clientData, setClientData } = useClientData();
  const navigate = useNavigate();
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState();
  const [dialogOpen, setDialogOpen] = useState(false);

  useEffect(() => {
    const jwt = localStorage.getItem('jwt');
    if (jwt) {
      setClientData(null);
      localStorage.clear();
    }
    removeCookie();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const errorsAux = {};

    FIELDS.forEach(elem => {
      if (!form[elem]) {
        errorsAux[elem] = true;
      }
    })

    setErrors(errorsAux);

    if (Object.keys(errorsAux).length === 0) {
      setLoading(true);
      recoverPassword(form['user']).then(res => {
        setLoading(false);
        setSnackbarMessage(t('common.emailRecoverPassword'));

        setTimeout(() => {
          navigate('/login')
        }, 2000);
      }).catch(error => {
        setShowError(error?.message || t('common.anErrorOcurred'));
        setLoading(false);
      })
    }
  };

  const modalMessage = `<p style='font-size:18px;'>${t('common.registerMessage')} <strong style='color:#002848;'>mayorista@gotravelres.com</strong><p>`;

  const handleClickRegisterLink = () => {
    const registerLink = clientData?.client?.register?.link;
    if (registerLink) {
      window.location.href = registerLink;
    } else {
      handleOpenDialog(); // Abre el diálogo si no hay link
    }
  };

  return (
    <>
      <Grid className='form'>
        <Typography className='title'>{t('common.recoverPassword')}</Typography>
        <form onSubmit={handleSubmit} noValidate>
          {showError && (
            <Alert severity='error' style={{ marginBottom: 10 }}>{showError}</Alert>
          )}
          <InputLabel>{t('common.user')}</InputLabel>
          <TextField
            fullWidth
            id="user"
            name="user"
            autoComplete="user"
            placeholder={t('common.user')}
            size='small'
            value={form.user || ''}
            error={errors.user}
            disabled={loading}
            onChange={(e) => setForm(current => ({ ...current, user: e.target.value }))}
          />

          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            className='submit-button'
            loading={loading}
            disabled={Boolean(snackbarMessage)}
          >
            {t('common.recover')}
          </LoadingButton>
        </form>

        <Grid className='buttons-bottom row'>
          <Button variant='standard' onClick={() => navigate('/login')}>{t('common.logInTitle')}</Button>
          <Button variant='standard' onClick={handleClickRegisterLink}>{t('common.signIn')}</Button>

          {/* {clientData?.client?.register?.link ?
            <Button variant='standard' onClick={() => navigate('/trenes/register')}>{t('common.signIn')}</Button> :
            <Button variant='standard' onClick={handleOpenDialog}>{t('common.signIn')}</Button>
          } */}
        </Grid>
      </Grid>

      <Snackbar
        open={Boolean(snackbarMessage)}
        autoHideDuration={2000}
        onClose={() => setSnackbarMessage(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackbarMessage(null)} severity="success" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <DialogMessage
        open={dialogOpen}
        onClose={handleCloseDialog}
        title={t('common.registerTitle')}
        message={<RawMarkup content={modalMessage} />}
        showCloseButton
      />
    </>
  );
}

export default RecoverPasswordForm;
