import React, { useEffect, useState } from 'react';
import Footer from '../../layoutCheckout/Footer';
import Header from '../../layoutCheckout/Header';
import { useLocation, useParams } from 'react-router-dom';
import Pending from './thankYouPage/Pending';
import Confirmed from './thankYouPage/Confirmed';
import Rejected from './thankYouPage/Rejected';
import Error from './thankYouPage/Error';

const PENDING = 'Pending';
const REJECTED = 'Rejected';
const CONFIRMED = 'Confirmed';
const ERROR = 'Error';

const ThankYouPage = (props) => {
  const params = useParams();
  const location = useLocation();
  const [user, setUser] = useState();
  const [purchaseDetail, setPurchaseDetail] = useState();
  const [email, setEmail] = useState();
  const [view, setView] = useState();

  useEffect(() => {
    const user = localStorage.getItem('user');
    setUser(user ? JSON.parse(user) : null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location.state) {
      setPurchaseDetail(location.state.purchaseDetail);
    }

    if (location.pathname.includes('error')) {
      setView(ERROR);
    } else if (location?.state?.status === 'REJECTED' || location?.state?.status === 'FAILED') {
      setView(REJECTED);
    } else if (params.module !== 'hotels' && !location?.state?.pendingPayment) {
      setView(CONFIRMED);
    } else {
      setView(PENDING);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  const handleChangeRetrieve = (retrieve) => {
    if (retrieve) {
      setPurchaseDetail(retrieve?.response?.purchaseDetail);

      if (view === PENDING && retrieve?.status !== PENDING) {
        if (retrieve?.response?.step?.nameContainer.includes(REJECTED)) {
          setView(REJECTED);
        } else {
          setEmail(retrieve?.response?.step?.data?.email);
          setView(CONFIRMED);
        }
      }
    }
  }

  return (
    <section className='checkout'>
      <Header
        client={props.client}
        user={user}
        isIframe={props.isIframe}
        reservations={props.reservations}
        selectedReservation={props.selectedReservation}
        setReservations={props.setReservations}
        handleSelectReservation={props.handleSelectReservation}
      />

      <div className='thankyoupage'>
        {view === PENDING ? (
          <Pending
            client={props.client}
            email={email}
            products={location.state?.products}
            paymentData={location.state?.paymentData}
            purchaseDetail={purchaseDetail}
            reservations={props.reservations}
            handleChangeRetrieve={handleChangeRetrieve}
            handleSelectReservation={props.handleSelectReservation}
          />
        ) : view === CONFIRMED ? (
          <Confirmed
            client={props.client}
            email={email}
            products={location.state?.products}
            purchaseDetail={purchaseDetail}
            paymentData={location.state?.paymentData}
            bookingStatus={location.state?.bookingStatus || {}}
            reservations={props.reservations}
            handleSelectReservation={props.handleSelectReservation}
          />
        ) : view === REJECTED ? (
          <Rejected
            client={props.client}
            email={email}
            products={location.state?.products}
            paymentData={location.state?.paymentData}
            bookingDuplicated={location?.state?.bookingDuplicated}
            bookingDetail={location?.state?.bookingDetail}
            purchaseDetail={purchaseDetail}
            reservations={props.reservations}
            handleSelectReservation={props.handleSelectReservation}
          />
        ) : view === ERROR ? (
          <Error
            reservations={props.reservations}
            handleSelectReservation={props.handleSelectReservation}
          />
        ) : null}

      </div>

      <Footer client={props.client} />
    </section>
  );
}

export default ThankYouPage;
