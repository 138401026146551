import React, { useState } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, Fab, Grid, Rating, Typography } from '@mui/material';
import MapView from './MapView';
import DetailsView from './DetailsView';
import RoomView from './RoomView';
import { AttachMoney, CalendarToday, Close, GpsFixed, LocationOn, RoomService } from '@mui/icons-material';
import { useEffect } from 'react';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { availabilityActionGimmonix, availabilityDetailOnlyActionGimmonix, cancelationPolicyActionGimmonix, moreAvailabilityActionGimmonix } from '../../../../store/actions';
import { useNavigate } from 'react-router-dom';
import Preloader from '../../../common/Preloader';
import { useTranslation } from 'react-i18next';

function HotelModal({
    isOpen,
    hotels,
    defaultView,
    selectedHotel,
    checkin,
    checkout,
    hashFlow,
    resultAvailability,
    resultAvailabilityDetailOnly,
    resultCancelationPolicy,
    resultMoreAvailability,
    errorAvailability,
    errorAvailabilityOnlyDetail,
    providers,
    isFavoritePage,
    pointInterest,
    ...props
}) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [open, setOpen] = useState(isOpen);
    const [currentView, setCurrentView] = useState(defaultView || 'map');
    const [availability, setAvailability] = useState();
    const [detailOnly, setDetailOnly] = useState(false);
    const [countPackages, setCountPackages] = useState(10);
    const [loadingAvailability, setLoadingAvailability] = useState(true);
    const [loadingAvailabilityDetailOnly, setLoadingAvailabilityDetailOnly] = useState(true);
    const [loadingCancelationPolicy, setLoadingCancelationPolicy] = useState(false);
    const [loadingMoreAvailability, setLoadingMoreAvailability] = useState(false);
    const [showMoreButton, setShowMoreButton] = useState(true);
    const [filters, setFilters] = useState({});
    const [abortController, setAbortController] = useState();
    const [repeatDetailOnly, setRepeatDetailOnly] = useState(false);
    const [showAllRooms, setShowAllRooms] = useState(false);

    useEffect(() => {
        setOpen(isOpen);

        if (isOpen) {
            setCurrentView(defaultView || 'map');
            setShowAllRooms(false);
        } else {
            setCurrentView('map');
            setDetailOnly(false);
            setCountPackages(10);
            setAvailability();
            setFilters({});
            setLoadingCancelationPolicy(false);
            setRepeatDetailOnly(false);
            abortController && abortController.abort();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, defaultView]);

    useEffect(() => {
        if (selectedHotel && currentView !== 'map' && availability?.details?.id !== selectedHotel?.id) {
            loadAvailabilities();
            setShowAllRooms(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedHotel, hashFlow, currentView]);

    useEffect(() => {
        if (resultAvailability.statusCode === 200) {
            setAvailability(resultAvailability.data);
            setLoadingAvailability(false);
            setShowMoreButton(resultAvailability.remaning_book_codes);
            setFilters(transformFilters(resultAvailability.filters));
            setDetailOnly(false);
        }
    }, [resultAvailability]);

    useEffect(() => {
        if (errorAvailability) {
            if (isOpen && repeatDetailOnly) {
                setTimeout(() => {
                    loadAvailabilities(true);
                    setRepeatDetailOnly(false);
                }, 5000)
            } else {
                if (isOpen && availability && Object.keys(availability).length > 0 && !Boolean(loadingCancelationPolicy)) {
                    const packages = availability?.packages?.slice(0, countPackages) || [];
                    const bookCode = packages.find(elem => !Boolean(elem.cancelation_policy))?.bookCode;
                    if (bookCode) {
                        getCancelationPolicy(bookCode);
                    }
                }
                setLoadingAvailability(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorAvailability, availability]);

    useEffect(() => {
        if ((errorAvailabilityOnlyDetail && repeatDetailOnly) || !errorAvailabilityOnlyDetail) {
            setRepeatDetailOnly(false);
        } else {
            setRepeatDetailOnly(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorAvailabilityOnlyDetail]);

    useEffect(() => {
        if (resultAvailabilityDetailOnly.statusCode === 200) {
            if (loadingAvailability || !Boolean(availability) || Object.keys(availability).length === 0) {
                setAvailability(resultAvailabilityDetailOnly.data);
                setDetailOnly(true);
                setShowMoreButton(resultAvailabilityDetailOnly.data.packages.length > 10);
                setFilters(transformFilters(resultAvailabilityDetailOnly.filters));

                if (!loadingAvailability) {
                    getCancelationPolicy(resultAvailabilityDetailOnly.data.packages[0]?.bookCode);
                }
            }
            setLoadingAvailabilityDetailOnly(false);
        } else {
            setAvailability({});
            setShowMoreButton(false);
            setLoadingAvailabilityDetailOnly(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultAvailabilityDetailOnly]);

    useEffect(() => {
        if (resultMoreAvailability?.cxl?.statusCode === 200) {
            setLoadingMoreAvailability(false);
            const availabilityAux = {...availability};
            let firstIndex = Infinity;
            let bookCode = '';
            resultMoreAvailability?.cxl?.data?.CancellationPolicies?.forEach(policy => {
                const index = availabilityAux?.packages?.findIndex(elem => elem.bookCode === policy.bookCode);
                if (index > -1) {
                    availabilityAux.packages[index].cancelation_policy = policy;
                    availabilityAux.packages[index].refundable = policy.refundable;
                    if (index < firstIndex) {
                        firstIndex = index;
                        bookCode = policy.bookCode;
                    }
                }
            });
            setAvailability(availabilityAux);
            setShowMoreButton(resultMoreAvailability.remaning_book_codes);
            setFilters(transformFilters(resultMoreAvailability.filters));

            if (bookCode) {
                setTimeout(() => {
                    document.getElementById(`package-${bookCode}`)
                        ?.scrollIntoView({ behavior: 'smooth' });
                }, 500);
            }
        } else if (resultMoreAvailability?.error) {
            setLoadingMoreAvailability(false);
            setShowMoreButton(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultMoreAvailability]);

    useEffect(() => {
        if (isOpen && (detailOnly || showAllRooms)) {
            let availabilityAux = {...availability};
            const startIndex = availabilityAux.packages.findIndex(elem => elem.bookCode === loadingCancelationPolicy) + 1;

            if (resultCancelationPolicy?.statusCode === 200) {
                resultCancelationPolicy?.data?.CancellationPolicies?.forEach(policy => {
                    const index = availabilityAux?.packages?.findIndex(elem => elem.bookCode === policy.bookCode);
                    if (index > -1) {
                        availabilityAux.packages[index].cancelation_policy = policy;
                        availabilityAux.packages[index].refundable = policy.refundable;
                    }
                });
            } else if (startIndex > 0 && availabilityAux?.packages?.[startIndex - 1]) {
                availabilityAux.packages[startIndex - 1].error_policy = true;
            }

            setAvailability(availabilityAux);

            const packages = availabilityAux.packages.slice(startIndex, countPackages);
            const bookCode = packages.find(elem => !Boolean(elem.cancelation_policy) && !elem.error_policy)?.bookCode;
            if (!showAllRooms && bookCode) {
                getCancelationPolicy(bookCode);
            } else {
                setLoadingCancelationPolicy(null);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultCancelationPolicy]);

    const loadAvailabilities = (detailOnly = false) => {
        const hotelProduct = JSON.parse(localStorage.getItem('products') || '[]')
            ?.find(elem => elem.ref_table_detail === 'hotel_detail');
        const token = hotelProduct?.parameters?.['API-Token'];
        if (token) {
            setLoadingAvailability(true);
            setLoadingAvailabilityDetailOnly(true);

            if (hashFlow) {
                const daysToCancel = hotelProduct?.parameters?.days_to_cancel || 0;
                const data = {
                    hashFlow,
                    hotelId: selectedHotel?.id,
                    token,
                    daysToCancel,
                    nrfBook: true,
                    detailOnly: true
                }
                setLoadingMoreAvailability(false);
                setFilters({});

                abortController && abortController.abort();
                const controller = new AbortController();
                setAbortController(controller);
                props.getAvailabilityDetailOnly(data, controller.signal);

                if (!detailOnly) {
                    setTimeout(() => {
                        delete data.detailOnly;
                        props.getAvailability(data, controller.signal);
                    }, 500);
                }
            }
        } else {
            navigate('/login', { state: { expiredSession: true } });
        }
    }

    const transformFilters = (filtersAux) => {
        const transformedFilters = {};
        filtersAux.forEach(item => {
            const key = Object.keys(item)[0];
            const value = item[key];
            transformedFilters[key] = Object.entries(value).map(([keyValue, total]) => ({ value: keyValue, total }));
        });
        return transformedFilters;
    }

    const changeView = (view) => {
        setCurrentView(view);
    };

    const handleClickShowMoreOptions = () => {
        if (selectedHotel && hashFlow) {
            const hotelProduct = JSON.parse(localStorage.getItem('products') || '[]')
                ?.find(elem => elem.ref_table_detail === 'hotel_detail');
            const token = hotelProduct?.parameters?.['API-Token'];
            if (token) {
                if (detailOnly) {
                    const count = countPackages + 10;
                    setCountPackages(count);
                    setShowMoreButton(availability.packages.length > count);
                    setFilters(current => ({ ...current }));

                    const maxLength = availability.packages.length < countPackages ? availability.packages.length : countPackages;
                    const bookCode = availability.packages?.[maxLength]?.bookCode;
                    if (bookCode) {
                        setTimeout(() => {
                            document.getElementById(`package-${bookCode}`)
                                ?.scrollIntoView({ behavior: 'smooth' });
                        }, 500);

                        if (!loadingCancelationPolicy && bookCode) {
                            getCancelationPolicy(bookCode);
                        }
                    }
                } else {
                    const daysToCancel = hotelProduct?.parameters?.days_to_cancel || 0;
                    const data = {
                        hashFlow: hashFlow,
                        hotelId: selectedHotel?.id,
                        token,
                        daysToCancel,
                        nrfBook: true
                    }
                    setLoadingMoreAvailability(true);
                    props.getMoreAvailabilityAction(data);
                }
            } else {
                navigate('/login', { state: { expiredSession: true } });
            }
        }
    }

    const getCancelationPolicy = (bookCode) => {
        if (bookCode) {
            const hotelProduct = JSON.parse(localStorage.getItem('products') || '[]')
                ?.find(elem => elem.ref_table_detail === 'hotel_detail');
            const token = hotelProduct?.parameters?.['API-Token'];
            if (token) {
                const daysToCancel = hotelProduct?.parameters?.days_to_cancel || 0;
                const data = {
                    hashFlow,
                    token,
                    daysToCancel,
                    bookCode: [bookCode],
                };
                props.getCancelationPolicyAction(data);

                if (loadingCancelationPolicy !== bookCode) {
                   setLoadingCancelationPolicy(bookCode);
                }
            } else {
                navigate('/login', { state: { expiredSession: true } });
            }
        }
    }

    const getLoader = () => {
        let showLoader = false;

        switch (currentView) {
            case 'room':
                showLoader = loadingAvailability || loadingAvailabilityDetailOnly;
                break;
            default:
                showLoader = false;
                break;
        }

        return showLoader && (
            <Preloader
                addDots
                image={`sites/${props.client.client.name}/preloadHotel.gif`}
                text={t('common.processingRequest')}
            />
        )
    }

    return (
        <Dialog open={open} onClose={props.handleClose} fullWidth={true} maxWidth="xl" className='hotel-modal'>
            <Fab size="small" onClick={props.handleClose} className='close-button'>
                <Close fontSize='small' />
            </Fab>

            {currentView !== 'details' && (
                <DialogTitle className='row' component='div'>
                    <Grid container spacing={2} columns={24}>
                        <Grid item xs={24} md={isFavoritePage ? 17 : 14} lg={isFavoritePage ? 19 : 16} className='column hotel-info'>
                            {Boolean(selectedHotel) && (
                                <>
                                    <Grid className='row'>
                                        <Typography className='title'>{selectedHotel?.name}</Typography>
                                        <Rating value={parseInt(selectedHotel?.stars || 0)} readOnly />
                                    </Grid>
                                    <Grid className='row bottom-info'>
                                        <Grid className='row address'>
                                            <LocationOn fontSize='small' /><span>{selectedHotel?.address}</span>
                                        </Grid>
                                        {!isFavoritePage && (
                                            <Grid className='row date'>
                                                <CalendarToday fontSize='small' />
                                                <span>
                                                    {dayjs(checkin, 'DD/MM/YYYY').format('DD MMM YY')} - {dayjs(checkout, 'DD/MM/YYYY').format('DD MMM YY')}
                                                </span>
                                            </Grid>
                                        )}
                                    </Grid>
                                </>
                            )}
                        </Grid>

                        <Grid item xs={24} md={isFavoritePage ? 7 : 10} lg={isFavoritePage ? 5 : 8} className='buttons-container'>
                            {currentView !== 'map' && (
                                <Button variant='contained' color='primary' disabled={!Boolean(selectedHotel)} onClick={() => setCurrentView('map')}>
                                    <GpsFixed fontSize='small' />{t('results.hotels.seeMap')}
                                </Button>
                            )}
                            {currentView !== 'details' && (
                                <Button variant='contained' color='primary' disabled={!Boolean(selectedHotel)} onClick={() => setCurrentView('details')}>
                                    <RoomService fontSize='small' />{t('results.hotels.seeHotelDetails')}
                                </Button>
                            )}
                            {!isFavoritePage && currentView !== 'room' && (
                                <Button variant='contained' color='primary' disabled={!Boolean(selectedHotel)} onClick={() => setCurrentView('room')}>
                                    <AttachMoney fontSize='small' />{t('results.hotels.seeRates')}
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </DialogTitle>
            )}

            <DialogContent id='hotel-modal-content'>
                {currentView === 'map' && (
                    <MapView
                        client={props.client}
                        isOpen={isOpen}
                        hotels={hotels}
                        selectedHotel={selectedHotel}
                        isFavoritePage={isFavoritePage}
                        pointInterest={pointInterest}
                        changeView={changeView}
                        handleClose={props.handleClose}
                        onHotelSelect={props.onHotelSelect}
                    />
                )}

                {currentView === 'room' && (
                    <RoomView
                        client={props.client}
                        selectedHotel={selectedHotel}
                        availability={availability}
                        loadingAvailability={loadingAvailability || loadingAvailabilityDetailOnly}
                        loadingCancelationPolicy={loadingCancelationPolicy}
                        loadingMoreAvailability={loadingMoreAvailability}
                        providers={providers}
                        hashFlow={hashFlow}
                        showMoreButton={showMoreButton}
                        filters={filters}
                        detailOnly={detailOnly}
                        countPackages={countPackages}
                        showAllRooms={showAllRooms}
                        handleClickShowMoreOptions={handleClickShowMoreOptions}
                        handleClickShowAllRooms={() => setShowAllRooms(true)}
                        getCancelationPolicy={getCancelationPolicy}
                    />
                )}

                {currentView === 'details' && (
                    <DetailsView
                        selectedHotel={selectedHotel}
                        checkin={checkin}
                        checkout={checkout}
                        isFavoritePage={isFavoritePage}
                        availability={availability}
                        loadingAvailability={loadingAvailabilityDetailOnly}
                        changeView={changeView}
                    />
                )}

                {getLoader()}
            </DialogContent>
        </Dialog>
    );
}

const mapStateToProps = reducers => {
    return reducers.gimmonixReducer;
};

const mapDispatchToProps = dispatch => {
    return {
        getAvailability: (data, signalAbort) => dispatch(availabilityActionGimmonix(data, signalAbort)),
        getAvailabilityDetailOnly: (data, signalAbort) => dispatch(availabilityDetailOnlyActionGimmonix(data, signalAbort)),
        getCancelationPolicyAction: (data) => dispatch(cancelationPolicyActionGimmonix(data)),
        getMoreAvailabilityAction: (data) => dispatch(moreAvailabilityActionGimmonix(data))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(HotelModal);
