import axios from "axios";
import { getCredentials } from '../sagas/util';

export const getAutocompleteGimmonix = async (query, signalAbort) => {
    const url = 'https://api-autocomplete.tije.travel/autocomplete/search?_format=json&flow_code=hotel_gimmonix&product=hotel_gimmonix&locale=es_AR&query=' + query;
    const options = {};
    if (signalAbort) {
        options.signal = signalAbort;
    }

    const response = await axios.get(url, options)
        .then(res => {
            return res.data;
        })
        .catch((err) => {
            return false;
        });

    return response;
}

export const getAutocompleteTrain = async (query, locale, signalAbort) => {
    const url = `${getCredentials().urlTrains}autocomplete?query=` + encodeURIComponent(query) + `&locale=${locale}`;
    const requestOptions = {};
    if (signalAbort) {
        requestOptions.signal = signalAbort;
    }

    const response = await axios.get(url, requestOptions)
        .then(res => {
            return res.data;
        })
        .catch((err) => {
            return false;
        });

    return response;
}

export const getDestinationPassesTrain = async (token) => {
    const url = `${getCredentials().urlTrains}autocomplete_passes`;
    const requestOptions = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      };

    const response = await axios.get(url, requestOptions)
        .then(res => {
            return res.data;
        })
        .catch((err) => {
            return false;
        });

    return response;
}

export const getAutocompleteFlights = async (query, signalAbort) => {
    const url = 'https://api-autocomplete.tije.travel/autocomplete/search?_format=json&flow_code=flight&product=flight&locale=es_AR&query=' + query;
    const options = {};
    if (signalAbort) {
        options.signal = signalAbort;
    }

    const response = await axios.get(url, options)
        .then(res => {
            return res.data;
        })
        .catch((err) => {
            return false;
        });

    return response;
}

export const getAutocompleteAirlines = async (query, signalAbort) => {
    const url = 'https://api-autocomplete.tije.travel/autocomplete/search?_format=json&flow_code=airline&product=flight&locale=es_AR&query=' + query;
    const options = {};
    if (signalAbort) {
        options.signal = signalAbort;
    }

    const response = await axios.get(url, options)
        .then(res => {
            return res.data;
        })
        .catch((err) => {
            return false;
        });

    return response;
}
