const es = {
  translation: {
    common: {
      noOptions: 'No hay opciones',
      loading: 'Cargando',
      continue: 'Continuar',
      back: 'Volver',
      cancel: 'Cancelar',
      modifyAndAdd: 'Modificar y agregar',
      modify: 'Modificar',
      reserve: 'Reservar',
      search: 'Buscar',
      searchingBestPrices: 'Estamos buscando los mejores precios',
      processingRequest: 'Estamos procesando la solicitud',
      processingBooking: 'Estamos procesando la reserva',
      hello: 'Hola',
      myBookings: 'Mis reservas',
      seeMoreBookings: 'Ver más reservas',
      logIn: 'Ingresar',
      logOut: 'Cerrar sesión',
      favorites: 'Favoritos',
      logInTitle: 'Inicia Sesión',
      user: 'Usuario',
      password: 'Contraseña',
      logInButton: 'Iniciar Sesión',
      recoverPassword: 'Recuperar contraseña',
      signIn: 'Registrarse',
      suscribe:'Suscribite y recibí nuestras ofertas',
      enterEmail: 'Ingresá tu mail',
      showMore: 'Ver más',
      recover: 'Recuperar',
      registerTitle: 'Registro de Usuario',
      registerMessage: 'Solicitá tu alta escribiéndonos a',
      emailRecoverPassword: 'Se te ha enviado un mail para recuperar la contraseña.',
      anErrorOcurred: 'Ha ocurrido un error.',
      close: 'Cerrar',
      guard: 'Guardia',
      sales: 'Ventas',
      reservation: 'Reserva',
      open: 'Abierta',
      products: 'Productos',
      passenger: 'Pasajero',
      date: 'Fecha',
      inProgress: 'En curso',
      continueBooking: 'Seguir reservando',
      exchangeTypeTtle: 'Tipo de cambio',
      exchangeTypeDescription: 'El tipo de cambio se define a las 11:00 hs, previo a este horario el pago quedara sujeto a ajustes.',
      availableCredit: 'Crédito disponible',
      expiredSession: 'Tu sesión ha expirado, por favor vuelve a iniciar sesión.',
      consult: 'Consultar',
      modules: {
        hotels: 'hoteles',
        trains: 'trenes',
        flights: 'vuelos'
      }
    },
    register: {
      title: 'Registro',
      lastNameFirstNameUser: 'Apellido y nombre del solicitante',
      lastNameFirstName: 'Apellido y nombre',
      agencyName: 'Nombre de agencia',
      businessName: 'Razón social',
      phoneNumber: 'Número de teléfono',
      country: 'País',
      selectCountry: 'Seleccione un país',
      city: 'Ciudad',
      selectCity: 'Seleccione una ciudad',
      taxSituation: 'Situación fiscal',
      taxId: 'Identificación fiscal',
      enablingFileNumber: 'Número de legajo habilitante',
      onlyArgentines: 'Solo para agencias argentinas',
      email: 'Email',
      address: 'Dirección',
      create: 'Crear',
      enterValue: 'Introduce un valor',
      invalidForm: 'El formulario no es válido, por favor corregir posibles errores',
      onlyLetters: 'Ingrese solo letras',
      created: 'Creado',
      requestSent: 'Su solicitud fue enviada correctamente',
      goLogin: 'Ir al login'
    },
    multiSearch: {
      hotels: {
        destination: 'Destino',
        checkIn: 'Entrada',
        checkOut: 'Salida',
        nights: 'Noches',
        guests: 'Huéspedes',
        searchByInterestPoint: 'Buscar por punto de interés',
        adults: 'Adultos',
        minors: 'Menores',
        roomsShort: 'Hab.',
        rooms: 'Habitaciones',
        room: 'Habitación',
        ages: 'Edades',
        agesTooltip: 'Edad de los menores en la fecha de llegada',
        apply: 'Aplicar',
        selectDestination: 'Ingrese destino',
        cities: 'Ciudades'
      },
      trains: {
        tickets: 'Tickets',
        passes: 'Pases',
        direct: 'Directo',
        roundTrip: 'Ida y vuelta',
        origin: 'Origen',
        selectCity: 'Ingrese una ciudad',
        destination: 'Destino',
        selectDestination: 'Ingrese un destino',
        departure: 'Salida',
        return: 'Regreso',
        hour: 'Hora',
        firstDayOfValidity: 'Primer día de validez',
        passengers: 'Pasajeros',
        adult: 'Adulto',
        adults: 'Adultos',
        young: 'Joven',
        youngs: 'Jóvenes',
        minorYoung: 'Menor/Joven',
        seniors: 'Seniors',
        senior: 'Senior',
        addSegment: 'Añadir tramo',
        fromYears: 'Desde {{years}} años',
        toYears: 'Hasta {{years}} años',
        fromToYears: '{{from}} - {{to}} años',
        maxPassengers: 'Máx. {{passengers}} pasajeros',
        atLeastOnePassenger: 'Debe seleccionar al menos un pasajero',
        youngAge: 'Edad Joven',
        years: 'Años',
        SwitzerlandMinors: 'Menores de 15 años inclusive, viajando con un padre o tutor legal, pueden viajar gratis con el Swiss Family Card.',
        city: 'Ciudad',
        station: 'Estación'
      },
      flights: {
        roundtrip: 'Ida y vuelta',
        oneWay: 'Sólo Ida',
        multidestination: 'Multidestinos',
        threeDays: 'Tarifas +/- 3 días',
        selectOrigin: 'Ingrese el origen',
        selectDestination: 'Ingrese el destino',
        cities: 'Ciudades',
        airports: 'Aeropuertos',
        origin: 'Origen',
        destination: 'Destino',
        departure: 'Salida',
        return: 'Regreso',
        passengers: 'Pasajeros',
        adults: 'Adultos',
        adult: 'Adulto',
        fromYears: '+ {{from}} años',
        kids: 'Niños',
        kid: 'Niño',
        fromToYears: '{{from}} a {{to}} años',
        babys: 'Infantes',
        baby: 'Infante',
        fromToMonths: '{{from}} a {{to}} meses',
        minorYears: 'Menores de {{years}} años',
        minorAges: 'Las edades de los menores deben ser las vigentes al momento de finalizar el viaje.',
        addSegment: 'Agregar tramo',
        currency: 'Moneda',
        apply: 'Aplicar',
        advancedSearch: 'Búsqueda avanzada',
        airline: 'Aerolínea',
        selectAirline: 'Ingrese una aerolínea',
        scaleType: 'Escalas',
        luggage: 'Equipaje',
        class: 'Clase',
        all: 'Todas',
        economy: 'Economy',
        premium: 'Premium',
        business: 'Business',
        firstClass: 'Primera Clase',
        direct: 'Directo',
        oneScale: '1 escala',
        twoScales: '2 escalas',
        threeScales: '3 o más',
        included: 'Incluido',
        notIncluded: 'No incluido',
        section: 'Tramo'
      }
    },
    results: {
      hotels: {
        noHotels: 'No hay hoteles disponibles en {{destination}}',
        otherSearch: 'Podés realizar otra búsqueda',
        communicateAdvisor: 'Para agilizar la gestión de tu consulta, por favor comunicate con tu asesor de ventas.',
        availableHotels: '{{hotels}} hoteles disponible en {{destination}}',
        fromPrice: 'desde USD {{price}}',
        backResults: 'Volver a resultados',
        compareHotelsInDestination: 'Comparar hoteles en {{destination}}',
        addedNewFavorite: 'Nuevo favorito agregado',
        sortBy: 'Ordenar por',
        recommended: 'Recomendados',
        price: 'Precio',
        stars: 'Estrellas',
        distance: 'Distancia',
        seeHotelsInMap: 'Ver hoteles en el mapa',
        hotelRecommended: '¡Alojamiento Recomendado!',
        seeHotelDetails: 'Ver detalles del hotel',
        seeHotelInMap: 'Ver en el mapa',
        withCancellationFees: 'Con gastos de cancelación',
        noCancellationFees: 'Sin gastos de cancelación',
        totalPriceFrom: 'Total estadía desde',
        pricePerNight: 'promedio por noche',
        moreRates: 'Más tarifas',
        compare: 'Comparar',
        onlyRoom: 'Solo Habitación',
        breakfast: 'Desayuno',
        allInclusive: 'Todo Incluido',
        halfPension: 'Media Pensión',
        distanceFromReference: 'a {{distance}} de {{destination}}',
        seeMap: 'Ver mapa',
        seeRates: 'Ver tarifas',
        amenities: 'Servicios',
        ratesAndRoomType: 'Tarifas y tipo de habitación',
        noRooms: 'No hay habitaciones disponibles para este hotel.',
        apartmentInfo: 'IMPORTANTE: Este tipo de alojamiento requiere que se contacte al establecimiento 72 horas antes del check in para coordinar la recepción del mismo.',
        bedType: 'Tipo de cama',
        room: 'habitación',
        refundable: 'Reembolsable',
        nonRefundable: 'No Reembolsable',
        taxesAndFeesIncluded: 'Impuestos y tasas incluidas',
        seeMoreOptions: 'Ver más opciones',
        seeHotelComments: 'Ver comentarios del hotel',
        hotelComments: 'Comentarios del hotel',
        freeCancellationBefore: 'Cancelación gratuita antes del {{date}}.',
        cancellationAfter: 'Cancelación a partir del día {{date}} se le cobrará {{currency}} {{price}}.',
        userWithourPermission: 'USUARIO SIN PERMISO PARA RESERVAR EN GASTOS. Contacta a tu asesor de ventas para poder concretar la reserva.',
        noAllowChangesOrCancellation: 'No permite cambios o cancelaciones.',
        notAvailable: 'No disponible',
        favoriteHotels: 'Hoteles favoritos',
        selectDates: 'Elegir fechas',
        download: 'Descargar',
        print: 'Imprimir',
        modifyCriteria: 'Modificar criterios',
        addRecommendedHotels: 'Añadir hoteles recomendados',
        addHotel: 'Agregar hotel',
        addHotelFromResults: 'Agregar hoteles de los resultados',
        compareHotels: 'Comparar hoteles',
        clearSelection: 'Limpiar selección',
        amenitiesList: {
          wifi: 'WIFI',
          parking: 'Estacionamiento',
          pool: 'Piscina',
          pets: 'Mascotas Permitidas',
          AC: 'Aire Acondicionado',
          transfer: 'Servicio de Traslado',
          gym: 'Gimnasio',
          spa: 'Spa',
          beach: 'Cercano a la Playa',
          wheelchair: 'Acceso con Silla de Ruedas',
          kitchen: 'Cocina',
          '24Hours': 'Recepción 24 horas',
          laundry: 'Servicio de Lavanderia',
          playroom: 'Salón de Juegos',
          nonSmokerRoom: 'Habitaciones No Fumador',
          map: 'Ubicación en mapa',
          distances: 'Distancias (centro, aeropuerto, principales)'
        },
        selectAmenitites: 'Selecciona tus criterios de comparativa',
        selectAll: 'Seleccionar todos',
        defaultAmenities: 'Restaurar criterios por defecto',
        updateComparation: 'Actualizar comparativa',
        hotelNotFound: 'Hotel no encontrado',
        consultCancelationPolicy: 'Consultar política de cancelación',
        showAllRooms: 'Ver todas las habitaciones'
      },
      trains: {
        noTrains: 'No hay trenes disponibles',
        otherSearch: 'Podés realizar otra búsqueda',
        segment: 'Tramo',
        selectSegment: 'Seleccionar tramo',
        filter: 'Filtro',
        directs: 'Directos',
        earlier: 'Más temprano',
        schedules: 'Horarios',
        later: 'Más tarde',
        noDirectTrains: 'No se encontraron trenes directos para este tramo',
        change: 'Cambio',
        changes: 'Cambios',
        train: 'Tren',
        mostEconomical: '¡Viaje más económico!',
        recommendedTrip: '¡Viaje recomendado! Mejor relación precio y duración',
        noTicketsSelected: 'No hay tickets seleccionados',
        selectTicketToContinue: 'Selecciona tu ticket para continuar',
        totalTickets: 'Total Boletos',
        notIncludeExpenses: 'No incluye gastos',
        adults: 'Adultos',
        youngs: 'Jóvenes',
        seniors: 'Seniors',
        transferTime: 'Tiempo de trasbordo',
        flexibilityAndConditions: 'Flexibilidad y condiciones de la tarifa',
        seeConditions: 'Ver condiciones',
        totalSegment: 'Total tramo',
        conditions: 'Condiciones de la tarifa',
        totalByPassengers: 'Valor por total de pasajeros',
        tripSummary: 'Resumen del viaje',
        passe: 'Pase',
        duration: 'Duración',
        seeCompleteConditions: 'Ver condiciones completas',
        numberDays: 'Cantidad de días',
        totalPasses: 'Total Pases',
        noPasseSelected: 'No hay pase seleccionado',
        selectPasseToContinue: 'Selecciona una tarifa para continuar',
        from: 'Desde',
        to: 'Hasta',
        destination: 'Destino',
        type: 'Tipo',
        class: 'Clase',
        validFrom: 'Válido desde',
        validTo: 'Válido hasta',
        termsAndConditions: 'Términos y condiciones',
        flexibleTypes: {
          bestPrice: 'Mejor precio',
          noFlexible: 'No flexible',
          semiFlexible: 'Semi-flexible',
          fullFlexible: 'Flexible'
        },
        travelFreeMinorsTickets: '<b>Infoas:</b> Viajan gratis <b>sin derecho a asiento y sin ticket</b> según edades: Trenes Reino Unido 0-4 años, Alemania y Suiza de 0-5 años, resto de Trenes 0-3 años inclusive.',
        travelFreeMinorsPasses: '<b>Infoas:</b> Viajan gratis <b>sin derecho a asiento y sin ticket</b> según edades: Pase Eurail 0-3 años y Pase Suizo de 0-5 años inclusive.',
        noResultsSegment: 'No se encontraron resultados para este tramo',
        errorAvailability: 'Error en disponiblidad',
        noAvailabilityTrain: 'No se ha logrado confirmar disponibilidad para el siguiente tren.',
        noAvailabilityTrains: 'No se ha logrado confirmar disponibilidad para los siguientes trenes.',
        continueOthersTrains: '¿Desea continuar la reserva con el resto de los tramos seleccionados, o elegir una nueva alternativa?',
        modifySelectionTrain: '¿Desea elegir una nueva alternativa?',
        modifySelection: 'Modificar selección',
        continue: 'Continuar',
        luggageConditions: {
          italoStandard: 'Las tarifas no permiten equipaje de más de 75x53x30 cm por pasajero.',
          brightlineStandard: 'Las tarifas <b>Smart</b> permiten hasta 2 maletas chicas (71x56x35cm.), de no más de 22 kg cada una, por pasajero.',
          brightlinePrimera: 'Las tarifas <b>Premium</b> permiten 1 maleta chica (71x56x35cm.), de no más de 22 kg. + 1 maleta de hasta 178 cm lineales y 32 kg. por pasajero.'
        },
        foodConditions: {
          italoPrimera: 'Las tarifas incluyen snacks y bebida a bordo.'
        },
        variableSeat: 'La inclusión de asientos es variable en cada tramo.',
        seatReservationIncluded: 'Reserva de asiento incluida.',
        ticketMail:  'La compañía de tren enviará de forma directa el ticket electrónico al mail de contacto informado para su impresión y presentación al momento de abordar el tren.',
        noPrintTicket: 'No es necesario imprimir el ticket. Sólo se deberá presentar una identificación con foto válida y la referencia del PNR informado en el mail de emisión para abordar el tren.',
        downloadTicket: 'El ticket electrónico será enviado para su descarga, impresión y presentación al momento de abordar el tren.',
        fareClasses: {
          standard: 'STANDARD',
          primera: 'PRIMERA'
        }
      },
      flights: {
        noFlights: 'No hay vuelos disponibles',
        otherSearch: 'Podés realizar otra búsqueda',
        flightRecommended: '¡Vuelo Recomendado!',
        availableFlights: '{{flights}} vuelos disponibles a {{destination}}',
        fromPrice: 'desde {{currency}} {{price}}',
        backResults: 'Volver a resultados',
        compareFlightsInDestination: 'Comparar vuelos en {{destination}}',
        sortBy: 'Ordenar por',
        recommended: 'Recomendado',
        price: 'Precio',
        duration: 'Duración',
        stopover: 'Escalas',
        departure: 'Ida',
        return: 'Vuelta',
        flight: 'Vuelo',
        economy: 'Economy',
        premium: 'Premium',
        business: 'Business',
        firstClass: 'Primera Clase',
        scales: 'Escalas',
        scale: 'Escala',
        direct: 'Directo',
        oneScale: '1 escala',
        twoScales: '2 escalas',
        threeScales: '3 o más escalas',
        scaleWait: 'Espera en {{city}} - {{hours}}h {{minutes}}m',
        scaleIn: 'Escala en {{airport}}',
        compare: 'Comparar',
        compareFlights: 'Comparar vuelos',
        clearSelection: 'Limpiar selección',
        publicFare: 'Tarifa pública',
        privateFare: 'Tarifa privada',
        priceByAdult: 'Precio por adulto',
        priceByKid: 'Precio por niño',
        priceByBaby: 'Precio por infante',
        by: 'Por',
        taxes: 'Impuestos',
        taxesAbb: 'Imp',
        adult: 'Adulto',
        kid: 'Niño',
        baby: 'Infante',
        total: 'Total',
        dayAfter: 'Llega 1 después',
        daysAfter: 'Llega {{ days }} después',
        totalPrice: 'Precio total',
        seeDetail: 'Ver detalle',
        class: 'Clase',
        flightNumber: 'Nº vuelo',
        itinerary: 'Itinerario',
        flightTime: 'Tiempo de vuelo',
        improveFlight: 'Mejora la experiencia de vuelo',
        seeMoreRates: 'Ver más tarifas',
        threeDaysPrices: 'Precios +/- 3 días',
        threeDaysMessage: '(Los precios son por 1 pasajero ida y vuelta, los mismos podrían no estar actualizados al momento de cotizar)',
        checkPrices: 'Consultar precios',
        smallBaggageTitle: 'Incluye una mochila o cartera',
        smallBaggageDescription: 'Debe caber debajo del asiento delantero.',
        mediumBaggageTitle: 'Incluye equipaje de mano',
        mediumBaggageDescription: 'Debe caber en el compartimiento superior del avión.',
        mediumBaggageNotIncluded: 'No incluye equipaje de mano',
        largeBaggageNotIncluded: 'No incluye equipaje para despachar',
        largeBaggageIncluded: 'Incluye equipaje para despachar',
        pieceKgPerPassenger: '1 pieza de {{ kg }} kgs por {{ passenger }}.',
        piecePerPassenger: '1 pieza por {{ passenger }}.',
        piecesPerPassenger: '{{ pieces }} piezas por {{ passenger }}.',
        largeBaggageDescription: 'Se despacha en el aeropuerto durante el Check-in.',
        seatSelection: 'Selección de asiento',
        changes: 'Cambios',
        refund: 'Devoluciones',
        notIncluded: 'No incluido',
        included: 'Incluido',
        withExtraCost: 'Con costo',
        withoutExtraCost: 'Sin costo',
        allowedWithPenalty: 'Permitido con penalidad',
        allowedWithoutPenalty: 'Permitido sin penalidad',
        byPax: 'por pasajero',
        ratesByAirlines: 'Tarifas por aerolíneas',
        multipleAirlines: 'Múltiples aerolíneas',
        airline: 'Aerolínea',
        airports: 'Aeropuertos',
        availableRate: 'Tarifa disponible',
        estimatedRate: 'Tarifa estimada. Verifica la misma para validar disponibilidad',
        checkPrice: 'Chequeando tarifa de {{ currency }} {{ price }}',
        notAvailable: 'El vuelo seleccionado no se encuentra disponible',
        notAlternativeFares: 'La aerolínea no brindó otras opciones para la tarifa seleccionada',
        refreshFare: 'Actualizar tarifa',
        section: 'Tramo',
        criteria: {
          duration: 'Duración',
          scales: 'Escalas',
          baggage: 'Equipaje',
          changeable: 'Cambios',
          refundable: 'Devoluciones',
          seatSelection: 'Selección de asientos'
        },
        modifyCriteria: 'Modificar criterios',
        download: 'Descargar',
        print: 'Imprimir',
        selectCriteria: 'Selecciona tus criterios de comparativa',
        selectAll: 'Seleccionar todos',
        updateComparation: 'Actualizar comparativa'
      }
    },
    filters: {
      hotels: {
        filterSearch: 'Filtrar búsqueda',
        clear: 'Limpiar',
        favorites: 'Favoritos',
        onlyFavorites: 'Solo favoritos',
        hotelName: 'Nombre del hotel',
        selectHotel: 'Ingrese hotel',
        zones: 'Zonas',
        all: 'Todas',
        filterByStars: 'Clasificación por estrellas',
        allStars: 'Todas las estrellas',
        filterByProperty: 'Tipo de alojamiento',
        filterByBoardBase: 'Régimen de comida',
        filterByRateType: 'Tarifas',
        filterByAmenities: 'Servicios',
        seeMoreAmenities: 'Ver más servicios',
        seeLessAmenities: 'Ver menos servicios',
        filterByProviders: 'Proveedores',
        seeMoreProviders: 'Ver más proveedores',
        seeLessProviders: 'Ver menos proveedores',
        priceRange: 'Rango de precio',
        refoundable: 'Reembolsable',
        nonRefoundable: 'No Reembolsable',
        filterByRoomType: 'Tipo de habitación',
        filterByBedType: 'Tipo de cama',
        apply: 'Aplicar',
        properties: {
          apartment: 'Departamento',
          hostel: 'Hostel',
          hotel: 'Hotel',
          other: 'Otro'
        }
      },
      flights: {
        filterSearch: 'Filtrar búsqueda',
        clear: 'Limpiar',
        apply: 'Aplicar',
        filterByStopovers: 'Escalas',
        filterByBaggage: 'Equipaje',
        filterByAirline: 'Aerolínea',
        filterByClass: 'Clase',
        filterByFareType: 'Tipo de tarifa',
        filterByContentType: 'Tipo de contenido',
        priceRange: 'Rango de precio',
        filterByAirport: 'Aeropuerto',
        filterBySchedule: 'Horario',
        departureFrom: 'Despegue de {{from}}',
        arrivalTo: 'Aterrizaje en {{to}}',
        section: 'Tramo'
      }
    },
    checkout: {
      common: {
        agencyData: 'Datos de la agencia',
        nameInDocument: 'Como figura en el documento de viaje',
        name: 'Nombre',
        enterName: 'Ingresa un nombre',
        surname: 'Apellido',
        enterSurname: 'Ingresa un apellido',
        phoneNumber: 'Número de teléfono',
        enterPhoneNumber: 'Ingresa un teléfono',
        email: 'E-mail donde recibirás la confirmación de reserva',
        enterEmail: 'Ingresa un e-mail',
        observations: 'Observaciones (Campo de uso interno. No originará notificaciones al proveedor)',
        enterObservations: 'Ingresa observaciones',
        passengersData: 'Datos de los pasajeros',
        passenger: 'Pasajero',
        adult: 'Adulto',
        minorYoung: 'Menor/Joven',
        senior: 'Senior',
        years: 'años',
        names: 'Nombre(s)',
        surnames: 'Apellido(s)',
        gender: 'Sexo',
        passportNumber: 'Número de pasaporte',
        documentType: 'Tipo y número de documento',
        expirationDate: 'Fecha de expiración',
        birthday: 'Fecha de nacimiento',
        nacionality: 'Nacionalidad',
        residence: 'Residencia',
        titularPassenger: 'Pasajero titular',
        nameSurname: 'Nombre y Apellido',
        nameSurnameTitular: 'Nombre y Apellido pasajero titular',
        titularPassengerTooltip: 'Este dato debe corresponder al pasajero titular de la reserva independientemente de si es o no el pagador de la misma.',
        passengerTooltip: 'Este dato debe corresponder al pagador de la reserva independientemente si es o no uno de los viajeros.',
        enterCuilCuit: 'Ingrese CUIL/CUIT',
        reservation: 'Reserva',
        reservationNumber: 'Número de Reserva Asociado',
        reservationNumberTooltip: 'Completa el campo para asociar este producto a una reserva existente.',
        optional: 'Opcional',
        firstName: 'Primer nombre',
        lastName: 'Primer apellido',
        totalToPay: 'Total a pagar',
        generalConditions: 'Leí y acepto los Términos y Condiciones',
        general: 'Generales',
        continue: 'Continuar',
        finish: 'Finalizar',
        pay: 'Pagar',
        payPaypal: 'Continuar y pagar con PayPal',
        total: 'Total',
        totalTickets: 'Total Boletos',
        comission: 'Comisión',
        subtotal: 'Subtotal',
        bookingFee: 'Booking Fee',
        netRate: 'Tarifa Neta',
        administrativeExpenses: 'Gastos Administrativos',
        netAgency: 'Total Neto Agencia',
        selectPassenger: 'Seleccionar pasajero existente',
        accept: 'Aceptar',
        contact: 'Contacto',
        passengers: 'Pasajero/s',
        emergencyEmail: 'E-mail - Urgencias y alertas directas',
        enterValidEmail: 'Ingrese un e-mail válido',
        minLength: 'Debe tener una longitud mínima de {{length}} caracteres',
        maxLength: 'Debe tener una longitud máxima de {{length}} caracteres',
        minMaxNumber: 'Mínimo {{ minimun }}, máximo {{ maximun }} números',
        enterValidDate: 'Ingrese una fecha válida',
        enterLaterDate: 'Ingrese una fecha posterior',
        enterPreviousDate: 'Ingrese una fecha anterior',
        enterLaterBirthdate: 'Ingrese una fecha posterior',
        enterPreviousBirthdate: 'Ingrese una fecha anterior',
        noSpecialCharacters: 'Ingrese solo letras sin caracteres especiales como ñ o tildes',
        noRepeatDocumentNumber: 'Número de documento debe ser único',
        chosenPayment: 'Elegiste pagar con {{ paymentMethod }}',
        payment: 'Pago',
        internalCredit: 'Crédito interno',
        creditMessageSuccess: 'La reserva será emitida y su valor será descontado del crédito disponible.',
        creditMessageError: 'El crédito disponible es insuficiente para avanzar a la emisión. Por favor contáctanos para continuar con la gestión o selecciona otro método de pago.',
        modify: 'Modificar medio de pago',
        makePayment: '¡Realice su pago!',
        howToPay: '¿Cómo deseas pagar?',
        addAssitance: 'Agregar asistencia al viajero',
        bookingNumber: 'Número de reserva',
        bookingStatus: 'Estado de reserva',
        paymentStatus: 'Estado de pago',
        payWith: 'Pagar con',
        taxesIncluded: 'Impuestos y tasas incluidos',
        comments: 'Comentarios',
        attention: '¡Atención!',
        changePriceMessage: 'Hubo una actualización en la tarifa al momento de confirmarla.',
        changePriceReservation: 'Te informamos que hubo una actualizacion en la tarifa al momento de procesar tu reserva.',
        creditCardButton: 'Tarjeta de débito o crédito',
        completeFieldsPaypal: 'Completá los datos para avanzar con la emisión de la reserva',
        completeAllFieldsPaypal: 'Por favor, complete el formulario de pago. (Todos los campos son obligatorios)',
        purchaseDetailTitle: 'Detalle de tu reserva',
        billingData: 'Datos de facturación',
        cardNumber: 'Número de tarjeta',
        enterCardNumber: 'Ingrese número de tarjeta',
        holderName: 'Titular de la tarjeta',
        enterHolderName: 'Ingrese titular de la tarjeta',
        expiry: 'Fecha de vencimiento',
        enterExpiry: 'Ingrese fecha de vencimiento',
        cvc: 'Código de seguridad',
        enterCVC: 'Ingrese código de seguridad',
        documentNumber: 'Número de documento',
        enterDocumentNumber: 'Ingrese número de documento',
        valid: 'Válido hasta',
        enterValidCardNumber: 'Ingrese un número de tarjeta válido',
        enterValidDocumentNumber: 'Ingrese un número de documento válido',
        kid: 'Niño',
        baby: 'Bebé',
        ageMessage: 'La edad corresponde a los años cumplidos al finalizar el viaje',
        quotas: 'Cuotas',
        dni: 'DNI',
        passport: 'Pasaporte',
        creditCard: 'Tarjeta de crédito'
      },
      timer: {
        expiredSearch: '¡Tu búsqueda ha expirado!',
        returnResultsOf: 'Volvé a los resultados de tu búsqueda de {{ result }}',
        returnResults: 'Volver a resultados',
        reservationExpireIn: 'La reserva caducará en',
        timeToReservation: 'Te encuentras a un paso de concretar tu reserva. Una vez transcurrido el tiempo para completar el formulario, será necesario realizar una nueva búsqueda.'
      },
      error: {
        title: '¡Lo sentimos! Página no encontrada',
        description: 'No se pudo encontrar la página que buscabas, pero podés volver al inicio y continuar buscando tu viaje ideal.',
        returnHome: 'Volver al inicio',
        returnResults: 'Volver a resultados',
        reservationNotExists: 'La reserva introducida no existe'
      },
      secureSite: {
        title: '{{ site }} es un sitio seguro',
        titleIframe: 'Sitio seguro',
        description: 'Utilizamos conexiones seguras para proteger tu información.'
      },
      thankYouPage: {
        sucessBooking: '¡Tu reserva fue realizada con exito!',
        operationPerformed: 'Operación realizada',
        confirmed: 'Confirmado',
        autocancel: 'En caso de no reportarse el pago correspondiente, la reserva se autocancelará de acuerdo a lo indicado en la Política de Cancelación.',
        pendingIssue: 'Pendiente de emisión',
        pendingPayment: 'Pendiente de pago',
        approvedPayment: 'Pago aprobado',
        rejectedPayment: 'Pago rechazado',
        rejectedPaymentMessage: '¡Gracias por elegirnos! La reserva no ha podido emitirse dado que el pago ha sido rechazado. Por favor realice una nueva reserva y reintente el pago con una tarjeta diferente. Ante cualquier consulta o necesidad de asistencia por favor contactanos.',
        sucessBookingPendingIssue: 'La reserva se ha generado con éxito y se encuentra pendiente de emisión. La misma sólo se garantiza mediante el pago. La tarifa final está sujeta a que todos los tramos estén confirmados.',
        errorBooking: 'Tu reserva no pudo realizarse',
        errorBookingProccess: 'La reserva se encuentra en proceso de confirmación.',
        sorry: '¡Lo sentimos!',
        attention: '¡Atención!',
        rejected: 'Rechazada',
        unconfirmedReservation: 'La solicitud de reserva no se ha podido confirmar. Por favor inténtalo nuevamente.',
        failedOperation: 'Operación fallida',
        rejectedBooking: 'Reserva rechazada',
        continueBooking: 'Seguir reservando',
        anErrorOcurred: 'Ha habido un error. Contáctanos para poder asistirte y brindarte más detalles de tu solicitud.',
        managingBooking: 'Estamos gestionando tu reserva',
        pendingConfirmation: 'Pendiente de confirmación',
        bookingInProcess: 'TU RESERVA ESTÁ EN PROCESO. NO CIERRES ESTA VENTANA.',
        bookingStatusOnConfirm: 'Acá mismo verás reflejado el estado de tu reserva una vez procesada.',
        pendingPaymentTitle: 'Reserva pendiente de pago',
        pendingOperation: 'Operación pendiente',
        requestedIssue: 'Emisión solicitada',
        approvedCredit: 'Aprobado con crédito',
        sucessBookingAndPayment: '¡Gracias por elegirnos! La reserva y su pago se han generado con éxito. A la brevedad recibirás un mail de confirmación con la emisión de la misma. Ante cualquier consulta o necesidad de asistencia por favor contáctanos.',
        receiveReservationTo: 'Recibirás tu reserva por e-mail a',
        spam: 'No olvides revisar tu bandeja de Spam y Correo no deseado.',
        bookingDetail: 'Accede al detalle de tu reserva',
        myBookings: 'Mis reservas',
        addProducts: 'Sumá más servicios para estos pasajeros',
        newBooking: '¿Querés hacer una nueva reserva?',
        reserve: 'Reservar',
        insufficientCredit: 'Crédito insuficiente',
        insufficientCreditMessage: '¡Gracias por elegirnos! La reserva no ha podido emitirse dado que el crédito de la cuenta corriente es insuficiente. Por favor contáctenos para verificar el estado de su cuenta corriente o reintente el pago mediante tarjeta de crédito.',
        checkRealStatus: 'Antes de seguir reservando, recuerda solicitar a tu asesor de ventas, verificar el estado real de la misma con el proveedor, para evitar posibles gastos.',
        checkRealStatusConfirm: 'Por favor comuníquese con su asesor de ventas para verificar el estado final de la misma y asistirlo con la emisión.',
        reservationPendingPaymentFlight: 'La reserva se ha generado y se encuentra pendiente de emisión. La misma sólo se garantiza mediante el pago. Recordá que la tarifa puede sufrir variaciones hasta el momento de la emisión. En caso de no solicitar la emisión en el mismo día, la reserva se cancelará automáticamente.<br/>Horario de Guardia Sábado, Domingo y Feriados 10 a 17 Hs.',
        inAnalysis: 'En análisis',
        reservationInAnalysis: 'Su reserva esta siendo analizada'
      },
      hotels: {
        tariffWithCostsTitle: '¡Importante! Tarifa en gastos.',
        tariffWithCostsSubtitle: 'En caso de reservar aplicarán gastos.',
        room: 'Habitación',
        adult: 'Adulto',
        minor: 'Menor',
        years: 'años'
      },
      trains: {
        onlyFirstNameAndSurnameMessage: 'Se debe indicar únicamente el primer nombre y el primer apellido tal cual figuran en el documento de viaje que utilizará el pasajero.',
        minorTicketsMessage: 'Infoas: Viajan gratis sin derecho a asiento y sin ticket según edades: Trenes Reino Unido 0-4 años, Alemania y Suiza de 0-5 años, resto de Trenes 0-3 años inclusive.',
        minorPassesMessage: 'Infoas: Viajan gratis sin derecho a asiento y sin ticket según edades: Pase Eurail 0-3 años y Pase Suizo de 0-5 años inclusive.',
        conditions: 'Condiciones de la tarifa',
        showLess: 'Ver menos',
        showMore: 'Ver más',
        emergencyEmailTooltip: 'Este contacto será utilizado por la compañía del tren para enviar alertas o comunicaciones de carácter urgente'
      }
    }
  }
};

export default es;
