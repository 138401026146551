import React, { Fragment } from 'react';
import { Button, Checkbox, Divider, FormControlLabel, Grid, Skeleton, Typography } from '@mui/material';
import { RemoveCircleOutline, Tune } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const FiltersComponent = ({
  filters,
  loading,
  activeFilters,
  showAllRooms,
  handleChangeFilters
}) => {
  const { t } = useTranslation();

  const handleClearAllFilters = () => {
    handleChangeFilters && handleChangeFilters({});
  }

  const handleClearFilter = (filter) => {
    const activeFiltersAux = {...activeFilters};
    activeFiltersAux[filter] = [];
    handleChangeFilters && handleChangeFilters(activeFiltersAux);
  }

  const handleChangeFilter = (filter, value, checked) => {
    const activeFiltersAux = {...activeFilters};
    if (checked) {
      if (!activeFiltersAux[filter]) {
        activeFiltersAux[filter] = [value.value];
      } else {
        activeFiltersAux[filter].push(value.value);
      }
    } else {
      activeFiltersAux[filter] = activeFiltersAux[filter]?.filter(elem => elem !== value.value) || [];
    }

    handleChangeFilters && handleChangeFilters(activeFiltersAux);
  }

  return (
    <Grid className='filters-component'>
      <Grid className='row title' justifyContent='space-between'>
        <Grid className='row'><Tune />{t('filters.hotels.filterSearch')}</Grid>
        <Grid className='row'>
          <Button className='clear-filter-button' onClick={handleClearAllFilters} disabled={loading}>
            <RemoveCircleOutline />{t('filters.hotels.clear')}
          </Button>
        </Grid>
      </Grid>

      {loading ? (
        Array.from(Array(3).keys()).map(elem => (
          <Fragment key={elem}>
            <Grid className='section skeleton'>
              <Grid className='row' justifyContent='space-between'>
                <Skeleton variant='rounded' width='50%' />
              </Grid>
              <Grid className='filters'>
                <Skeleton variant='rounded' width='100%' />
                <Skeleton variant='rounded' width='100%' />
                <Skeleton variant='rounded' width='100%' />
                <Skeleton variant='rounded' width='100%' />
              </Grid>
            </Grid>

            {elem < 4 && <Divider />}
          </Fragment>
        ))
      ) : (
        <>
          {!showAllRooms && (
            <>
              <Grid className='section'>
                <Grid className='row' justifyContent='space-between'>
                  <Typography className='title'>{t('filters.hotels.filterByRateType')}</Typography>
                  {activeFilters['refoundable']?.length > 0 && (
                    <Grid className='row'>
                      <Button className='clear-filter-button' onClick={() => handleClearFilter('refoundable')} disabled={loading}>
                        <RemoveCircleOutline />{t('filters.hotels.clear')}
                      </Button>
                    </Grid>
                  )}
                </Grid>
                <Grid className='filters'>
                  {filters?.refoundable?.map((elem, i) => (
                    <Grid className='filter' justifyContent='space-between' key={i}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={activeFilters['refoundable']?.find(active => active === elem.value) ? true : false}
                            onChange={(e, checked) => handleChangeFilter('refoundable', elem, checked)}
                          />
                        }
                        label={elem.value === 'false' ? t('filters.hotels.nonRefoundable') : t('filters.hotels.refoundable')}
                      />
                      <span>({elem.total})</span>
                    </Grid>
                  ))}
                </Grid>
              </Grid>

              <Divider />
            </>
          )}
          

          <Grid className='section'>
            <Grid className='row' justifyContent='space-between'>
              <Typography className='title'>{t('filters.hotels.filterByBoardBase')}</Typography>
              {activeFilters['boardbase']?.length > 0 && (
                <Grid className='row'>
                  <Button className='clear-filter-button' onClick={() => handleClearFilter('boardbase')}>
                    <RemoveCircleOutline />{t('filters.hotels.clear')}
                  </Button>
                </Grid>
              )}
            </Grid>
            <Grid className='filters'>
              {filters?.boardbase?.map((elem, i) => (
                <Grid className='filter' justifyContent='space-between' key={i}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={activeFilters['boardbase']?.find(active => active === elem.value) ? true : false}
                        onChange={(e, checked) => handleChangeFilter('boardbase', elem, checked)}
                      />
                    }
                    label={elem.value}
                  />
                  <span>({elem.total})</span>
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Divider />

          <Grid className='section'>
            <Grid className='row' justifyContent='space-between'>
              <Typography className='title'>{t('filters.hotels.filterByRoomType')}</Typography>
              {activeFilters['roomName']?.length > 0 && (
                <Grid className='row'>
                  <Button className='clear-filter-button' onClick={() => handleClearFilter('roomName')}>
                    <RemoveCircleOutline />{t('filters.hotels.clear')}
                  </Button>
                </Grid>
              )}
            </Grid>
            <Grid className='filters'>
              {filters?.roomName?.map((elem, i) => (
                <Grid className='filter' justifyContent='space-between' key={i}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={activeFilters['roomName']?.find(active => active === elem.value) ? true : false}
                        onChange={(e, checked) => handleChangeFilter('roomName', elem, checked)}
                      />
                    }
                    label={elem.value}
                  />
                  <span>({elem.total})</span>
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Divider />

          <Grid className='section'>
            <Grid className='row' justifyContent='space-between'>
              <Typography className='title'>{t('filters.hotels.filterByBedType')}</Typography>
              {activeFilters['bedding']?.length > 0 && (
                <Grid className='row'>
                  <Button className='clear-filter-button' onClick={() => handleClearFilter('bedding')}>
                    <RemoveCircleOutline />{t('filters.hotels.clear')}
                  </Button>
                </Grid>
              )}
            </Grid>
            <Grid className='filters'>
              {filters?.bedding?.map((elem, i) => {
                if (elem.value && elem.value !== 'null') {
                  return <Grid className='filter' justifyContent='space-between' key={i}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={activeFilters['bedding']?.find(active => active === elem.value) ? true : false}
                          onChange={(e, checked) => handleChangeFilter('bedding', elem, checked)}
                        />
                      }
                      label={elem.value}
                    />
                    <span>({elem.total})</span>
                  </Grid>
                }

                return null;
              })}
            </Grid>
          </Grid>

          <Divider />

          <Grid className='section'>
            <Grid className='row' justifyContent='space-between'>
              <Typography className='title'>{t('filters.hotels.filterByProviders')}</Typography>
              {activeFilters['providerName']?.length > 0 && (
                <Grid className='row'>
                  <Button className='clear-filter-button' onClick={() => handleClearFilter('providerName')}>
                    <RemoveCircleOutline />{t('filters.hotels.clear')}
                  </Button>
                </Grid>
              )}
            </Grid>
            <Grid className='filters'>
              {filters?.providerName?.map((elem, i) => (
                <Grid className='filter' justifyContent='space-between' key={i}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={activeFilters['providerName']?.find(active => active === elem.value) ? true : false}
                        onChange={(e, checked) => handleChangeFilter('providerName', elem, checked)}
                      />
                    }
                    label={elem.value}
                  />
                  <span>({elem.total})</span>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default FiltersComponent;
