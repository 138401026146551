import React, { useEffect, useState } from 'react';
import {
    Box,
    TextField,
    Button,
    InputAdornment,
    FormLabel,
    Select,
    MenuItem,
    Typography,
    Autocomplete,
    Grid,
    IconButton,
    FormHelperText,
    Divider,
    Dialog,
    DialogContent,
    DialogActions,
    Fab,
    Popover,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Hidden
} from '@mui/material';
import dayjs from 'dayjs';
import { DesktopDatePicker, PickersDay } from '@mui/x-date-pickers';
import { getAutocompleteTrain, getDestinationPassesTrain } from '../../../store/services/Autocomplete';
import {
    AddBox,
    Add,
    ArrowBackOutlined,
    ArrowDropDown,
    ArrowDropUp,
    CheckOutlined,
    Close,
    Delete,
    LocationOn,
    PeopleAlt,
    Person,
    Remove,
    Search,
    SwapHoriz,
    InsertInvitation,
    DateRange
} from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useClientData } from '../../../context/ClientContext';

const DATE_FORMAT = 'DD/MM/YYYY';
const DATE_FORMAT_INTERNATIONAL = 'YYYY-MM-DD';

const MultibuscadorTrains = ({ defaultData, isResultsView, setDefaultDestinationPasses, ...props }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useParams();
    const { language } = useClientData();
    const [formData, setFormData] = useState({
        segments: [{
            origin: '',
            destination: '',
            dateDeparture: dayjs().format(DATE_FORMAT),
            timeDeparture: '08:00',
            dateArrive: '',
            timeArrive: ''
        }],
        adults: 1,
        seniors: 0,
        minors: 0,
        minorAges: [],
        ticketType: 'tickets',
        tripType: []
    });
    const [resumeData, setResumeData] = useState();
    const [anchorEl, setAnchorEl] = useState();
    const [openCheckin, setOpenCheckin] = useState(false);
    const [openCheckout, setOpenCheckout] = useState(false);
    const [timeoutAutocomplete, setTimeoutAutocomplete] = useState();
    const [abortController, setAbortController] = useState();
    const [loadingDestinations, setLoadingDestinations] = useState();
    const [destinations, setDestinations] = useState([]);
    const [destinationPasses, setDestinationPasses] = useState([]);
    const [errors, setErrors] = useState({ segments: [], minorAges: [] });
    const [openModalSwitzerland, setOpenModalSwitzerland] = useState(false);
    const [expanded, setExpanded] = useState(true);
    const [dayHover, setDayHover] = useState();

    useEffect(() => {
        if (isResultsView) {
            setExpanded(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (defaultData) {
            setFormData(defaultData);
            setResumeData(JSON.parse(JSON.stringify(defaultData)));

            if (defaultData.ticketType === 'tickets') {
                setLoadingDestinations(true);
                const defaultDataAux = JSON.parse(JSON.stringify(defaultData));

                ;(async () => {
                    await Promise.all(defaultDataAux.segments.map(async elem => {
                        elem.origin = await loadDefaultData(elem.origin.code) || '';
                        elem.destination = await loadDefaultData(elem.destination.code) || '';
                    }));

                    setFormData(defaultDataAux);
                    setResumeData(JSON.parse(JSON.stringify(defaultDataAux)));
                    setLoadingDestinations(false);
                })();
            } else {
                ;(async () => {
                    const destinationPassesAux = await getDestinationPasses();
                    if (destinationPassesAux && setDefaultDestinationPasses) {
                        const destinationAux = destinationPassesAux.find(elem => elem.key === defaultData?.segments?.[0]?.destination);
                        const defaultDataAux = JSON.parse(JSON.stringify(defaultData));
                        defaultDataAux.segments[0].destinationDescription = destinationAux.description;
                        setDefaultDestinationPasses(destinationAux);
                        setResumeData(JSON.parse(JSON.stringify(defaultDataAux)));
                    }
                })();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultData]);

    const loadDefaultData = async (code) => {
        const response = await getAutocompleteTrain(code, language);
        if (response && Array.isArray(response)) {
            const destination = response.find(elem => elem.code === code);
            if (destination) {
                return {
                    ...destination,
                    typeLabel: destination.type === 'city'
                        ? t('multiSearch.trains.city')
                        : t('multiSearch.trains.station'),
                };
            }
        }
    }

    const handleSubmit = (e, checkSwitzerland = true) => {
        e?.preventDefault();

        const user = localStorage.getItem('user');
        if (user) {
            const isPasses = formData.ticketType === 'passes';
            const errorsAux = {};
            let errorsCount = 0;

            errorsAux.segments = formData.segments.map(elem => {
                const errors = {
                    origin: !isPasses ? !Boolean(elem.origin) : false,
                    destination: !Boolean(elem.destination),
                    dateDeparture: !Boolean(elem.dateDeparture),
                    dateArrive: !isPasses ? formData.tripType.includes('roundTrip') && !Boolean(elem.dateArrive) : false,
                    timeDeparture: !Boolean(elem.timeDeparture),
                    timeArrive: !isPasses ? formData.tripType.includes('roundTrip') && !Boolean(elem.timeArrive) : false
                };
                errorsCount += Object.values(errors).filter(elem => elem === true).length;
                return errors;
            });

            const totalPassengers = formData.adults + formData.seniors + formData.minors;
            if (totalPassengers === 0) {
                errorsAux.noPassengers = true;
                errorsCount += 1;
            }

            const maxAge = isPasses ? 27 : 29;
            const minorAges = formData.minorAges.map(elem => elem ? parseInt(elem) : '');
            errorsAux.minorAges = minorAges.map(elem => elem === '' || elem > maxAge);
            errorsCount += errorsAux.minorAges.filter(elem => elem === true).length;

            setErrors(errorsAux);

            if (errorsCount === 0) {
                if (isPasses) {
                    if (checkSwitzerland && formData.segments[0].destination === 'CH') {
                       const minors16 = formData.minorAges.some(elem => parseInt(elem) <= 15);
                        if (minors16) {
                            setOpenModalSwitzerland(true);
                            return;
                        }
                    }

                    setOpenModalSwitzerland(false);
                    handleSubmitPasses();
                } else {
                    handleSubmitTickets();
                }
                setExpanded(false);
            }
        } else {
            navigate('/login', { state: { expiredSession: true } });
        }
    };

    const handleSubmitTickets = () => {
        const directTrains = formData.tripType.includes('directTrain') ? 1 : 0;
        const roundTrip = formData.tripType.includes('roundTrip') ? 1 : 0;
        const origin = [];
        const destination = [];
        const date = [];
        const time = [];
        const passengers = [];

        formData.segments.forEach(elem => {
            origin.push(`${elem.origin.type}__${elem.origin.code}`);
            destination.push(`${elem.destination.type}__${elem.destination.code}`);
            date.push(dayjs(elem.dateDeparture, DATE_FORMAT).format(DATE_FORMAT_INTERNATIONAL));
            time.push(elem.timeDeparture.replace(':', ''));

            if (formData.tripType.includes('roundTrip')) {
                origin.push(`${elem.destination.type}__${elem.destination.code}`);
                destination.push(`${elem.origin.type}__${elem.origin.code}`);
                date.push(dayjs(elem.dateArrive, DATE_FORMAT).format(DATE_FORMAT_INTERNATIONAL));
                time.push(elem.timeArrive.replace(':', ''));
            }
        });
        passengers.push(Array.from({ length: formData.seniors }, () => '60'));
        passengers.push(Array.from({ length: formData.adults }, () => '30'));
        passengers.push(formData.minorAges.splice(0, formData.minors));

        const iframePath = params.tokenIframe ? `/${params.tokenIframe}` : '';
        const url = iframePath + `/trenes/resultados/${formData.ticketType}/${directTrains}/${roundTrip}/${origin.join(',')}/${destination.join(',')}/${date.join(',')}/${time.join(',')}/${passengers.flat().join('-')}`;
        navigate(url);
    }

    const handleSubmitPasses = () => {
        const destination = formData?.segments?.[0]?.destination;
        const date = dayjs(formData?.segments?.[0]?.dateDeparture, DATE_FORMAT).format(DATE_FORMAT_INTERNATIONAL);
        const time = formData?.segments?.[0]?.timeDeparture?.replace(':', '');
        const passengers = [];
        passengers.push(Array.from({ length: formData.seniors }, () => '60'));
        passengers.push(Array.from({ length: formData.adults }, () => '28'));
        passengers.push(formData.minorAges.splice(0, formData.minors));

        const iframePath = params.tokenIframe ? `/${params.tokenIframe}` : '';
        const url = iframePath + `/trenes/resultados/${formData.ticketType}/${destination}/${date}/${time}/${passengers.flat().join('-')}`;
        navigate(url);
    }

    const handleChangeDestination = (value) => {
        if (value && value.length > 3) {
            clearTimeout(timeoutAutocomplete)
            const timeout = setTimeout(async () => {
                abortController && abortController.abort();
                const controller = new AbortController();
                setAbortController(controller);
                setLoadingDestinations(true);
                let response = await getAutocompleteTrain(value, language, controller.signal);
                response = Array.isArray(response) && response
                    ?.map(elem => ({
                        ...elem,
                        typeLabel: elem.type === 'city'
                            ? t('multiSearch.trains.city')
                            : t('multiSearch.trains.station'),
                    }));
                setDestinations(response || []);
                setAbortController(null);
                setLoadingDestinations(false);
                setAbortController(null);
                setLoadingDestinations(false);
            }, 500);
            setTimeoutAutocomplete(timeout);
        }
    }

    const handleBlurField = () => {
        abortController && abortController.abort();
        clearTimeout(timeoutAutocomplete);
        setDestinations([]);
        setAbortController(null);
        setLoadingDestinations(false);
    }

    const handleClickSwapDestinations = (index) => {
        const segments = [...formData.segments];
        const originAux = segments[index].origin;
        segments[index].origin = segments[index].destination;
        segments[index].destination = originAux;

        setFormData(current => ({
            ...current,
            segments
        }));
    }

    const handleChangeSegmentField = (field, index, value) => {
        const segments = [...formData.segments];
        segments[index][field] = value;

        if (field === 'dateDeparture') {
            const dateDeparture = value ? dayjs(value, DATE_FORMAT) : null;
            const dateArrive = segments[index].dateArrive ? dayjs(segments[index].dateArrive, DATE_FORMAT) : null;

            if (dateDeparture && dateArrive && dateDeparture.isAfter(dateArrive, 'date')) {
                segments[index].dateArrive = '';
            }

            if (formData.tripType.includes('roundTrip')) {
                setOpenCheckout(index);
            }
        } else if (field === 'timeDeparture') {
            segments[index].timeArrive = value;
        }

        setFormData(current => ({
            ...current,
            segments
        }));
    }

    const handleChangeField = (field, value, indexPax = null, checked = false) => {
        let formDataAux = {...formData};

        if (field === 'minorAges') {
            formDataAux[field].splice(indexPax, 1, value)
        } else if (field === 'tripType') {
            if (checked) {
                formDataAux[field].push(value);
            } else {
                formDataAux[field] = formDataAux[field].filter(elem => elem !== value);
            }

            if (value === 'roundTrip') {
                formDataAux.segments = [{
                    ...formDataAux.segments[0],
                    dateArrive: checked ? dayjs(formDataAux.segments[0].dateDeparture, DATE_FORMAT).add(1, 'day').format(DATE_FORMAT) : '',
                    timeArrive: checked ? '08:00' : ''
                }]
            }
        } else {
            formDataAux[field] = value;

            if (field === 'ticketType') {
                formDataAux = {
                    ...formDataAux,
                    segments: [{
                        origin: '',
                        destination: '',
                        dateDeparture: dayjs().format(DATE_FORMAT),
                        timeDeparture: '08:00',
                        dateArrive: '',
                        timeArrive: ''
                    }],
                    adults: 1,
                    seniors: 0,
                    minors: 0,
                    minorAges: [],
                    tripType: []
                };

                if (value === 'passes') {
                    getDestinationPasses();
                }
            } else if (field === 'minors') {
                const minorAges = formDataAux.minorAges.slice(0, value);

                while (minorAges.length < value) {
                    minorAges.push(0)
                }

                formDataAux.minorAges = minorAges;
            }
        }

        setFormData(formDataAux);
    }

    const handleAddSegment = () => {
        let dateDeparture = formData.segments[formData.segments.length - 1].dateDeparture;
        dateDeparture = dayjs(dateDeparture, DATE_FORMAT).add(1, 'day').format(DATE_FORMAT);
        const segments = [...formData.segments];
        segments.push({
            origin: '',
            destination: '',
            dateDeparture,
            timeDeparture: '08:00'
        });
        setFormData(current => ({
            ...current,
            segments
        }))
    }

    const handleRemoveSegment = (index) => {
        const segments = [...formData.segments];
        segments.splice(index, 1);
        setFormData(current => ({
            ...current,
            segments
        }))
    }

    const getMaxPassengers = (field) => {
        const fields = ['adults', 'seniors', 'minors'];
        let count = formData.ticketType === 'passes' ? 10 : 9;

        fields.forEach(elem => {
            if (elem !== field) {
                count -= (formData[elem] || 0);
            }
        });

        return count;
    }

    const getDestinationPasses = async () => {
        const accessToken = localStorage.getItem('jwt');
        const response = await getDestinationPassesTrain(accessToken);
        setDestinationPasses(response);
        return response;
    }

    const getDestinationPassesSelect = () => {
        const data = [
            {
                title: 'Multi-País',
                data: destinationPasses?.filter(elem => elem.type === 'multi_country') || []
            },
            {
                title: 'Países Populares',
                data: destinationPasses?.filter(elem => elem.type === 'popular_countries') || []
            },
            {
                title: 'País',
                data: destinationPasses?.filter(elem => elem.type === 'one_country') || []
            }
        ];

        const options = []

        data.forEach((elem, i) => {
            if (elem.data.length > 0) {
                options.push(<span className='group-title' key={i}>{elem.title}</span>);
                elem.data.forEach((data, j) => (
                    options.push(<MenuItem key={`${j}-${data.key}`} value={data.key} className='select-option'>
                        {data.description}
                    </MenuItem>)
                ))
            }
        });

        return options;
    }

    const handleClickGuests = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseGuests = () => {
        setAnchorEl(null);
    };

    const getResumeData = () => {
        const date = resumeData?.ticketType === 'tickets' ? '' : resumeData?.segments?.[0]?.dateDeparture;
        const passengers = resumeData?.adults + resumeData?.minors + resumeData?.seniors;

        let destinations = [];
        if (resumeData?.ticketType === 'tickets') {
            destinations = resumeData?.segments?.map(destination => ([
                destination?.origin?.label, destination?.destination?.label
            ]));
        } else {
            destinations = [[resumeData?.segments?.[0]?.destinationDescription]];
        }

        destinations = destinations.filter(elem => elem && elem.every(destination => destination));

        if (resumeData) {
            return <>
                {destinations.map((destination, i) => (
                    <Grid className='row destination' key={i}>
                        <LocationOn />
                        {destination.join(' - ')}
                    </Grid>
                ))}
                {date && (
                    <Grid className='row date'>
                        <InsertInvitation />
                        {dayjs(date, DATE_FORMAT).format('dddd, DD MMM YY')}
                    </Grid>
                )}
                {passengers && (
                    <Grid className='row pax'>
                        <PeopleAlt />
                        {passengers}
                    </Grid>
                )}
            </>
        }
    }

    const getSubmitButton = () => {
        return <Button
            variant="contained"
            color="secondary"
            size="small"
            type='submit'
            className='submit-button'
            sx={{ width: { xl: '100%' } }}
        >
            {t('common.search')}
        </Button>
    }

    const isSelected = (day, segment) => {
        const startDate = segment.dateDeparture && dayjs(segment.dateDeparture, DATE_FORMAT);
        const endDate = segment.dateArrive && dayjs(segment.dateArrive, DATE_FORMAT);

        return (startDate ? startDate.isSame(day, 'date') : false)
            || (endDate ? endDate.isSame(day, 'date') : false);
    }

    const isBetween = (day, segment) => {
        if (segment.dateDeparture && segment.dateArrive) {
            return day.isBetween(dayjs(segment.dateDeparture, DATE_FORMAT), dayjs(segment.dateArrive, DATE_FORMAT), 'date', '[]');
        }

        return false
    }

    const isDisabled = (day, segment) => {
        let flag = false;

        if (day.isBefore(dayjs(), 'date')) {
            flag = flag || true;
        }

        if (openCheckout !== null && segment.dateDeparture) {
            const startDate = dayjs(segment.dateDeparture, DATE_FORMAT);
            flag = flag || day.isBefore(startDate, 'date');
        }

        return flag;
    }

    const getPickerDay = (day, _value, DayComponentProps, segment) => {
        const date = dayjs(day.$d);
        const isBetweenAux = isBetween(date, segment);
        const isStartDate = segment.dateDeparture === date.format(DATE_FORMAT);
        const isEndDate = segment.dateArrive === date.format(DATE_FORMAT);

        const classNames = [];
        if (isBetweenAux) classNames.push('range-between');
        if (isStartDate) classNames.push('start-date');
        if (isEndDate) classNames.push('end-date');

        const classNamesDay = [];
        if (!isStartDate && !isEndDate) {
            if (isBetweenAux) {
                classNamesDay.push('between');
            } else if (segment.dateDeparture && dayHover) {
                if (openCheckin !== null && segment.dateArrive && date.isBetween(dayHover, dayjs(segment.dateDeparture, DATE_FORMAT), 'date', '()')) {
                    classNamesDay.push('preselected-day');
                } else if (openCheckout !== null) {
                    const dateAux = segment.dateArrive ? dayjs(segment.dateArrive, DATE_FORMAT) : dayjs(segment.dateDeparture, DATE_FORMAT);
                    segment.dateArrive && date.isBetween(dayjs(segment.dateArrive, DATE_FORMAT), dayHover, 'date', '()')
                    if (dateAux && date.isBetween(dateAux, dayHover, 'date', '()')) {
                        classNamesDay.push('preselected-day');
                    }
                }
            }
        }

        return (
            <div className={classNames.join(' ')} key={DayComponentProps.key}>
                <PickersDay
                    {...DayComponentProps}
                    className={classNamesDay.join(' ')}
                    selected={isSelected(date, segment)}
                    disabled={isDisabled(date, segment)}
                    onMouseOver={() => setDayHover(date)}
                    onMouseLeave={() => setDayHover(null)}
                    disableHighlightToday
                />
            </div>
        );
    }

    // const isDisabledTime = (time, segment) => {
    //     const timeDeparture = segment.timeDeparture ? dayjs(segment.timeDeparture, 'HH:mm') : null;
    //     const timeArrive = time ? dayjs(time, 'HH:mm') : null;

    //     if (segment.dateDeparture === segment.dateArrive) {
    //         return timeDeparture.isSameOrAfter(timeArrive, 'hour');
    //     }

    //     return false;
    // }

    const getPassengersValue = () => {
        const passengers = [
            `${formData.adults} ${formData.adults === 1 ? t('multiSearch.trains.adult') : t('multiSearch.trains.adults')}`,
            `${formData.minors} ${formData.minors === 1 ? t('multiSearch.trains.young') : t('multiSearch.trains.youngs')}`,
            `${formData.seniors} ${formData.seniors === 1 ? t('multiSearch.trains.senior') : t('multiSearch.trains.seniors')}`
        ];

        return passengers.join(', ');
    }

    return (
        <Box className="train-search search-box-container">
            {isResultsView && (
                <Grid className='resume-container row'>
                    <Button className='back-button' onClick={() => navigate('/trenes')}>
                        <ArrowBackOutlined fontSize='small' />
                        {t('common.back')}
                    </Button>

                    {getResumeData()}

                    <Button className='modify-button' variant='outlined' onClick={() => setExpanded(!expanded)}>
                        {expanded ? <Close fontSize='small' /> : <Search fontSize='small' />}
                        {expanded ? t('common.cancel') : t('common.modifyAndAdd')}
                    </Button>
                </Grid>
            )}

            <Accordion expanded={expanded}>
                <AccordionSummary />
                <AccordionDetails>
                    <form onSubmit={e => handleSubmit(e)}>
                        <Grid container className="search-box" spacing={1}>
                            <Grid item xs={12} className='row mb-05'>
                                <Grid className='ticket-type-buttons'>
                                    <Button
                                        variant='outlined'
                                        className={formData.ticketType === 'tickets' ? 'selected' : ''}
                                        onClick={e => handleChangeField('ticketType', 'tickets')}
                                    >
                                        {formData.ticketType === 'tickets' && <CheckOutlined fontSize='small' />}
                                        {t('multiSearch.trains.tickets')}
                                    </Button>
                                    <Button
                                        variant='outlined'
                                        className={formData.ticketType === 'passes' ? 'selected' : ''}
                                        onClick={e => handleChangeField('ticketType', 'passes')}
                                    >
                                        {formData.ticketType === 'passes' && <CheckOutlined fontSize='small' />}
                                        {t('multiSearch.trains.passes')}
                                    </Button>
                                </Grid>
                                {formData.ticketType === 'tickets' && (
                                    <>
                                        <Divider orientation="vertical" flexItem />
                                        <Grid className='trip-type-buttons'>
                                            <Button
                                                variant='outlined'
                                                className={formData.tripType.includes('directTrain') ? 'selected' : ''}
                                                onClick={e => handleChangeField('tripType', 'directTrain', null, !formData.tripType.includes('directTrain'))}
                                            >
                                                {t('multiSearch.trains.direct')}
                                            </Button>
                                            <Button
                                                variant='outlined'
                                                className={formData.tripType.includes('roundTrip') ? 'selected' : ''}
                                                onClick={e => handleChangeField('tripType', 'roundTrip', null, !formData.tripType.includes('roundTrip'))}
                                            >
                                                {t('multiSearch.trains.roundTrip')}
                                            </Button>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                            <Grid item xs={12} xl={11}>
                                <Grid container spacing={1} columns={24}>
                                    <Grid
                                        item
                                        xs={24}
                                        lg={17}
                                        xl={19}
                                        sx={{ paddingRight: { md: '40px', lg: 0 } }}
                                    >
                                        <Grid container spacing={1} rowSpacing={2}>
                                            {formData.segments.map((segment, i) => (
                                                <Grid item key={i} xs={12} className='row segment-container'>
                                                    {formData.ticketType === 'tickets' && (
                                                        <div className='segment-number'>{i + 1}</div>
                                                    )}
                                                    <Grid
                                                        container
                                                        spacing={1}
                                                        columns={24}
                                                        style={{ paddingLeft: 40 }}
                                                    >
                                                        <Grid
                                                            item
                                                            xs={24}
                                                            md={formData.ticketType === 'tickets' ? 24 : 17}
                                                            lg={formData.ticketType === 'tickets' ? 24 : 18}
                                                            xl={formData.tripType.includes('roundTrip') ? 13 : 16}
                                                        >
                                                            {formData.ticketType === 'tickets' ? (
                                                                <Grid container spacing={1}>
                                                                    <Grid item xs={12} sm={6} style={{ paddingRight: 20, position: 'relative' }}>
                                                                        <FormLabel>{t('multiSearch.trains.origin')}</FormLabel>
                                                                        <Autocomplete
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            size="small"
                                                                            options={destinations || []}
                                                                            getOptionLabel={option => `${option?.label}, ${option?.country?.label}`}
                                                                            groupBy={(option) => option.typeLabel}
                                                                            value={segment?.origin?.label && segment?.origin?.country?.label ? segment?.origin : null}
                                                                            onChange={(e, value) => handleChangeSegmentField('origin', i, value)}
                                                                            noOptionsText={t('common.noOptions')}
                                                                            loadingText={`${t('common.loading')}...`}
                                                                            loading={loadingDestinations}
                                                                            renderInput={(params) =>
                                                                                <TextField
                                                                                    {...params}
                                                                                    placeholder={t('multiSearch.trains.selectCity')}
                                                                                    onChange={(e) => handleChangeDestination(e.target.value)}
                                                                                    error={errors.segments?.[i]?.origin}
                                                                                    onBlur={handleBlurField}
                                                                                />
                                                                            }
                                                                            renderGroup={(params) => (
                                                                                <li {...params}>
                                                                                    <span className='group-title'>
                                                                                        {params.group}
                                                                                    </span>
                                                                                    <ul className='group-option'>{params.children}</ul>
                                                                                </li>
                                                                            )}
                                                                        />
                                                                        <IconButton
                                                                            className='search-stations-switcher'
                                                                            onClick={() => handleClickSwapDestinations(i)}
                                                                        >
                                                                            <SwapHoriz />
                                                                        </IconButton>
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={6} style={{ paddingLeft: 20 }}>
                                                                        <FormLabel>{t('multiSearch.trains.destination')}</FormLabel>
                                                                        <Autocomplete
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            size="small"
                                                                            options={destinations || []}
                                                                            getOptionLabel={option => `${option?.label}, ${option?.country?.label}`}
                                                                            groupBy={(option) => option.typeLabel}
                                                                            value={segment?.destination?.label && segment?.destination?.country?.label ? segment?.destination : null}
                                                                            onChange={(e, value) => handleChangeSegmentField('destination', i, value)}
                                                                            noOptionsText={t('common.noOptions')}
                                                                            loadingText={`${t('common.loading')}...`}
                                                                            loading={loadingDestinations}
                                                                            renderInput={(params) =>
                                                                                <TextField
                                                                                    {...params}
                                                                                    placeholder={t('multiSearch.trains.selectDestination')}
                                                                                    onChange={(e) => handleChangeDestination(e.target.value)}
                                                                                    error={errors.segments?.[i]?.destination}
                                                                                    onBlur={handleBlurField}
                                                                                />
                                                                            }
                                                                            renderGroup={(params) => (
                                                                                <li {...params}>
                                                                                    <span className='group-title'>
                                                                                        {params.group}
                                                                                    </span>
                                                                                    <ul className='group-option'>{params.children}</ul>
                                                                                </li>
                                                                            )}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            ) : (
                                                                <>
                                                                    <FormLabel>{t('multiSearch.trains.destination')}</FormLabel>
                                                                    <Select
                                                                        fullWidth
                                                                        size='small'
                                                                        displayEmpty
                                                                        className={segment.destination ? '' : 'select-placeholder'}
                                                                        value={segment.destination}
                                                                        disabled={!destinationPasses || destinationPasses.length === 0}
                                                                        renderValue={value => {
                                                                            if (value) {
                                                                                return destinationPasses.find(elem => elem.key === value)?.description;
                                                                            }

                                                                            return t('multiSearch.trains.selectDestination');
                                                                        }}
                                                                        onChange={(e) => handleChangeSegmentField('destination', i, e.target.value)}
                                                                    >
                                                                        {getDestinationPassesSelect()}
                                                                    </Select>
                                                                </>
                                                            )}
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={24}
                                                            md={formData.ticketType !== 'tickets' ? 7 : 24}
                                                            lg={true}
                                                        >
                                                            <Grid container spacing={1}>
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    sm={formData.ticketType === 'tickets'
                                                                        ? formData.tripType.includes('roundTrip') ? 4 : 8
                                                                        : 12
                                                                    }
                                                                    md={formData.ticketType === 'tickets'
                                                                        ? formData.tripType.includes('roundTrip') ? 5 : 8
                                                                        : 12
                                                                    }
                                                                    lg={formData.ticketType === 'tickets'
                                                                        ? formData.tripType.includes('roundTrip') ? 5 : 7
                                                                        : 12
                                                                    }
                                                                    xl={formData.ticketType === 'tickets'
                                                                        ? formData.tripType.includes('roundTrip') ? 4 : 7
                                                                        : 12
                                                                    }
                                                                >
                                                                    <FormLabel>
                                                                        {formData.ticketType === 'tickets'
                                                                            ? t('multiSearch.trains.departure')
                                                                            : t('multiSearch.trains.firstDayOfValidity')
                                                                        }
                                                                    </FormLabel>
                                                                    <DesktopDatePicker
                                                                        open={openCheckin === i}
                                                                        onClose={() => setOpenCheckin(false)}
                                                                        onChange={(e) => handleChangeSegmentField('dateDeparture', i, e.format('L'))}
                                                                        showDaysOutsideCurrentMonth
                                                                        value={segment.dateDeparture ? dayjs(segment.dateDeparture, DATE_FORMAT) : null}
                                                                        className='pointer-input'
                                                                        renderDay={(day, _value, DayComponentProps) => getPickerDay(
                                                                            day,
                                                                            _value,
                                                                            DayComponentProps,
                                                                            segment
                                                                        )}
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                                {...params}
                                                                                id='checkin-input'
                                                                                focused={false}
                                                                                placeholder={DATE_FORMAT}
                                                                                value={segment.dateDeparture ? segment.dateDeparture : ''}
                                                                                inputProps={{ readOnly: true }}
                                                                                size='small'
                                                                                fullWidth
                                                                                error={errors.segments?.[i]?.dateDeparture}
                                                                                InputProps={{
                                                                                    startAdornment: (
                                                                                        <InputAdornment position="start">
                                                                                            <DateRange />
                                                                                        </InputAdornment>
                                                                                    )
                                                                                }}
                                                                                onClick={() => {
                                                                                    setOpenCheckout(null)
                                                                                    setOpenCheckin(i)
                                                                                }}
                                                                            />
                                                                        )}
                                                                    />
                                                                </Grid>
                                                                {formData.tripType.includes('roundTrip') && (
                                                                    <Grid item xs={12} sm={4} md={5} lg={5} xl={4}>
                                                                        <FormLabel>{t('multiSearch.trains.return')}</FormLabel>
                                                                        <DesktopDatePicker
                                                                            open={openCheckout === i}
                                                                            onClose={() => setOpenCheckout(false)}
                                                                            onChange={(e) => handleChangeSegmentField('dateArrive', i, e.format('L'))}
                                                                            showDaysOutsideCurrentMonth
                                                                            value={segment.dateArrive ? dayjs(segment.dateArrive, DATE_FORMAT) : null}
                                                                            className='pointer-input'
                                                                            renderDay={(day, _value, DayComponentProps) => getPickerDay(
                                                                                day,
                                                                                _value,
                                                                                DayComponentProps,
                                                                                segment
                                                                            )}
                                                                            renderInput={(params) => (
                                                                                <TextField
                                                                                    {...params}
                                                                                    id='checkout-input'
                                                                                    focused={false}
                                                                                    placeholder={DATE_FORMAT}
                                                                                    value={segment.dateArrive ? segment.dateArrive : ''}
                                                                                    inputProps={{ readOnly: true }}
                                                                                    size='small'
                                                                                    fullWidth
                                                                                    error={errors.segments?.[i]?.dateArrive}
                                                                                    InputProps={{
                                                                                        startAdornment: (
                                                                                            <InputAdornment position="start">
                                                                                                <DateRange />
                                                                                            </InputAdornment>
                                                                                        )
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        setOpenCheckin(null)
                                                                                        setOpenCheckout(i)
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        />
                                                                    </Grid>
                                                                )}
                                                                {formData.ticketType === 'tickets' && (
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        sm={4}
                                                                        md={formData.tripType.includes('roundTrip') ? 2 : 4}
                                                                        lg={formData.tripType.includes('roundTrip') ? 2 : 5}
                                                                        xl={formData.tripType.includes('roundTrip') ? 4 : 5}
                                                                    >
                                                                        <FormLabel>{t('multiSearch.trains.hour')}</FormLabel>
                                                                        <Select
                                                                            fullWidth
                                                                            size='small'
                                                                            value={segment.timeDeparture}
                                                                            onChange={(e) => handleChangeSegmentField('timeDeparture', i, e.target.value)}
                                                                            error={errors.segments?.[i]?.timeDeparture}
                                                                        >

                                                                            {Array.from({ length: 24 }, (_, index) => (
                                                                                <MenuItem key={index} value={`${index < 10 ? `0${index}` : index}:00`}>
                                                                                    {`${index < 10 ? `0${index}` : index}:00`}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </Grid>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                        {/* {formData.tripType.includes('roundTrip') && (
                                                            <Grid item xs={24} md={true}>
                                                                <Grid container spacing={1}>
                                                                    <Grid item xs={12} md={8} lg={7}>
                                                                        <FormLabel>{t('multiSearch.trains.return')}</FormLabel>
                                                                        <DesktopDatePicker
                                                                            open={openCheckout === i}
                                                                            onClose={() => setOpenCheckout(false)}
                                                                            onChange={(e) => handleChangeSegmentField('dateArrive', i, e.format('L'))}
                                                                            showDaysOutsideCurrentMonth
                                                                            value={segment.dateArrive ? dayjs(segment.dateArrive, DATE_FORMAT) : null}
                                                                            className='pointer-input'
                                                                            renderDay={(day, _value, DayComponentProps) => getPickerDay(
                                                                                day,
                                                                                _value,
                                                                                DayComponentProps,
                                                                                segment
                                                                            )}
                                                                            renderInput={(params) => (
                                                                                <TextField
                                                                                    {...params}
                                                                                    id='checkout-input'
                                                                                    focused={false}
                                                                                    placeholder={DATE_FORMAT}
                                                                                    value={segment.dateArrive ? segment.dateArrive : ''}
                                                                                    inputProps={{ readOnly: true }}
                                                                                    size='small'
                                                                                    fullWidth
                                                                                    error={errors.segments?.[i]?.dateArrive}
                                                                                    InputProps={{
                                                                                        startAdornment: (
                                                                                            <InputAdornment position="start">
                                                                                                <DateRange />
                                                                                            </InputAdornment>
                                                                                        )
                                                                                    }}
                                                                                    onClick={() => {
                                                                                        setOpenCheckin(null)
                                                                                        setOpenCheckout(i)
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} md={4} lg={5}>
                                                                        <FormLabel>{t('multiSearch.trains.hour')}</FormLabel>
                                                                        <Select
                                                                            fullWidth
                                                                            size='small'
                                                                            value={segment.timeArrive}
                                                                            onChange={(e) => handleChangeSegmentField('timeArrive', i, e.target.value)}
                                                                            error={errors.segments?.[i]?.timeArrive}

                                                                        >
                                                                            {Array.from({ length: 24 }, (_, index) => (
                                                                                <MenuItem
                                                                                    key={index}
                                                                                    value={`${index < 10 ? `0${index}` : index}:00`}
                                                                                    disabled={isDisabledTime(`${index < 10 ? `0${index}` : index}:00`, segment)}
                                                                                >
                                                                                    {`${index < 10 ? `0${index}` : index}:00`}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        )} */}
                                                    </Grid>
                                                    {i > 0 && (
                                                        <IconButton className='delete-segment-button' onClick={() => handleRemoveSegment(i)}>
                                                            <Delete />
                                                        </IconButton>
                                                    )}
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item xs={24}
                                        lg={true}
                                        sx={{
                                            paddingLeft: { xs: '48px !important', lg: '8px !important' },
                                            paddingRight: { md: '40px', lg: 0 }
                                        }}
                                    >
                                        <FormLabel>{t('multiSearch.trains.passengers')}</FormLabel>
                                        <TextField
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            onClick={handleClickGuests}
                                            className='pointer-input'
                                            value={getPassengersValue()}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Person />
                                                    </InputAdornment>
                                                ),
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        {Boolean(anchorEl) ? <ArrowDropUp /> : <ArrowDropDown />}
                                                    </InputAdornment>
                                                ),
                                                readOnly: true
                                            }}
                                        />
                                        <FormHelperText error={true}>
                                            {errors.noPassengers ? t('multiSearch.trains.atLeastOnePassenger') : <>&nbsp;</>}
                                        </FormHelperText>
                                        <Popover
                                            open={Boolean(anchorEl)}
                                            anchorEl={anchorEl}
                                            onClose={handleCloseGuests}
                                            className='trains-guests-popover'
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                        >
                                            <Grid p={2}>
                                                <Grid className='passengers-input'>
                                                    <div>
                                                        <Typography className='title'>{t('multiSearch.trains.adults')}</Typography>
                                                        <Typography className='subtitle'>
                                                            {t('multiSearch.trains.fromToYears', { from: formData.ticketType === 'passes' ? 28 : 30, to: 59 })}
                                                        </Typography>
                                                    </div>

                                                    <div className='buttons-container row'>
                                                        <IconButton
                                                            disabled={formData.adults === 0}
                                                            onClick={() => handleChangeField('adults', formData.adults - 1)}
                                                        >
                                                            <Remove />
                                                        </IconButton>
                                                        <span>{formData.adults}</span>
                                                        <IconButton
                                                            disabled={formData.adults === getMaxPassengers('adults')}
                                                            onClick={() => handleChangeField('adults', formData.adults + 1)}
                                                        >
                                                            <Add />
                                                        </IconButton>
                                                    </div>
                                                </Grid>

                                                <Divider />

                                                <Grid className='passengers-input'>
                                                    <div>
                                                        <Typography className='title'>{t('multiSearch.trains.senior')}</Typography>
                                                        <Typography className='subtitle'>
                                                            {t('multiSearch.trains.fromYears', { years: 60 })}
                                                        </Typography>
                                                    </div>

                                                    <div className='buttons-container row'>
                                                        <IconButton
                                                            disabled={formData.seniors === 0}
                                                            onClick={() => handleChangeField('seniors', formData.seniors - 1)}
                                                        >
                                                            <Remove />
                                                        </IconButton>
                                                        <span>{formData.seniors}</span>
                                                        <IconButton
                                                            disabled={formData.seniors === getMaxPassengers('seniors')}
                                                            onClick={() => handleChangeField('seniors', formData.seniors + 1)}
                                                        >
                                                            <Add />
                                                        </IconButton>
                                                    </div>
                                                </Grid>

                                                <Divider />

                                                <Grid className='passengers-input'>
                                                    <div>
                                                        <Typography className='title'>{t('multiSearch.trains.minorYoung')}</Typography>
                                                        <Typography className='subtitle'>
                                                            {t('multiSearch.trains.toYears', { years: formData.ticketType === 'passes' ? 27 : 29 })}
                                                        </Typography>
                                                    </div>

                                                    <div className='buttons-container row'>
                                                        <IconButton
                                                            disabled={formData.minors === 0}
                                                            onClick={() => handleChangeField('minors', formData.minors - 1)}
                                                        >
                                                            <Remove />
                                                        </IconButton>
                                                        <span>{formData.minors}</span>
                                                        <IconButton
                                                            disabled={formData.minors === getMaxPassengers('minors')}
                                                            onClick={() => handleChangeField('minors', formData.minors + 1)}
                                                        >
                                                            <Add />
                                                        </IconButton>
                                                    </div>
                                                </Grid>

                                                {formData.minors > 0 && (
                                                    Array.from({ length: formData.minors }, (_, index) => (
                                                        <Grid key={index + 1} className='passenger-age-container'>
                                                            <Typography className='title'>{t('multiSearch.trains.youngAge')} {index + 1}</Typography>
                                                            <Select
                                                                variant="outlined"
                                                                size="small"
                                                                value={formData.minorAges[index]}
                                                                onChange={(e) => handleChangeField('minorAges', e.target.value, index)}
                                                            >
                                                                {Array.from({ length: formData.ticketType === 'passes' ? 28 : 30 }, (_, ageIndex) => (
                                                                    <MenuItem key={ageIndex} value={ageIndex}>
                                                                        {ageIndex} {t('multiSearch.trains.years').toLowerCase()}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </Grid>
                                                    ))
                                                )}

                                                <Grid container justifyContent="flex-end">
                                                    <Typography className='max-pax-text'>
                                                        {t('multiSearch.trains.maxPassengers', { passengers: formData.ticketType === 'passes' ? 10 : 9 })}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Popover>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Hidden xlDown>
                                <Grid item xs={12} xl={1}>
                                    {getSubmitButton()}
                                </Grid>
                            </Hidden>
                        </Grid>
                        <Grid className='row add-segment-container'>
                            <div>
                                {formData.segments.length < 8 && !formData.tripType.includes('roundTrip') && formData.ticketType !== 'passes' && (
                                    <Button startIcon={<AddBox />} onClick={handleAddSegment}>
                                        {t('multiSearch.trains.addSegment')}
                                    </Button>
                                )}
                            </div>
                            <Hidden xlUp>
                                {getSubmitButton()}
                            </Hidden>
                        </Grid>
                    </form>
                </AccordionDetails>
            </Accordion>

            <Dialog
                open={openModalSwitzerland}
                className='switzerland-modal'
                onClose={() => setOpenModalSwitzerland(false)}
            >
                <Fab size="small" onClick={() => setOpenModalSwitzerland(false)} className='close-button'>
                    <Close fontSize='small' />
                </Fab>
                <DialogContent>
                    {t('multiSearch.trains.SwitzerlandMinors')}
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" variant="contained" onClick={() => handleSubmit(null, false)}>
                        {t('common.continue')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default MultibuscadorTrains;
