import React, { useEffect, useState }  from 'react';
import Header from './Header';
import Footer from './Footer';
import { connect } from 'react-redux';
import { getBookingAction, getPrebookingAction } from '../../store/actions';
import { getClientIp } from '../../store/services/IpServices';
import { useParams, Navigate, useNavigate } from 'react-router-dom';
import Preload from '../pages/Checkout/Preload';
import Steps from '../pages/Checkout/Steps';
import PreloadBooking from '../pages/Checkout/PreloadBooking';
import Preloader from '../common/Preloader';
import { useTranslation } from 'react-i18next';
import { useClientData } from '../../context/ClientContext';

const PRELOAD = {
  hotels: 'preloadHotel.gif',
  trains: 'preloadTrenes.gif',
  flights: 'preloadAereos.gif'
};

function LayoutCheckout({
  children,
  client,
  reservations,
  selectedReservation,
  isIframe,
  setReservations,
  handleSelectReservation,
  startRenderProp,
  responsePrebooking,
  preloadBooking,
  errorPrebooking,
  dataFinal,
  errorAvailabilityProp,
  ...props
}) {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const { currentProduct } = useClientData();
  const [ip, setIp] = useState('');
  const [hash, setHash] = useState('');
  const [module, setModule] = useState('');
  const [user, setUser] = useState();
  const [preload, setPreload] = useState(true);
  const [finalStepPreload, setFinalStepPreload] = useState(false);
  const [startRender, setStartRender] = useState(true);
  const [prebooking, setPrebooking] = useState();
  const [errorAvailability, setErrorAvailability] = useState();
  const [locationState, setLocationState] = useState({});

  useEffect(() => {
    const user = localStorage.getItem('user');
    setUser(user ? JSON.parse(user) : null);
    setHash(params.hash || '');
    setModule(params.module || '');

    (async () => {
      const tokenCheckout = currentProduct?.config?.api_checkout_token || currentProduct?.config_work_unit?.api_checkout_token;

      if (params.hash && tokenCheckout) {
        const clientIp = await getClientIp();
        const dataToSend = {
          hash: params.hash,
          ip: clientIp
        }
        setIp(clientIp);
        props.getPrebooking(tokenCheckout, dataToSend);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPreload(preloadBooking);
  }, [preloadBooking]);

  useEffect(() => {
    setStartRender(startRenderProp);
  }, [startRenderProp]);

  useEffect(() => {
    setErrorAvailability(errorAvailabilityProp);
  }, [errorAvailabilityProp]);

  useEffect(() => {
    setPrebooking(responsePrebooking);
  }, [responsePrebooking]);

  useEffect(() => {
    if (dataFinal && Object.keys(dataFinal).length > 0) {
      if (dataFinal.status) {
        const id = dataFinal.data?.responseSonata?.id || dataFinal.data.idTransaction;
        const number = dataFinal.data?.responseSonata?.data?.number
          || dataFinal.data?.responseSonata?.data?.reservation_number
          || dataFinal.data?.responseSonata?.reservation_number;
        const iframePath = params.tokenIframe ? `/${params.tokenIframe}` : '';
        const url = iframePath + `/checkout/${module}/booking/id/${id}/${number}`;
        const status = (dataFinal?.data?.responseSonata?.booking_status === false || dataFinal?.data?.paymentResponse?.payment_status === null)
          ? 'REJECTED'
          : dataFinal?.data?.paymentResponse?.payment_status || locationState?.status;
        navigate(url, {
          state: prebooking ? {
            purchaseDetail: prebooking.purchaseDetail,
            products: prebooking.products,
            dataFinal: dataFinal.data,
            ...locationState,
            status,
            bookingDuplicated: dataFinal?.data?.responseSonata?.booking_duplicated || false,
            bookingDetail: dataFinal?.data?.responseSonata?.booking_detail || '',
            pendingPayment: dataFinal?.pendingPayment || (dataFinal?.data?.paymentResponse?.payment_status === 'PENDING' ? true : false)
          } : null,
          replace: true
        })
      } else if (dataFinal?.message?.includes('Error')) {
        const iframePath = params.tokenIframe ? `/${params.tokenIframe}` : '';
        navigate(iframePath + `/checkout/${module}/error`, { replace: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFinal]);

  const handleFinalStep = (dataStep, prevStep, extraData = {}, locationStateAux = {}) => {
    const accessToken = localStorage.getItem('jwt');
    const tokenCheckout = currentProduct?.config?.api_checkout_token || currentProduct?.config_work_unit?.api_checkout_token;
    const user = JSON.parse(localStorage.getItem('user') || '{}');

    if (accessToken && tokenCheckout) {
      const prevStepName = prevStep !== null ? prevStep.replace('V2', '') : null;
      const dataToSend = {
        hash: hash,
        ip: ip,
        step: prevStepName,
        username: user?.username,
        finishedStep: dataStep,
        ...extraData
      };
      props.getBooking(accessToken, tokenCheckout, dataToSend);
      setFinalStepPreload(true);
      setLocationState(locationStateAux);

      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  }

  return (
    <div className={`wrapper checkout site-${client.client.name}`}>
      <Header
        client={client}
        user={user}
        reservations={reservations}
        selectedReservation={selectedReservation}
        isIframe={isIframe}
        setReservations={setReservations}
        handleSelectReservation={handleSelectReservation}
      />

      {preload ? (
        <>
          <Preloader
            addDots
            image={`sites/${client.client.name}/${PRELOAD[params.module]}`}
            text={finalStepPreload ? t('common.processingBooking') : t('common.processingRequest')}
          />
          {finalStepPreload ? <PreloadBooking /> : <Preload />}
        </>
      ) : (
        startRender ? (
          prebooking.error ? (
            <Navigate to={prebooking?.step?.path} />
          ) : (
            <Steps
              client={client}
              getFinalStep={handleFinalStep}
              steps={prebooking.step}
              timer={prebooking.timer}
              products={prebooking.products}
              hash={hash}
              ip={ip}
              expired={prebooking.expired}
              data={prebooking.step[0]}
              preloadCurrent={prebooking.preloads}
              module={module}
              selectedReservation={selectedReservation}
            />
          )
        ) : (
          errorAvailability
            ? <Navigate to={`/checkout/${module}/error/disponibilidad`} />
            : <Navigate to={`/checkout/${module}/error404`} />
        )
      )}

      <Footer client={client} marginBottom={!preload && startRender ? 92 : 0} />
    </div>
  );
}

const mapStateToProps = reducers => {
  return reducers.checkoutReducer;
};

const mapDispatchToProps = dispatch => {
  return {
    getPrebooking: (tokenCheckout, data) => dispatch(getPrebookingAction(tokenCheckout, data)),
    getBooking: (access, tokenCheckout, data) => dispatch(getBookingAction(access, tokenCheckout, data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutCheckout);
