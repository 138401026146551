import { Button, Card, CardContent, CardHeader, Grid, Link, Typography } from '@mui/material'
import { AccessTime, AirlineSeatReclineNormal, ArrowForward, AttachMoney, ConfirmationNumber, KeyboardArrowDown, KeyboardArrowUp, Luggage, PublishedWithChanges, Restaurant, Train } from '@mui/icons-material';
import dayjs from 'dayjs';
import Image from '../../../common/Image';
import TrainScales from '../../../modules/Trains/TrainScales';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FOOD_CONDITIONS, LUGGAGE_CONDITIONS } from '../../../../utils/trainsData';
import { useClientData } from '../../../../context/ClientContext';
import TranslatedText from '../../../common/TranslatedText';

const getPrice = (price) => {
  return Number(price).toFixed(2);
}

const getTotalPrice = (purchaseDetail) => {
  const price = purchaseDetail?.priceDetail?.reduce((acc, current) => {
    const prices = current?.charge?.[0]?.fare_class?.prices?.api?.values || current?.charge?.[0]?.prices?.api?.values;
    return acc + (prices?.precio_neto_agencia_minorista || prices?.precio_neto_gotrenes || 0);
  }, 0);

  return getPrice(price);
}

const getProductsDetails = (products) => {
  const getProductTitle = (product) => {
    if (product?.charge?.[0].type?.includes('pass')) {
      return product?.charge?.[0]?.label;
    } else {
      return `${product?.charge?.[0]?.origin?.label} - ${product?.charge?.[0]?.destination?.label}`;
    }
  }

  return products?.map((item, i) => (
    <Grid
      key={i}
      container
      className='item-resume'
    >
      <Grid item md={8} xl={9}>
        <div className='container-item-resume row'>
          <span>{getProductTitle(item)}</span>
        </div>
      </Grid>
      <Grid item md={4} xl={3}>
        <div className={`text-right`}>
          <span>
            {item.currency} {getPrice(item.amount)}
          </span>
        </div>
      </Grid>
    </Grid>
  ))
}

const getPriceDetails = (purchaseDetail, isModal = false) => {
  let tarifaComisionable = 0;
  let comisionTrenesMinorista = 0;
  let precioNetoMinorista = 0;
  let feeAdicionalMinorista = 0;
  let feeTrenes = 0;
  let precioNetoSinGastosMinorista = 0;
  let gastosAdministrativosMinorista = 0;
  let precioNetoAgenciaMinorista = 0;

  purchaseDetail?.priceDetail?.forEach(elem => {
    const prices = elem?.charge?.[0]?.fare_class?.prices?.api?.values || elem?.charge?.[0]?.prices?.api?.values;
    tarifaComisionable += prices?.tarifa_comisionable || 0;
    comisionTrenesMinorista += prices?.comision_trenes_minorista || prices?.comision_gotrenes || 0;
    precioNetoMinorista += prices?.precio_neto_minorista || prices?.subtotal_gotrenes || 0;
    feeAdicionalMinorista += prices?.fee_adicional_minorista || 0;
    feeTrenes += prices?.fee_trenes_minorista || prices?.booking_fee_gotrenes || 0;
    precioNetoSinGastosMinorista += prices?.precio_neto_sin_gastos_minorista || 0;
    gastosAdministrativosMinorista += prices?.gastos_administrativos_minorista || prices?.gastos_administrativos_gotrenes || 0;
    precioNetoAgenciaMinorista += prices?.precio_neto_agencia_minorista || prices?.precio_neto_gotrenes || 0;
  });

  if (isModal) {
    return [
      { title: 'netAgency', value: getPrice(precioNetoAgenciaMinorista), classname: 'strong amount', showTax: true }
    ]
  } else {
    return [
      { title: 'totalTickets', value: getPrice(tarifaComisionable), classname: 'strong sub-amount' },
      { title: 'comission', value: getPrice(comisionTrenesMinorista) },
      { title: 'subtotal', value: getPrice(precioNetoMinorista) },
      { title: 'bookingFee', value: getPrice(feeAdicionalMinorista) },
      { title: 'bookingFee', value: getPrice(feeTrenes) },
      { title: 'netRate', value: getPrice(precioNetoSinGastosMinorista) },
      { title: 'administrativeExpenses', value: getPrice(gastosAdministrativosMinorista) },
      { title: 'netAgency', value: getPrice(precioNetoAgenciaMinorista), classname: 'strong amount', showTax: true }
    ];
  }
}

const getConditionIcon = (condition) => {
  switch (condition.type) {
    case 'refund':
      return <AttachMoney fontSize='small' />;
    case 'change':
      return <PublishedWithChanges fontSize='small' />;
    default:
      return '';
  }
}

const getCardDetailTickets = (bookingItems) => {
  const CardDetail = ({ bookingItems }) => {
    const { t, i18n } = useTranslation();
    const { currentProduct } = useClientData();
    const [expandedConditions, setExpandedConditions] = useState([]);

    const getShortConditions = (product) => {
      if (product?.conditions) {
        let conditions = Array.isArray(product?.conditions)
          ? product?.conditions
          : [product?.conditions];

        conditions = conditions.reduce((acc, current) => {
          const index = acc?.findIndex(elem => (
            JSON.stringify(elem.short_description) === JSON.stringify(current.short_description)
          ));
          if (index > -1) {
            if (!acc[index].names.includes(current.name)) {
              acc[index].names.push(current.name);
            }
          } else {
            acc.push({
              names: [current.name],
              short_description: current.short_description
            });
          }

          return acc;
        }, []);

        return conditions?.map((elem, i) => (
          <Fragment key={i}>
            {elem.names.map((name, z) => (
              <Grid key={z} item xs={12} className='type-title'>
                {name}
              </Grid>
            ))}
            {elem.short_description.map((condition, j) => (
              <Grid item xs={6} className='row short-condition' key={j}>
                {getConditionIcon(condition)}
                <Typography>
                  {condition.title && <p>{condition.title}</p>}
                  {condition.subtitle && <p>{condition.subtitle}</p>}
                </Typography>
              </Grid>
            ))}
          </Fragment>
        ));
      }
    }

    const compareConditions = (conditionA, conditionB) => {
      return conditionA.name === conditionB.name
        && conditionA.large_description === conditionB.large_description;
    }

    const getLargeConditions = (product) => {
      if (product?.conditions) {
        let conditions = Array.isArray(product?.conditions)
          ? product?.conditions
          : [product?.conditions];
        conditions = conditions.filter((elem, i) => (
          conditions.findIndex(obj => compareConditions(obj, elem)) === i
        ));
        return <div className='mb-05'>
          {conditions?.map((condition, i) => (
            <Fragment key={i}>
              <Typography className='title mt-05 mb-05' variant='h3'>
                {condition.name}
              </Typography>
              <div className='conditions'>
                <TranslatedText
                  text={condition.large_description}
                  language={currentProduct?.config?.LOCALE || 'es'}
                />
              </div>
            </Fragment>
          ))}
        </div>;
      }
    }

    const getTermsAndConditions = (fareClass) => {
      if (fareClass?.conditions?.[0]?.terms) {
        return <div className='mb-1'>
          <Link href={fareClass?.conditions?.[0]?.terms} target="_blank">
            {t('results.trains.termsAndConditions')}
          </Link>
        </div>
      }
    }

    const getTicketingConditions = (product) => {
      if (product?.ticketingOptions) {
        let ticketingOptions = Array.isArray(product?.ticketingOptions)
          ? product?.ticketingOptions
          : [product?.ticketingOptions];
        ticketingOptions = ticketingOptions.slice(0, 1);
        return ticketingOptions?.map((condition, i) => {
          let translatedCondition = condition;
          if (translatedCondition === i18n.getFixedT('es')('results.trains.ticketMail')) {
            translatedCondition = t('results.trains.ticketMail');
          } else if (translatedCondition === i18n.getFixedT('es')('results.trains.noPrintTicket')) {
            translatedCondition = t('results.trains.noPrintTicket');
          } else if (translatedCondition === i18n.getFixedT('es')('results.trains.downloadTicket')) {
            translatedCondition = t('results.trains.downloadTicket');
          }
  
          return <Grid className='row condition' key={i}>
            <ConfirmationNumber fontSize='small' />
            {translatedCondition}
          </Grid>
        });
      }
    }

    const getSeatReservationConditions = (segments) => {
      let conditionFlag = false;
      let condition = '';
      segments?.forEach(elem => {
        if (condition && condition !== elem.seatReservationConditions) {
          conditionFlag = true;
        }
        condition = elem.seatReservationConditions;
      });

      if (conditionFlag) {
        condition = t('results.trains.variableSeat');
      } else if (condition === i18n.getFixedT('es')('results.trains.seatReservationIncluded')) {
        condition = t('results.trains.seatReservationIncluded');
      }

      if (condition) {
        return <Grid className='row condition'>
          <AirlineSeatReclineNormal fontSize='small' />
          {condition}
        </Grid>
      }
    }

    const getLuggageConditions = (fareClass, marketingCarriers) => {
      if (fareClass) {
        const conditions = [
          ...new Set(marketingCarriers.flatMap(elem => LUGGAGE_CONDITIONS[elem]?.[fareClass?.name]))
        ];
        if (conditions.some(elem => elem)) {
          return <Grid className='row condition'>
            <Luggage fontSize='small' />
            <Grid className='luggage-condition'>
              {conditions.map((condition, i) => (
                <Typography key={i} dangerouslySetInnerHTML={{ __html: t(condition) }} />
              ))}
            </Grid>
          </Grid>
        }
      }
    }

    const getFoodConditions = (fareClass, marketingCarriers) => {
      if (fareClass) {
        const conditions = marketingCarriers.map(elem => FOOD_CONDITIONS[elem]?.[fareClass?.name]);
        if (conditions.some(elem => elem)) {
          return <Grid className='row condition'>
            <Restaurant fontSize='small' />
            <Grid className='food-condition'>
              {conditions.map((condition, i) => (
                <Typography key={i} dangerouslySetInnerHTML={{ __html: t(condition) }} />
              ))}
            </Grid>
          </Grid>
        }
      }
    }

    const getMinorsMessage = (passengers) => {
      const minorsFiveYears = passengers?.find(elem => elem.age <= 5);
      if (minorsFiveYears) {
        return <Typography
          className='minor-message'
          dangerouslySetInnerHTML={{ __html: t('results.trains.travelFreeMinorsTickets') }}
        />
      }
    }

    const handleExpandClick = (index) => {
      if (expandedConditions.includes(index)) {
        setExpandedConditions(expandedConditions.filter(elem => elem !== index));
      } else {
        setExpandedConditions(current => [...current, index]);
      }
    }

    return Object.values(bookingItems).map((item, i) => {
      const segments = Object.values(item.segments);
      const marketingCarriers = [
        ...new Set(segments.map(elem => elem.marketingCarrier))
      ];
      const isExpanded = expandedConditions.includes(i);
      return <Card key={i} className='segment-container tickets'>
        <CardContent>
          <Grid>
            <Typography className='row segment'>
              <Train fontSize='small' />&nbsp;
              {t('results.trains.segment').toUpperCase()} {i + 1}:&nbsp;
              {item?.origin?.label} <ArrowForward fontSize='small' /> {item?.destination?.label}
            </Typography>
            <Typography className='row date'>
              <AccessTime fontSize='small' />&nbsp;
              {dayjs(item?.departure).format('ddd DD MMM YY')}&nbsp;
              -&nbsp;
              {dayjs(item?.departure).format('HH:mm')}
              <ArrowForward fontSize='small' />
              {dayjs(item?.arrival).format('HH:mm')}
            </Typography>
            <Typography className='row duration'>
              {item.duration},&nbsp;
              <span className='changes'>
                {segments.length - 1} {t(`results.trains.${segments.length === 2 ? 'change' : 'changes'}`)}
              </span>
              {marketingCarriers.map((elem, j) => {
                const name = elem.replaceAll(' ', '');
                return <Image img={`providersTrains/${name}.png`} alt='logo-provider' key={j} hideBrokenImage />
              })}
            </Typography>
          </Grid>

          <TrainScales
            segments={segments}
            fareFeatures={item?.fare_class?.fare_features}
          />

          <Card className='mt-1 conditions-card'>
            <CardHeader title={t('checkout.trains.conditions')} />
            <CardContent>
              <Grid container rowSpacing={1}>
                {getShortConditions(item?.fare_class)}
                {isExpanded && (
                  <Grid item xs={12}>
                    {getLargeConditions(item?.fare_class)}
                    {getTermsAndConditions(item?.fare_class)}
                    {getSeatReservationConditions(segments)}
                    {getTicketingConditions(item?.fare_class)}
                    {getLuggageConditions(item?.fare_class, marketingCarriers)}
                    {getFoodConditions(item?.fare_class, marketingCarriers)}
                    {getMinorsMessage(item?.travelers)}
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Button
                    className='mt-05 show-more-button'
                    endIcon={isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    onClick={() => handleExpandClick(i)}
                  >
                    {isExpanded ? t('checkout.trains.showLess') : t('checkout.trains.showMore')}
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </CardContent>
      </Card>
    })
  }

  return <CardDetail bookingItems={bookingItems} />
}

const getCardDetailPasses = (bookingItems) => {
  const CardDetail = ({ bookingItems }) => {
    const { t } = useTranslation();
    const { currentProduct } = useClientData();

    const getMinorsMessage = (passengers) => {
      const minorsFiveYears = passengers?.find(elem => elem.age <= 5);
      if (minorsFiveYears) {
        return <Typography
          className='minor-message'
          dangerouslySetInnerHTML={{ __html: t('results.trains.travelFreeMinorsPasses') }}
        />
      }
    }

    return bookingItems.map((item, i) => {
      return <Card key={i} className='segment-container passes'>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Image
                img={`passesCompanies/${item?.products?.[0]?.supplier.replaceAll(' ', '')}.png`}
                alt='passes-company'
                className='logo'
              />
            </Grid>
            <Grid item xs={6}>
              <Typography>{t('results.trains.destination')}: <b>{item?.descriptionCode?.description}</b></Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{t('results.trains.type')}: <b>{item?.periodType.description}</b></Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography>{t('results.trains.class')}: <b>{item?.travelClass.comfortCategory.label}</b></Typography>
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={6} className='date'>
              <Typography>{t('results.trains.validFrom')}: <b>{dayjs(item?.activationPeriod.startDate).format('dddd, DD MMM YY')}</b></Typography>
            </Grid>
            <Grid item xs={6} className='date'>
              <Typography>{t('results.trains.validTo')}: <b>{dayjs(item?.activationPeriod.endDate).format('dddd, DD MMM YY')}</b></Typography>
            </Grid>
          </Grid>

          <Card className='mt-1 conditions-card'>
            <CardHeader title={t('checkout.trains.conditions')} />
            <CardContent>
              <div className='conditions'>
                {item?.conditions?.map((elem, i) => (
                  <TranslatedText
                    key={i}
                    text={elem.condition}
                    language={currentProduct?.config?.LOCALE || 'es'}
                  />
                ))}
              </div>
              {getMinorsMessage()}
            </CardContent>
          </Card>
        </CardContent>
      </Card>
    })
  }

  return <CardDetail bookingItems={bookingItems} />
}

const getCardDetail = (bookingItems) => {
  if (Array.isArray(bookingItems || {})) {
    return getCardDetailPasses(bookingItems);
  } else {
    return getCardDetailTickets(bookingItems);
  }
}

export {
  getPrice,
  getTotalPrice,
  getProductsDetails,
  getPriceDetails,
  getCardDetail
};
