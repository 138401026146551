import React, { Fragment, useEffect, useState } from 'react';
import {
  Autocomplete,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Rating,
  Select,
  Skeleton,
  Slider,
  TextField,
  Typography
} from '@mui/material';
import { Check, Close, Search, Tune, FilterAltOff, RemoveCircleOutline } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const FiltersComponent = ({
  filters,
  hotels,
  loading,
  isFavoritePage,
  handleChangeFilters,
  handleClickFavoriteFilter
}) => {
  const { t } = useTranslation();
  const [showMoreAmenities, setShowMoreAmenities] = useState(false);
  const [showMoreProviders, setShowMoreProviders] = useState(false);
  const [activeFilters, setActiveFilters] = useState({});
  const [rangePrice, setRangePrice] = useState([0, 0]);
  const [selectedHotel, setSelectedHotel] = useState();
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (filters) {
      setRangePrice([
        filters['priceCount']?.min || 0,
        filters['priceCount']?.max || 0
      ]);
      const filtersAux = {
        stars: filters['starsCount']?.resultados?.filter(elem => elem.active)?.map(elem => parseInt(elem.idFilter)) || [],
        property: filters['propertyCount']?.results?.filter(elem => elem.active) || [],
        boardbase: filters['boardBaseCount']?.resultados?.filter(elem => elem.active) || [],
        rateType: filters['rateTypeCount']?.resultados?.filter(elem => elem.active) || [],
        amenities: filters['amenitiesCount']?.resultados?.filter(elem => elem.active) || [],
        provider: filters['providerCount']?.resultados?.filter(elem => elem.active) || [],
        // destination: filters['destinations']?.active || 'all',
        hotelName: filters['hotelName'] || activeFilters['hotelName'] || null
      };
      setActiveFilters(filtersAux);
      setInputValue(filtersAux.hotelName?.name || '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  const handleClearAllFilters = () => {
    const activeFiltersAux = {
      stars: [],
      property: [],
      boardbase: [],
      rateType: [],
      amenities: [],
      provider: []
    };
    setInputValue('');
    setSelectedHotel(null);
    handleChangeFilters && handleChangeFilters(activeFiltersAux);
  }

  const handleClearFilter = (filter) => {
    const activeFiltersAux = {...activeFilters};
    activeFiltersAux[filter] = [];
    handleChangeFilters && handleChangeFilters(activeFiltersAux);
  }

  const handleChangeStars = (number, checked = null) => {
    const activeFiltersAux = {...activeFilters};

    if (number === 'all') {
      activeFiltersAux.stars = [];
    } else {
      if (checked) {
        activeFiltersAux.stars = activeFiltersAux.stars.filter(elem => elem !== 'all');
        activeFiltersAux.stars.push(number);
      } else {
        activeFiltersAux.stars = activeFiltersAux.stars.filter(elem => elem !== parseInt(number));
      }
    }

    handleChangeFilters && handleChangeFilters(activeFiltersAux);
  }

  const handleChangeFilter = (filter, value, checked) => {
    const activeFiltersAux = {...activeFilters};
    if (checked) {
      if (!activeFiltersAux[filter]) {
        activeFiltersAux[filter] = [value];
      } else {
        activeFiltersAux[filter].push(value);
      }
    } else {
      activeFiltersAux[filter] = activeFiltersAux[filter].filter(elem => elem.value !== value.value);
    }

    handleChangeFilters && handleChangeFilters(activeFiltersAux);
  }

  const handleChangeRangePrice = (event, newValue, activeThumb) => {
    const minDistance = 0;

    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setRangePrice([Math.min(newValue[0], rangePrice[1] - minDistance), rangePrice[1]]);
    } else {
      setRangePrice([rangePrice[0], Math.max(newValue[1], rangePrice[0] + minDistance)]);
    }
  };

  const handleClearRangePrice = () => {
    setRangePrice([
      filters['priceCount']?.min || 0,
      filters['priceCount']?.max || 0
    ]);
    handleChangeFilters && handleChangeFilters(activeFilters);
  }

  const handleApplyPrice = () => {
    const activeFiltersAux = {...activeFilters};
    activeFiltersAux.price = rangePrice;
    handleChangeFilters && handleChangeFilters(activeFiltersAux);
  }

  const handleSearchByName = (searchEmpty = false) => {
    const name = searchEmpty ? '' : inputValue;
    const data = searchEmpty || !selectedHotel ? { name, id: null } : selectedHotel;
    const activeFiltersAux = {...activeFilters};
    activeFiltersAux.hotelName = data;
    setActiveFilters(activeFiltersAux);
    handleChangeFilters && handleChangeFilters(activeFiltersAux);
  }

  const handleSearchByDestination = (value) => {
    const activeFiltersAux = {...activeFilters};
    activeFiltersAux.destination = value;
    setActiveFilters(activeFiltersAux);
    handleChangeFilters && handleChangeFilters(activeFiltersAux);
  }

  const getActiveFilters = () => {
    if (Object.values(activeFilters).flat().length > 0) {
      return <Grid className='active-filters-container'>
        {Object.entries(activeFilters).flatMap(entry => {
          const [key, value] = entry;
          if (key !== 'stars' && key !== 'price' && key !== 'hotelName' && key !== 'destination' && key !== 'favoritesCount') {
            return value.map((elem, i) => {
              if (elem.value && elem.valie !== 'null') {
                return <Grid key={`${key}_${i}`} className='active-filter'>
                  {elem.value}
                  <IconButton
                    size='small'
                    onClick={() => handleChangeFilter(key, elem, false)}
                  >
                    <Close fontSize='small' />
                  </IconButton>
                </Grid>
              }

              return null;
            })
          }

          return null;
        })}
      </Grid>
    }
  }

  const filterOptions = (options, { inputValue }) => {
    if (!inputValue || inputValue.length < 2) {
      return [];
    }

    let filteredOptions = options.filter(option =>
      option.name.toLowerCase().includes(inputValue.toLowerCase())
    );

    return filteredOptions.slice(0, 6);
  };

  return (
    <Grid className='filters-component'>
      <Grid className='row title' justifyContent='space-between'>
        <Grid className='row'><Tune />{t('filters.hotels.filterSearch')}</Grid>
        <Grid className='row'>
          <Button className='clear-filter-button' onClick={handleClearAllFilters} disabled={loading}>
            <RemoveCircleOutline />{t('filters.hotels.clear')}
          </Button>
        </Grid>
      </Grid>

      {loading ? (
        Array.from(Array(isFavoritePage ? 2 : 5).keys()).map(elem => (
          <Fragment key={elem}>
            <Grid className='section skeleton'>
              <Grid className='row' justifyContent='space-between'>
                <Skeleton variant='rounded' width='50%' />
              </Grid>
              <Grid className='filters'>
                <Skeleton variant='rounded' width='100%' />
                <Skeleton variant='rounded' width='100%' />
                <Skeleton variant='rounded' width='100%' />
                <Skeleton variant='rounded' width='100%' />
              </Grid>
            </Grid>

            {elem < 4 && <Divider />}
          </Fragment>
        ))
      ) : (
        <>
          {!isFavoritePage && (
            <>
              {getActiveFilters()}

              <Grid className='section'>
                <Typography className='title'>{t('filters.hotels.favorites')}</Typography>
                <Grid className='filters'>
                  <Grid className='filter' justifyContent='space-between'>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={filters?.['favoritesCount']?.active || false}
                          disabled={filters?.['favoritesCount']?.total === 0 || filters?.['favoritesCount']?.total === undefined}
                          onChange={(e, checked) => handleClickFavoriteFilter(checked)}
                        />
                      }
                      label={t('filters.hotels.onlyFavorites')}
                    />
                    <span>({filters?.['favoritesCount']?.total || 0})</span>
                  </Grid>
                </Grid>
              </Grid>

              <Divider />
            </>
          )}

          <Grid className='section'>
            <Typography className='title'>{t('filters.hotels.hotelName')}</Typography>
            <Grid className='search-input'>
              <Autocomplete
                variant="outlined"
                fullWidth
                freeSolo
                size='small'
                options={hotels || []}
                getOptionLabel={option => option?.name || ''}
                filterOptions={filterOptions}
                onInputChange={(e, value, action) => {
                  if (e && e.key !== 'Enter') {
                    setInputValue(value);
                    setSelectedHotel(null);
                    if (!value && action === 'clear' && activeFilters.hotelName?.name !== '') {
                      handleSearchByName(true);
                    }
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSearchByName();
                  }
                }}
                onChange={(e, value) => setSelectedHotel(typeof value === 'string' ? { name: value } : value)}
                value={selectedHotel || null}
                inputValue={inputValue || ''}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    placeholder={t('filters.hotels.selectHotel')}
                  />
                }
              />
              <IconButton className='search-button' onClick={() => handleSearchByName()}>
                <Search fontSize='small' />
              </IconButton>
            </Grid>
          </Grid>

          {isFavoritePage && (
            <>
              <Divider />

              <Grid className='section'>
                <Typography className='title'>{t('filters.hotels.zones')}</Typography>
                <Grid className='filters'>
                  <Select
                    size='small'
                    fullWidth
                    defaultValue='all'
                    onChange={e => handleSearchByDestination(e.target.value)}
                  >
                    <MenuItem key={0} value='all'>{t('filters.hotels.all')}</MenuItem>
                    {filters?.destinations?.options?.map((elem, i) => (
                      <MenuItem key={i + 1} value={elem}>{elem}</MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </>
          )}

          <Divider />

          <Grid className='section'>
            <Grid className='row' justifyContent='space-between'>
              <Typography className='title'>{t('filters.hotels.filterByStars')}</Typography>
              {activeFilters.stars && activeFilters.stars?.length > 0 && (
                <Grid className='row'>
                  <Button className='clear-filter-button' onClick={() => handleChangeStars('all')}>
                    <RemoveCircleOutline />{t('filters.hotels.clear')}
                  </Button>
                </Grid>
              )}
            </Grid>
            <Grid className='filters'>
              <Grid className='filter' justifyContent='space-between'>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!activeFilters.stars || activeFilters.stars?.length === 0}
                      onChange={() => handleChangeStars('all')}
                    />
                  }
                  label={t('filters.hotels.allStars')}
                />
                <span>({filters['starsCount']?.total || 0})</span>
              </Grid>
              {filters['starsCount']?.resultados?.map(elem => (
                <Grid className='filter' justifyContent='space-between' key={elem.idFilter}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={elem.active}
                        onChange={(e, checked) => handleChangeStars(elem.idFilter, checked)}
                        disabled={!elem.total}
                      />
                    }
                    label={<Rating value={parseInt(elem.idFilter)} readOnly />}
                  />
                  <span>({elem.total})</span>
                </Grid>
              ))}
            </Grid>
          </Grid>

          {!isFavoritePage && (
            <>
              <Divider />

              <Grid className='section'>
                <Grid className='row' justifyContent='space-between'>
                  <Typography className='title'>{t('filters.hotels.filterByProperty')}</Typography>
                  {filters['propertyCount']?.results?.some(elem => elem.active) && (
                    <Grid className='row'>
                      <Button className='clear-filter-button' onClick={() => handleClearFilter('property')}>
                        <RemoveCircleOutline />{t('filters.hotels.clear')}
                      </Button>
                    </Grid>
                  )}
                </Grid>
                <Grid className='filters'>
                  {filters['propertyCount']?.results?.map(elem => {
                    if (elem.value && elem.value !== 'null') {
                      return <Grid className='filter' justifyContent='space-between' key={elem.idFilter}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={elem.active}
                              onChange={(e, checked) => handleChangeFilter('property', elem, checked)}
                              disabled={!elem.total}
                            />
                          }
                          label={elem.value}
                        />
                        <span>({elem.total})</span>
                      </Grid>
                    }

                    return null;
                  })}
                </Grid>
              </Grid>

              <Divider />

              <Grid className='section'>
                <Grid className='row' justifyContent='space-between'>
                  <Typography className='title'>{t('filters.hotels.filterByBoardBase')}</Typography>
                  {filters['boardBaseCount']?.resultados?.some(elem => elem.active) && (
                    <Grid className='row'>
                      <Button className='clear-filter-button' onClick={() => handleClearFilter('boardbase')}>
                        <RemoveCircleOutline />{t('filters.hotels.clear')}
                      </Button>
                    </Grid>
                  )}
                </Grid>
                <Grid className='filters'>
                  {filters['boardBaseCount']?.resultados?.map(elem => (
                    <Grid className='filter' justifyContent='space-between' key={elem.idFilter}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={elem.active}
                            onChange={(e, checked) => handleChangeFilter('boardbase', elem, checked)}
                            disabled={!elem.total}
                          />
                        }
                        label={elem.value}
                      />
                      <span>({elem.total})</span>
                    </Grid>
                  ))}
                </Grid>
              </Grid>

              <Divider />

              <Grid className='section'>
                <Grid className='row' justifyContent='space-between'>
                  <Typography className='title'>{t('filters.hotels.filterByRateType')}</Typography>
                  {filters['rateTypeCount']?.resultados?.some(elem => elem.active) && (
                    <Grid className='row'>
                      <Button className='clear-filter-button' onClick={() => handleClearFilter('rateType')}>
                        <RemoveCircleOutline />{t('filters.hotels.clear')}
                      </Button>
                    </Grid>
                  )}
                </Grid>
                <Grid className='filters'>
                  {filters['rateTypeCount']?.resultados?.map(elem => (
                    <Grid className='filter' justifyContent='space-between' key={elem.idFilter}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={elem.active}
                            onChange={(e, checked) => handleChangeFilter('rateType', elem, checked)}
                            disabled={!elem.total}
                          />
                        }
                        label={elem.value}
                      />
                      <span>({elem.total})</span>
                    </Grid>
                  ))}
                </Grid>
              </Grid>

              <Divider />

              <Grid className='section'>
                <Grid className='row' justifyContent='space-between'>
                  <Typography className='title'>{t('filters.hotels.filterByAmenities')}</Typography>
                  {filters['amenitiesCount']?.resultados?.some(elem => elem.active) && (
                    <Grid className='row'>
                      <Button className='clear-filter-button' onClick={() => handleClearFilter('amenities')}>
                        <RemoveCircleOutline />{t('filters.hotels.clear')}
                      </Button>
                    </Grid>
                  )}
                </Grid>
                <Grid className='filters'>
                  {filters['amenitiesCount']
                    ?.resultados?.slice(0, showMoreAmenities ? filters['amenitiesCount']?.resultados.length : 8)
                    ?.map(elem => (
                      <Grid className='filter' justifyContent='space-between' key={elem.id_filter}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={elem.active}
                              onChange={(e, checked) => handleChangeFilter('amenities', elem, checked)}
                              disabled={!elem.total}
                            />
                          }
                          label={elem.value}
                        />
                        <span>({elem.total})</span>
                      </Grid>
                    ))
                  }
                  {filters['amenitiesCount']?.resultados.length > 8 && (
                    <Button variant='outlined' size='small' onClick={() => setShowMoreAmenities(!showMoreAmenities)}>
                      {t(`filters.hotels.${showMoreAmenities ? 'seeLessAmenities' : 'seeMoreAmenities'}`)}
                    </Button>
                  )}
                </Grid>
              </Grid>

              <Divider />

              <Grid className='section'>
                <Grid className='row' justifyContent='space-between'>
                  <Typography className='title'>{t('filters.hotels.filterByProviders')}</Typography>
                  {filters['providerCount']?.resultados?.some(elem => elem.active) && (
                    <Grid className='row'>
                      <Button className='clear-filter-button' onClick={() => handleClearFilter('provider')}>
                        <RemoveCircleOutline />{t('filters.hotels.clear')}
                      </Button>
                    </Grid>
                  )}
                </Grid>
                <Grid className='filters'>
                  {filters['providerCount']
                    ?.resultados?.slice(0, showMoreProviders ? filters['providerCount']?.resultados.length : 8)
                    ?.map(elem => (
                      <Grid className='filter' justifyContent='space-between' key={elem.idFilter}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={elem.active}
                              onChange={(e, checked) => handleChangeFilter('provider', elem, checked)}
                              disabled={!elem.total}
                            />
                          }
                          label={elem.value}
                        />
                        <span>({elem.total})</span>
                      </Grid>
                    ))
                  }
                  {filters['providerCount']?.resultados.length > 8 && (
                    <Button variant='outlined' size='small' onClick={() => setShowMoreProviders(!showMoreProviders)}>
                      {t(`filters.hotels.${showMoreProviders ? 'seeLessProviders' : 'seeMoreProviders'}`)}
                    </Button>
                  )}
                </Grid>
              </Grid>

              <Divider />

              <Grid className='section'>
                <Grid className='row' justifyContent='space-between'>
                  <Typography className='title'>{t('filters.hotels.priceRange')}</Typography>
                  <Grid className='row'>
                    <Button onClick={handleClearRangePrice}>
                      <FilterAltOff />{t('filters.hotels.clear')}
                    </Button>
                  </Grid>
                </Grid>
                <Grid className='filters range-price'>
                  <Grid className='row' justifyContent='space-between'>
                    <span>USD {rangePrice[0]}</span>
                    <span>USD {rangePrice[1]}</span>
                  </Grid>
                  <Slider
                    min={filters['priceCount']?.min || 0}
                    max={filters['priceCount']?.max || 0}
                    disableSwap
                    value={rangePrice}
                    onChange={handleChangeRangePrice}
                  />
                </Grid>
                <Grid display='flex' justifyContent='flex-end'>
                  <Button variant='contained' size='small' style={{ marginTop: 10 }} onClick={handleApplyPrice}>
                    <Check fontSize='small' />{t('filters.hotels.apply')}
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </>
      )}
    </Grid>
  );
};

export default FiltersComponent;
