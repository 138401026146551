import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  MenuItem,
  Select,
  Skeleton,
  Typography
} from '@mui/material';
import dayjs from 'dayjs';
import { AccessTime, ArrowForwardIos, Close, DateRange, InsertInvitation, Train } from '@mui/icons-material';
import Image from '../../common/Image';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getClientIp } from '../../../store/services/IpServices';
import { connect } from 'react-redux';
import { startCheckoutAction } from '../../../store/actions';
import { LoadingButton } from '@mui/lab';
import Preloader from '../../common/Preloader';
import { useClientData } from '../../../context/ClientContext';
import TranslatedText from '../../common/TranslatedText';

const DATE_FORMAT = 'DD/MM/YYYY';
const FARE_CLASSES = [
  {
    code: 'STANDARD',
    text: 'standard'
  }, {
    code: 'PRIMERA',
    text: 'primera'
  }
];

function ResultsComponentPasses({ client, dataForm, destination, results, loading, apiCheckout, errorApiCheckout, ...props }) {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { currentProduct, setClientData } = useClientData();
  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedOffer, setSelectedOffer] = useState();
  const [openModalConditions, setOpenModalConditions] = useState(false);
  const [modalConditionsData, setModalConditionsData] = useState();
  const [loadingCheckout, setLoadingCheckout] = useState(false);

  useEffect(() => {
    setSelectedDays(results?.products?.map(elem => elem.durations[0].key) || []);
    setSelectedOffer();
    setOpenModalConditions(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results]);

  useEffect(() => {
    if (loadingCheckout && apiCheckout && Object.keys(apiCheckout).length !== 0) {
      if (!errorApiCheckout) {
        const iframePath = params.tokenIframe ? `${params.tokenIframe}/` : '';
        if (apiCheckout.baseDomain) {
          window.location.href = apiCheckout.urlRedirect.replace(apiCheckout.baseDomain, apiCheckout.baseDomain + iframePath);
        } else {
          const iframePath = params.tokenIframe ? `/${params.tokenIframe}` : '';
          window.location.href = iframePath + `${window.location.origin}/${apiCheckout.urlRedirect}`;
        }
      } else {
        setLoadingCheckout(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorApiCheckout, apiCheckout]);

  const getProductData = (product, index) => {
    const offerDescription = product?.offersDescription?.[selectedDays?.[index]];
    if (offerDescription) {
      offerDescription.label = product?.label;
    }

    return <>
      <div className='product-title-logo row'>
        <Typography className='product-title'>{product.label}</Typography>
        <Image img={`passesCompanies/${product.supplier}.png`} alt='passes-company' className='logo' />
      </div>
      <div className='conditions'>
        {offerDescription?.globalConditions?.map((condition, i) => (
          <span key={i}>
            <TranslatedText
              text={condition.condition}
              language={currentProduct?.config?.LOCALE || 'es'}
            />
          </span>
        ))}
      </div>

      <Button className='conditions-button' onClick={() => handleOpenConditionsModal(offerDescription)}>
        {t('results.trains.seeCompleteConditions')}
      </Button>
    </>
  }

  const handleChangeDays = (value, index) => {
    if (index > -1) {
      const selectedDaysAux = [...selectedDays];
      selectedDaysAux[index] = value;
      setSelectedDays(selectedDaysAux);
      setSelectedOffer(null);
    }
  }

  const getProductDuration = (product, index) => {
    const offerDescription = product?.offersDescription?.[selectedDays?.[index]];

    return <>
      <div>
        <Typography className='title row'>
          <InsertInvitation />
          <TranslatedText text={offerDescription?.periodType?.description} language={currentProduct?.config?.LOCALE || 'es'} isHtml={false} />
        </Typography>
        <Typography>{t('results.trains.from')}: {dayjs(offerDescription?.activationPeriod?.startDate).format('dddd, DD MMM YY')}</Typography>
        <Typography>{t('results.trains.to')}: {dayjs(offerDescription?.activationPeriod?.endDate).format('dddd, DD MMM YY')}</Typography>
      </div>
      <div>
        <Typography className='title row'>
          <DateRange />
          {t('results.trains.numberDays')}:
        </Typography>
        <Select
          fullWidth
          size='small'
          value={selectedDays[index] || ''}
          onChange={(e) => handleChangeDays(e.target.value, index)}
        >
          {product.durations?.map((elem, i) => (
            <MenuItem key={i} value={elem.key}>
              <TranslatedText text={elem.description} language={currentProduct?.config?.LOCALE || 'es'} isHtml={false} />
            </MenuItem>
          ))}
        </Select>
      </div>
    </>
  }

  const getCurrency = () => {
    return currentProduct?.config?.CURRENCY_TO || currentProduct?.config?.currency_to || 'USD';
  }

  const formatPrice = (price) => {
    return parseInt(
      Math.sign(price || 0) *
      Math.floor(
        Math.abs(price || 0) + 0.5
      )
    );
  }

  const getPrice = (option) => {
    return option?.api.values.search_price;
  }

  const getPricesData = (product, index, comfort) => {
    const offerDescription = product?.offersDescription?.[selectedDays?.[index]];
    const travelClass = offerDescription?.travelClass?.find(elem => elem.category.code === comfort);
    const isSelected = selectedOffer && selectedOffer.travelClass?.id === travelClass?.id;

    if (travelClass && travelClass?.api?.values?.search_price) {
      return <Button
        fullWidth
        className={`offer-button ${isSelected && 'selected-offer'}`}
        variant='outlined'
        onClick={() => (
          setSelectedOffer({
            ...offerDescription,
            travelClass,
            label: product.label,
            supplier: product.supplier
          })
        )}
      >
        {getCurrency()} {formatPrice(getPrice(travelClass))}
      </Button>
    }
  }

  const hasResults = (product) => {
    const offersDescription = Object.values(product?.offersDescription || {});
    const travelClass = offersDescription?.flatMap(elem => elem.travelClass);
    return travelClass.some(elem => elem?.api?.values?.search_price)
  }

  const getProductsContainer = () => {
    return results?.products && Array.isArray(results?.products)
      && results?.products.filter(hasResults).map((result, i) => (
      <Grid container className='product-container' key={i} columns={24}>
        <Grid item xs={11} className='product-column'>{getProductData(result, i)}</Grid>
        <Grid item xs={6} className='duration-column'>{getProductDuration(result, i)}</Grid>
        <Grid item xs={true} className='row'>{getPricesData(result, i, 'STANDARD')}</Grid>
        <Grid item xs={true} className='row'>{getPricesData(result, i, 'COMFORT')}</Grid>
      </Grid>
    ))
  }

  const getPassengersTitle = () => {
    const isSelected = Boolean(selectedOffer);
    if (isSelected) {
      const passengers = [
        dataForm?.adults ? `${dataForm.adults} ${dataForm.adults === 1 ? t('multiSearch.trains.adult') : t('multiSearch.trains.adults')}` : '',
        dataForm?.minors ? `${dataForm.minors} ${dataForm.minors === 1 ? t('multiSearch.trains.young') : t('multiSearch.trains.youngs')}` : '',
        dataForm?.seniors ? `${dataForm.seniors} ${dataForm.seniors === 1 ? t('multiSearch.trains.senior') : t('multiSearch.trains.seniors')}` : ''
      ].filter(elem => elem).join(', ');

      return <Typography className='passengers'>{passengers}</Typography>;
    }

    return '';
  }

  const handleOpenConditionsModal = (data) => {
    if (data) {
      setOpenModalConditions(true);
      setModalConditionsData(data);
    }
  }

  const getMinorsMessage = () => {
    const passengers = params['*']?.split('/')?.[3]?.split('-');
    const minorsFiveYears = passengers?.find(elem => parseInt(elem) <= 5);
    if (minorsFiveYears) {
      return <Typography dangerouslySetInnerHTML={{ __html: t('results.trains.travelFreeMinorsPasses') }} />
    }
  }

  const handleClickReserve = async (offer) => {
    const user = localStorage.getItem('user');
    const accessToken = localStorage.getItem('jwt');
    const tokenCheckout = currentProduct?.config?.api_checkout_token || currentProduct?.config_work_unit?.api_checkout_token;

    if (user && accessToken && tokenCheckout) {
      setLoadingCheckout(true);

      const passengers = params['*']?.split('/')[3];
      const accessToken = localStorage.getItem('jwt');
      const clientIp = await getClientIp();
      const urlRedirect = location.pathname;

      const items = {
        location: offer.travelClass.location
      };

      const info = {
        descriptionCode: destination,
        validityStartDate: dayjs(dataForm.segments[0].dateDeparture, DATE_FORMAT).format('YYYY-MM-DD')
      };

      const dataProduct = {
        urlRedirect,
        supplier: null,
        ip: clientIp,
        x_correlation_id: results.x_correlation_id,
        travelers: passengers.split('-').map(elem => ({ age: elem })),
        items,
        info,
        currency: currentProduct?.config?.CURRENCY_TO || currentProduct?.config?.currency_to || 'USD'
      };

      const user = JSON.parse(localStorage.getItem('user') || '{}');

      const requestData = {
        module: 'trains',
        productType: 'passes',
        dataProduct,
        username: user?.username
      };

      props.startCheckout(accessToken, tokenCheckout, requestData);
    } else {
      const iframePath = params.tokenIframe ? `/${params.tokenIframe}` : '';
      if (iframePath) {
        localStorage.clear();
        setClientData(null);
        navigate(iframePath);
      } else {
        navigate('/login', { state: { expiredSession: true } });
      }
    }
  }

  return (
    <>
      <Container>
        <Grid className='results-component-passes' container spacing={3} columns={24}>
          <Grid item xs={16} className='left-side'>
            {loading ? (
              <Grid style={{ padding: 10 }}>
                <Box className='row mb-1' style={{ width: '50%' }}>
                  <Skeleton variant="circular" width={30} height={30} className='mr-05' />
                  <Skeleton variant='rounded' height={45} style={{ flex: 1 }} />
                </Box>
                <Skeleton className='mb-1' variant='rounded' width='100%' height={146} />
                <Skeleton variant='rounded' width='100%' height={146} />
              </Grid>
            ) : (
              <Grid>
                <Grid container className='headers-container' columns={24}>
                  <Grid item xs={11} className='header passe-header row'>
                    <Train className='train-icon' />
                    <div>
                      <Typography className='title'>{t('results.trains.passe')} {destination?.description}</Typography>
                      <Typography className='row date'>
                        <AccessTime fontSize='small' className='time-icon' />
                        {t('results.trains.from')} {dataForm?.segments?.[0]?.dateDeparture && dayjs(dataForm?.segments?.[0]?.dateDeparture, DATE_FORMAT).format('dddd, DD MMM YY')}
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={6} className='header duration-header row'>{t('results.trains.duration').toUpperCase()}</Grid>
                  {FARE_CLASSES.map((elem, i) => (
                    <Grid item xs={true} key={i} className='header row'>{t(`results.trains.fareClasses.${elem.text}`)}</Grid>
                  ))}
                </Grid>

                {getProductsContainer()}
              </Grid>
            )}
          </Grid>

          <Grid item xs={8} className='right-side'>
            <Card>
              <CardHeader
                title={Boolean(selectedOffer)
                  ? <span style={{ fontSize: 15 }}>{t('results.trains.totalPasses')}</span>
                  : <span>{t('results.trains.noPasseSelected')}</span>
                }
                subheader={!Boolean(selectedOffer)
                  ? t('results.trains.selectPasseToContinue')
                  : <div className='column'>
                      <Typography>
                        <span className='price-currency'>{getCurrency()}</span>
                        <span className='price'>{formatPrice(getPrice(selectedOffer.travelClass))}</span>
                      </Typography>
                      <span style={{ fontSize: 12 }}>({t('results.trains.notIncludeExpenses')})</span>
                    </div>
                }
              />
              <CardContent className='column'>
                {getPassengersTitle()}
                <LoadingButton
                  fullWidth
                  variant='contained'
                  color='secondary'
                  className='submit-button'
                  loading={loadingCheckout}
                  disabled={!Boolean(selectedOffer)}
                  onClick={() => handleClickReserve(selectedOffer)}
                >
                  {t('common.reserve')}
                </LoadingButton>
                {selectedOffer ? (
                  <Card className='segment-resume'>
                    <CardHeader
                      avatar={<Train fontSize='small' />}
                      title={<Typography className='destination'>{selectedOffer.label}</Typography>}
                    />
                    <CardContent>
                      <Grid container>
                        <Grid item xs={12}>
                          <Image img={`passesCompanies/${selectedOffer.supplier}.png`} alt='passes-company' className='logo' />
                        </Grid>
                        <Grid item xs={6}>
                          <Typography>{t('results.trains.destination')}: <b>{destination?.description}</b></Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography>{t('results.trains.type')}: <b><TranslatedText text={selectedOffer.periodType.description} language={currentProduct?.config?.LOCALE || 'es'} isHtml={false} /></b></Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography>{t('results.trains.class')}: <b>{selectedOffer.travelClass.category.label}</b></Typography>
                        </Grid>
                        <Grid item xs={6} />
                        <Grid item xs={6} className='date'>
                          <Typography>{t('results.trains.validFrom')}: <b>{dayjs(selectedOffer.activationPeriod.startDate).format('dddd, DD MMM YY')}</b></Typography>
                        </Grid>
                        <Grid item xs={6} className='date'>
                          <Typography>{t('results.trains.validTo')}: <b>{dayjs(selectedOffer.activationPeriod.endDate).format('dddd, DD MMM YY')}</b></Typography>
                        </Grid>
                      </Grid>

                      <Button className='conditions-button' onClick={() => handleOpenConditionsModal(selectedOffer)}>
                        <ArrowForwardIos /> {t('results.trains.seeConditions')}
                      </Button>
                    </CardContent>
                  </Card>
                ) : (
                  <Image img='noTickets.png' alt='no-tickets' className='no-tickets' />
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>

      <Dialog
        open={openModalConditions}
        onClose={() => setOpenModalConditions(false)}
        className='conditions-modal'
        maxWidth='sm'
        fullWidth
      >
        <Fab size="small" onClick={() => setOpenModalConditions(false)} className='close-button'>
          <Close fontSize='small' />
        </Fab>

        <DialogTitle variant='h5'>{t('results.trains.conditions')}</DialogTitle>

        <DialogContent>
          <Typography className='title'>
            {modalConditionsData?.label}
          </Typography>
          <div className='conditions'>
            {modalConditionsData?.conditions?.map((elem, i) => (
              <TranslatedText
                key={i}
                text={elem.condition}
                language={currentProduct?.config?.LOCALE || 'es'}
              />
            ))}
            {getMinorsMessage()}
          </div>
        </DialogContent>
      </Dialog>

      {loadingCheckout && (
        <Preloader
          addDots
          image={`sites/${client.client.name}/preloadTrenes.gif`}
          text={t('common.processingRequest')}
        />
      )}
    </>
  );
}

const mapStateToProps = reducers => {
  return reducers.trainsReducer;
};

const mapDispatchToProps = dispatch => {
  return {
    startCheckout: (access, tokenCheckout, data) => dispatch(startCheckoutAction(access, tokenCheckout, data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResultsComponentPasses);
