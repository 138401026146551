import { GimmonixHomepage, GimmonixResults, GimmonixFavorites } from './Gimmonix';
import { TrainsHomepage, TrainsResults } from './Trains';
import Flights from './Flights/Homepage';
import Assists from './Assists/Homepage';
import Packages from './Packages/Homepage';
import Tours from './Tours/Homepage';
import Transfers from './Transfers/Homepage';
import Rentingcarz from './Rentingcarz/Homepage';

export {
  GimmonixHomepage,
  GimmonixResults,
  GimmonixFavorites,
  TrainsHomepage,
  TrainsResults,
  Flights,
  Assists,
  Packages,
  Tours,
  Transfers,
  Rentingcarz
}