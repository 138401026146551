import { all } from 'redux-saga/effects';
import { searchSagaGimmonix, availabilitySagaGimmonix, favoritesSagaGimmonix } from './sagaGimmonix';
import { checkoutSaga } from './sagaCheckout';
import { reservationSaga } from './sagaReservation';
import { searchSagaTrains } from './sagaTrains';

export default function* rootSaga() {
    yield all([
        searchSagaGimmonix(),
        availabilitySagaGimmonix(),
        favoritesSagaGimmonix(),
        checkoutSaga(),
        reservationSaga(),
        searchSagaTrains()
    ])
}